import React from 'react';
import PropTypes from 'prop-types';

const CircularProgress = ({ size, style }) => {
  const rootStyle = style || {};

  return (
    <div className="CircularProgress" style={{ width: size, height: size, ...rootStyle }}>
      <svg className="CircularProgress-svg" viewBox="22 22 44 44">
        <circle className="CircularProgress-circle" cx={44} cy={44} r={(44 - 3.6) / 2} fill="none" strokeWidth={3.6} />
      </svg>
    </div>
  );
};

CircularProgress.propTypes = {
  size: PropTypes.number,
};

CircularProgress.defaultProps = {
  size: 44,
};

export default CircularProgress;
