import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import Immutable from 'immutable';
import ReactDOM from 'react-dom';
import moment from 'moment';
import component from 'omniscient';
import observer from 'omnipotent/decorator/observer';
import classNames from 'classnames';

import { structure, util } from '../../core';
import { bySlug } from '../../predicates';
import { date } from '../../common';
import TabBar from '../../containers/TabBar/TabBar';
import FullScreenHelper from '../../utils/FullScreenHelper';
import createLookupTable from '../../utils/lib/createLookupTable';
import DownloadModal from '../../components/Modals/DownloadModal';
import EmbedDebateModal from '../../components/Modals/EmbedDebateModal';
import InternalLink from '../../components/InternalLink/InternalLink';
import Icon from '../../components/Icon/Icon';

import DebateErrorComponent from './DebateErrorComponent';
import DebateLoadingComponent from './DebateLoadingComponent';

const definition = {
    //
    // Context / state
    //

    getInitialState: () => ({
      debateError: false,
      agendaLoading: false,
    }),

    contextTypes: {
      getCursor: PropTypes.func.isRequired,
      getService: PropTypes.func.isRequired,
      pathWith: PropTypes.func.isRequired,
      params: PropTypes.object.isRequired,
      route: PropTypes.object.isRequired,
      routeHistory: PropTypes.array.isRequired,
      navigate: PropTypes.func.isRequired,
    },

    //
    // Hooks
    //
    componentDidMount: function () {
      const { getService, params, route } = this.context,
        initialActiveEvent = this.getSeekEvent(),
        isEmbedded = route.name === 'debate-embedded';

      if (initialActiveEvent && !isEmbedded) {
        getService('router').navigate(this.context.pathWith('debate-markers', params));
      }
    },

    componentWillReceiveProps: function (nextProps) {
      if (this.props.params.debate !== nextProps.params.debate) {
        this.context.getCursor(['ui', 'video']).update('playRequested', () => false);
        this.context.getCursor(['ui', 'video']).update('audioOnly', () => false);
        this.getDebateData(nextProps.params.debate);
      }
    },

    componentDidUpdate: function (prevProps) {
      const debate = this.props.debates.find(bySlug(this.props.params.debate));
      const prevDebate = prevProps.debates.find(bySlug(this.props.params.debate));

      // we have a debate with a different date
      // this can happen when the user clicks on a deeplink while the debate page is still open
      if (!debate && prevProps.params.date !== this.props.params.date) {
        return this.getAgendaAndDebateData(this.props.params.date);
      }

      // the agenda data is loaded, get debate data if we have found a candidate
      // debate slug can potentially be the same for some debates in different weeks, so we also check if the id has
      // changed.
      if (debate && (!prevDebate || debate.get('id') !== prevDebate.get('id'))) {
        this.getDebateData(this.props.params.debate);
      }
    },

    componentWillMount: function () {
      const { getCursor, getService } = this.context;
      const date = getCursor('data').get('date');
      const dateService = getService('date');

      getCursor(['ui', 'video']).update('audioOnly', () => false);

      // get agenda and debate data
      if (date && date !== this.props.params.date && dateService.isWithinAllowedTimeWindow(this.props.params.date)) {
        return this.getAgendaAndDebateData(this.props.params.date);
      }

      // agenda is already fetched, only get debate data
      this.getDebateData(this.props.params.debate);
    },

    /**
     * Stops the video when leaving live debate
     */
    componentWillUnmount: function () {
      if (this._swipeDetector) {
        this._swipeDetector.dispose();
        this._swipeDetector = null;
      }

      const { getService, getCursor } = this.context;

      getService('continue-watching').saveDebate();
      getService('live').unsubscribeFromDebate();
      getService('live').clear();
      getService('video').pause();

      // clear isFullScreen
      getCursor(['ui', 'video']).merge({
        audioOnly: false,
        isFullScreen: false,
        // fixes infinite spinner when switching to a other debate while first debate is still seeking
        isSeeking: false,
      });

      getCursor(['ui', 'marker']).update(() => null);

      getCursor(['ui', 'seekEvent']).update(() => null);

      getCursor(['ui', 'downloadModal']).merge({
        open: false,
        marker: null,
        fromDate: null,
        toDate: null,
      });

      // leave fullscreen when leaving the debate page
      if (FullScreenHelper.fullScreenElement()) {
        FullScreenHelper.exitFullScreen();
      }

      if (document.pictureInPictureElement) {
        document.exitPictureInPicture();
      }
    },

    //
    // Methods
    //
    getAgendaAndDebateData: function (date) {
      const { getService, getCursor } = this.context;

      this.setState({ agendaLoading: true });

      getService('api')
        .getDatedComponents(date)
        .then(([actors, agenda]) => {
          Object.entries({ actors, ...agenda }).reduce((cursor, [key, data]) => cursor.update(key, () => Immutable.fromJS(data)), getCursor(['data']));

          this.setState({ agendaLoading: false });
        })
        .catch(() => {
          this.setState({ agendaLoading: false });
        });
    },

    getSeekEvent: function () {
      const seekEvent = this.context.getCursor(['ui', 'seekEvent']).deref();

      if (!seekEvent) {
        return null;
      }

      if (Date.now() > seekEvent.disableTime) {
        this.context.getCursor(['ui', 'seekEvent']).update(() => null);

        return null;
      }

      return seekEvent.event;
    },

    performInitialSeek: function (debate) {
      const seekEvent = this.getSeekEvent();

      if (!seekEvent) {
        return;
      }

      const { getCursor, getService } = this.context;
      const videoSyncService = getService('video-sync');
      const entryParams = getCursor(['ui']).get('entryParams', '');
      const searchParams = new URLSearchParams(entryParams);
      const eventType = searchParams.get('event_type');
      const eventStart = searchParams.get('event_start');

      // when sharing a voting round the event parameter is wrong. So we use the eventStart parameter here which is the
      // votingRound startedAt value.
      if (eventType === 'voting_round') {
        // only seek if we have a eventStart
        if (eventStart) {
          getCursor(['ui', 'sync']).set('pdt', eventStart);
          videoSyncService.seekToMoment(date.fromISO(eventStart));
        }

        return;
      }

      const eventsList = debate.events,
        filteredList = eventsList ? eventsList.filter(({ eventType, eventStart }) => seekEvent === eventType + eventStart) : null,
        seekItem = filteredList && filteredList.length === 1 ? filteredList[0] : null;

      if (!seekItem) {
        this.context.getCursor(['ui', 'seekEvent']).update(() => null);

        return;
      }

      const update = {
        disableTime: Date.now() + 8000,
        event: seekEvent,
      };

      this.context.getCursor(['ui', 'seekEvent']).update(() => update);

      videoSyncService.seekToMoment(date.fromISO(seekItem.eventStart));
    },

    getDebateData: function (debateSlug) {
      const { getCursor, getService } = this.context,
        debates = getCursor(['data', 'debates']),
        debate = debates.find(bySlug(debateSlug));

      if (!debate) {
        return;
      }

      let debateId = debate.get('id'),
        debateDate = getCursor(['data', 'date']).deref();

      this.showLoader();

      getService('api')
        .getDebate(debateDate, debateId)
        .then(
          (data) => {
            getCursor(['data', 'debates'])
              .find(bySlug(debateSlug))
              .update(() => Immutable.fromJS(data));

            getService('live').subscribeToDebate(debateId);
            getService('continue-watching').setDebateId(debateId);

            this.setState({ debateError: false });

            this.removeLoader();

            try {
              this.performInitialSeek(data);
            } catch (error) {
              if (import.meta.env.DEV) {
                console.log('performInitialSeek error', error);
              }
            }
          },
          (error) => {
            if (import.meta.env.DEV) {
              console.log('debate not found', error);
            }

            // Error on XHR request -> show debate error component.
            this.setState({ debateError: true });

            this.handleDebateError();
          },
        )
        .catch((error) => {
          if (import.meta.env.DEV) {
            console.log(error);
          }

          this.handleDebateError();
        });
    },

    /**
     * Handle error during fetching of debate data.
     */
    handleDebateError: function () {
      this.removeLoader();

      this.context.getCursor(['ui', 'seekEvent']).update(() => null);
    },

    removeLoader: function () {
      this.context.getCursor(['ui']).update('isLoading', () => false);
    },

    showLoader: function () {
      this.context.getCursor(['ui']).update('isLoading', () => true);
    },

    renderEmbed: function (debate, route, debateError) {
      return (
        <div className="Layer ViewStack-layer">
          {this.props.children &&
            React.cloneElement(this.props.children, {
              debate,
              debateError,
              key: route.name,
            })}
        </div>
      );
    },

    closeDownloadModal: function () {
      this.context.getCursor(['ui', 'downloadModal']).merge({
        open: false,
        marker: null,
        fromDate: null,
        toDate: null,
      });
    },

    closeEmbedModal: function () {
      this.context.getCursor(['ui', 'embedModal']).set('open', false);
    },

    closePanelClickHandler: function () {
      // Focus play button for accessibility reasons
      document.querySelector('button.Controlbar-buttonPlay')?.focus();
    },
  },
  render = function ({ debates, video, downloadModal, embedModal, downloads }) {
    const { params, route, pathWith } = this.context,
      { debateError, agendaLoading } = this.state,
      politicians = this.context.getCursor(['data', 'actors', 'politicians']),
      politiciansLookUp = createLookupTable(politicians.toJS(), 'id'),
      debate = debates.find(bySlug(params.debate)),
      playRequested = video.get('playRequested'),
      isEmbedded = route.name === 'debate-embedded',
      clipsEnabled = util.getOption('REACT_APP_STRUCTURED_DATA_CLIPS_ENABLED') === '1';

    if (isEmbedded) {
      return this.renderEmbed(debate, route, debateError);
    }

    if (agendaLoading) {
      return <DebateLoadingComponent />;
    }

    if (!debate || debateError) {
      return <DebateErrorComponent debate={debate} />;
    }

    if (!debate.has('current')) {
      return <DebateLoadingComponent debate={debate} />;
    }

    const mainWrapperClassNames = classNames('Main-wrapper', {
        'is-expanded': route.name !== 'debate-video',
        'is-collapsed': route.name === 'debate-video',
      }),
      closeLinkClassNames = classNames('Button Button--close', {
        'u-hidden': !playRequested,
      });

    const title = debate.get('name'),
      description = debate.get('introduction') || 'Er is geen introductie voor dit debat beschikbaar',
      startsAt = debate.get('startsAt'),
      endsAt = debate.get('endsAt'),
      startedAt = debate.get('startedAt'),
      endedAt = debate.get('endedAt'),
      debateDate = debate.get('debateDate'),
      imageUrl = debate.getIn(['video', 'imageUrl']),
      contentUrl = debate.getIn(['video', 'catchup', 'url']),
      votings = debate.get('votings'),
      votingsLookUp = votings ? createLookupTable(votings.toJS(), 'id') : {},
      duration = moment(endedAt || endsAt).diff(moment(startedAt || startsAt)),
      durationISO = moment.duration(duration).toISOString();
    const clips = [];

    if (clipsEnabled) {
      debate.get('events') &&
        debate.get('events').forEach((event) => {
          const eventStart = event.get('eventStart'),
            eventType = event.get('eventType'),
            objectId = event.get('objectId'),
            date = moment(eventStart, 'YYYY-MM-DDTHH:mm:ssZ'),
            offset = date.diff(moment(startedAt), 'seconds'),
            eventSlug = encodeURIComponent(`${eventType}${eventStart}`),
            clipUrl = `${window.location.href.split('?')[0]}?event=${eventSlug}`;
          // Format the clipName
          let clipName = null;

          switch (eventType) {
            case 'chairman':
              clipName = 'De voorzitter spreekt';
              break;
            case 'speaker':
            case 'interrupter':
              const politician = politiciansLookUp[objectId];
              const person = politician ? `${politician.firstName} ${politician.lastName}` : 'Onbekende spreker';

              clipName = `${person} ${eventType === 'speaker' ? 'spreekt' : 'interrumpeert'}`;
              break;
            case 'voting_start':
              const voting = votingsLookUp[objectId];

              clipName = voting ? voting.title : null;
              break;
            default:
              break;
          }

          if (offset < 0 || !clipName) {
            return;
          }

          // Add to clips
          clips.push({
            '@type': 'Clip',
            name: clipName,
            startOffset: offset,
            url: clipUrl,
          });
        });
    }

    const structuredJSONVideo = {
      '@context': 'https://schema.org',
      '@type': 'VideoObject',
      name: title,
      thumbnailUrl: imageUrl,
      description: description,
      uploadDate: debateDate,
      contentUrl: contentUrl,
      duration: durationISO,
      hasPart: clips,
      publication: {
        '@type': 'BroadcastEvent',
        startDate: startedAt || startsAt,
        endDate: endedAt || endsAt,
        isLiveBroadcast: true,
      },
    };

    const structuredJSONEvent = {
      '@context': 'https://schema.org',
      '@type': 'Event',
      name: title,
      startDate: startedAt || startsAt,
      endDate: endedAt || endsAt,
      image: [imageUrl],
      eventStatus: 'https://schema.org/EventScheduled',
      eventAttendanceMode: 'https://schema.org/OnlineEventAttendanceMode',
      description: description,
      organizer: {
        '@type': 'Organization',
        name: 'Tweede Kamer der Staten-Generaal',
        url: 'https://www.tweedekamer.nl/',
      },
      location: {
        '@type': 'VirtualLocation',
        url: window.location.href,
      },
    };
    const structuredJSON = () => {
      return JSON.stringify({
        '@context': 'https://schema.org',
        '@graph': [structuredJSONEvent, structuredJSONVideo],
      });
    };

    return (
      <div className="Layer ViewStack-layer DebateRoute">
        <Helmet>
          <title>{title} | Debat Direct</title>
          <meta name="description" content={description} />
          <script type="application/ld+json">{structuredJSON()}</script>
        </Helmet>
        <div className={mainWrapperClassNames} id="main" tabIndex="-1">
          <TabBar debate={debate} route={route} controls="debate-tab-content" />
          <div className="Main-animation" id="debate-tab-content" tabIndex="-1">
            {this.props.children &&
              React.cloneElement(this.props.children, {
                debate: debate,
                key: route.name,
              })}
          </div>
          <InternalLink className={closeLinkClassNames} href={pathWith('debate-video')} aria-label="Sluit paneel" onClick={this.closePanelClickHandler}>
            <Icon name="close" />
          </InternalLink>
        </div>
        <DownloadModal
          open={downloadModal.get('open')}
          openState={downloads.get('openState')}
          fromDate={downloadModal.get('fromDate')}
          initiator={downloadModal.get('initiator')}
          toDate={downloadModal.get('toDate')}
          marker={downloadModal.get('marker')}
          downloads={downloads.get('items')}
          debate={debate}
          politicians={politicians}
          onClose={() => this.closeDownloadModal()}
        />
        <EmbedDebateModal debate={debate} open={embedModal.get('open')} onClose={() => this.closeEmbedModal()} initiator={embedModal.get('initiator')} />
      </div>
    );
  },
  DebateRouterComponent = component('DebateRouterComponent', definition, render);

export default observer(
  structure,
  {
    debates: ['data', 'debates'],
    video: ['ui', 'video'],
    downloadModal: ['ui', 'downloadModal'],
    embedModal: ['ui', 'embedModal'],
    downloads: ['ui', 'downloads'],
  },
  DebateRouterComponent,
);
