import PropTypes from 'prop-types';
import React from 'react';
import component from 'omniscient';
import observer from 'omnipotent/decorator/observer';

import { structure } from '../../core';
import { byId } from '../../predicates';
import Button from '../../components/Button/Button';
import Icon from '../../components/Icon/Icon';

const showMenuButtonRoutes = [
    'home',
    'votings',
    'index-for-date',
    'category',
    'location-plenary',
    'parliament-members',
    'cabinet-members',
    'about',
    'support',
    'disclaimer',
    'notifications',
    'kamerbeelden',
    'downloads',
    'search',
  ],
  ucFirst = (string) => string.charAt(0).toUpperCase() + string.slice(1),
  definition = {
    contextTypes: {
      getCursor: PropTypes.func.isRequired,
      getService: PropTypes.func.isRequired,
      pathTo: PropTypes.func.isRequired,
      navigate: PropTypes.func.isRequired,
      routeHistory: PropTypes.array.isRequired,
      route: PropTypes.object.isRequired,
      params: PropTypes.object.isRequired,
    },

    backButtonClickHandler: function () {
      const { routeHistory, getService } = this.context;

      getService('router').goBackToIndex(routeHistory);
    },

    menuButtonClickHandler: function () {
      this.context.getCursor(['ui', 'menu']).update('isVisible', () => true);
    },

    searchButtonClickHandler: function (event) {
      const { getService } = this.context;
      const searchService = getService('search');

      this.context.getCursor().setIn(['ui', 'search', 'query'], '');

      if (this.context.route.name === 'search') {
        document.querySelector('#searchBarInput')?.focus();
        event.preventDefault();

        return;
      }

      searchService.resetFacets();
      searchService.resetSuggestions();
      searchService.setFromDate(null);
      searchService.setToDate(null);
      searchService.search('');

      getService('router').navigate('/search');
    },

    renderMenuOrBackButton: function () {
      const routeName = this.context.route.name,
        showBackButton = showMenuButtonRoutes.indexOf(routeName) === -1,
        isMenuVisible = this.context.getCursor(['ui', 'menu']).get('isVisible');

      if (showBackButton) {
        return (
          <Button className="Button--menu js-ocoMenuIgnore" aria-label="Terug" onClick={this.backButtonClickHandler}>
            <Icon name="caretLeft" className="Button-icon" width="40" height="40" />
          </Button>
        );
      }

      return (
        <Button
          className="Button--menu Button--uppercase js-ocoMenuIgnore"
          tabIndex="0"
          aria-haspopup="true"
          aria-controls="main-menu"
          aria-expanded={isMenuVisible}
          onClick={this.menuButtonClickHandler}
          aria-label="Menu"
        >
          <Icon name="menu" className="Button-icon" width="40" height="40" />
          <span className="Button-label u-centerSelf">Menu</span>
        </Button>
      );
    },

    renderSearchButton: function () {
      const showSearchBar = true;

      if (showSearchBar) {
        return (
          <Button className="Button--menu" onClick={this.searchButtonClickHandler} aria-label="Zoek debatten">
            <span className="Button-label u-centerSelf u-textUppercase">Zoek debatten</span>
            <Icon name="search" className="Button-icon" width="40" height="40" aria-hidden="true" />
          </Button>
        );
      }
    },

    parseNavbarTitle: function (debate) {
      const dateService = this.context.getService('date'),
        localizedDate = ucFirst(dateService.getUserFriendlyAgendaDate()),
        defaultPrefix = `${localizedDate} in de `;

      if (!debate) {
        return [defaultPrefix, 'Tweede Kamer'];
      }

      const { getCursor } = this.context,
        locationId = debate.get('locationId'),
        location = getCursor(['data', 'locations']).find(byId(locationId)),
        locationName = location ? location.get('name') : null;

      if (!locationName) {
        return [defaultPrefix, 'Tweede Kamer'];
      }

      // If debate is not happening today, we use the default prefix and the location name.
      if (dateService.getAgendaDateDifference() !== 0) {
        return [defaultPrefix, locationName];
      }

      // Otherwise we use Eerder, Nu and Straks.
      if (debate.has('endedAt')) {
        return ['Eerder in de ', locationName];
      }

      if (debate.has('startedAt')) {
        return ['Nu in de ', locationName];
      }

      return ['Straks in de ', locationName];
    },
  },
  /**
   * The NavBar component
   * @param  {Object} props    Determines what elements are shown
   * @return {React.Component} An instance of the NavBar component
   */
  NavBar = component('NavBar', definition, function ({ debate, inert }) {
    const { route, params } = this.context,
      title = route.title && typeof route.title === 'function' ? route.title(route, params, debate) : route.title,
      titleParts = title ? [title] : this.parseNavbarTitle(debate);

    return (
      <header className="App-navBar NavBar u-flex u-row u-justify" inert={inert}>
        {this.renderMenuOrBackButton()}
        <div className="NavBar-headingWrapper">
          <div className="u-flex u-shrink u-horizontalCenter u-parentHeight u-sm-center">
            <h1 className="NavBar-heading Heading u-centerSelf">
              <span role="text">
                <span>{titleParts[0]}</span>
                <span className="u-noWrap">{titleParts[1]}</span>
              </span>
            </h1>
          </div>
        </div>
        <div className="NavBar-actions u-flex" />
        {this.renderSearchButton()}
      </header>
    );
  });

export default observer(
  structure,
  {
    debates: ['data', 'debates'],
    video: ['ui', 'video'],
    agendaDate: ['data', 'date'],
  },
  NavBar,
);
