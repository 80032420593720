import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import component from 'omniscient';
import immutable from 'immutable';
import observer from 'omnipotent/decorator/observer';
import moment from 'moment';

import { structure } from '../../core';
import InternalLink from '../../components/InternalLink/InternalLink';
import SwipeDetector from '../../utils/lib/SwipeDetector';

import { DayComponent, TodayComponent } from './DayComponent';
import DayErrorComponent from './DayErrorComponent';
import RecessComponent from './RecessComponent';

const dayLabels = [
  { short: 'Ma', long: 'Maandag' },
  { short: 'Di', long: 'Dinsdag' },
  { short: 'Wo', long: 'Woensdag' },
  { short: 'Do', long: 'Donderdag' },
  { short: 'Vrij', long: 'Vrijdag' },
  { short: 'Zat', long: 'Zaterdag' },
  { short: 'Zon', long: 'Zondag' },
];

/**
 * Definition of day selector component.
 */
const definition = {
  contextTypes: {
    getCursor: PropTypes.func.isRequired,
    getService: PropTypes.func.isRequired,
    pathTo: PropTypes.func.isRequired,
    pathWith: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
  },

  getInitialState: () => ({
    loadingDate: null,
    mounted: true,
  }),

  componentWillReceiveProps: function (nextProps) {
    const date = this.props.params.date,
      agendaDate = this.props.agendaDate.deref(),
      currentDate = this.props.currentDate.deref(),
      nextDate = nextProps.params.date || currentDate,
      dateService = this.context.getService('date');

    if (date === nextDate) {
      return;
    }

    const weekBegin = moment(currentDate).startOf('week');
    const weekEnd = weekBegin.clone().add(6, 'days');

    if (!nextDate || !moment(nextDate).isBetween(weekBegin, weekEnd, 'day', '[]')) {
      const path = this.context.pathWith('index');

      return this.context.getService('router').replace(path);
    }

    if (nextDate !== agendaDate && dateService.isValidPathDate(nextDate) && nextDate !== this.state.loadingDate) {
      this._getDatedComponents(nextDate);
    }
  },

  componentDidMount: function () {
    this._setSwipeDetector();

    // Check if we have a fresh agenda.
    // If not -> refresh.
    const requestedDate = this.props.params.date,
      currentDate = this.props.currentDate.deref(),
      agendaFetchTime = this.context.getCursor(['data', 'agendaFetchTime']).deref(),
      agendaDate = this.props.agendaDate.deref(),
      agendaIsStale = Date.now() - agendaFetchTime > 15000;

    const weekBegin = moment(currentDate).startOf('week');
    const weekEnd = weekBegin.clone().add(6, 'days');

    if (!requestedDate || !moment(requestedDate).isBetween(weekBegin, weekEnd, 'day', '[]')) {
      const path = this.context.pathWith('index');

      return this.context.getService('router').replace(path);
    }

    // We have data, but the date is different from the date we want to display.
    if (requestedDate && requestedDate !== agendaDate) {
      return this._getDatedComponents(requestedDate);
    }

    // If user enters on index, we want to prevent re-fetching the data which has been fetched in the bootstrap phase.
    if (agendaIsStale) {
      this._getDatedComponents(agendaDate);
    }
  },

  componentDidUpdate: function () {
    this._setSwipeDetector();
  },

  componentWillUnmount: function () {
    this.state.mounted = false;
    this._setLoadingDate(null);

    if (this._swipeDetector) {
      this._swipeDetector.dispose();
      this._swipeDetector = null;
    }
  },

  /**
   * Set swipe detector.
   * @private
   */
  _setSwipeDetector: function () {
    if (this._swipeDetector) {
      return;
    }

    if (!this.refs.dayContainer) {
      return;
    }

    this._swipeDetector = new SwipeDetector(this.refs.dayContainer);
    this._swipeDetector.onSwipe = this._handleSwipe;
    this._swipeDetector.init();
  },

  /**
   * Handle swipe event.
   * @param {Object} event
   * @private
   */
  _handleSwipe: function (event) {
    const dateService = this.context.getService('date'),
      currentDate = this.props.currentDate.deref(),
      agendaDate = this.props.agendaDate.deref(),
      weekDays = dateService.createWeekDates(agendaDate, currentDate),
      relevantWeekDays = dateService.hasWeekendDebates(agendaDate, currentDate) ? weekDays : weekDays.slice(0, 5),
      index = relevantWeekDays.indexOf(agendaDate);

    if (index === -1) {
      return;
    }

    const operator = event.direction === SwipeDetector.LEFT() ? 1 : -1,
      newIndex = index + operator;

    if (newIndex < 0 || newIndex > relevantWeekDays.length - 1) {
      return;
    }

    const path = this.context.pathWith('index-for-date', { date: relevantWeekDays[newIndex] });

    this.context.getService('router').replace(path);
  },

  /**
   * Get dated agenda and actors.
   * @param {String} agendaDate
   * @private
   */
  _getDatedComponents: function (agendaDate) {
    const createCallback = (callback) => (response) => {
      // User has apparently clicked on a debate -> ignore data.
      if (!this.state.mounted) {
        return;
      }

      // We receive date that is no longer relevant.
      // This can happen if user is a fast clicker.
      if (agendaDate !== this.state.loadingDate) {
        return;
      }

      callback(response);
      this._setLoadingDate(null);
    };

    this._setLoadingDate(agendaDate);

    const apiService = this.context.getService('api');

    apiService
      .getDatedComponents(agendaDate)
      .then(createCallback(this._handleDatedComponents), createCallback(this._handleDatedComponentsError))
      .then(() => document.querySelector('.List-itemTitle') && document.querySelector('.List-itemTitle').focus()); // focus on first debate title
  },

  /**
   * Handle dated agenda and actors.
   * @param {Array} response
   * @private
   */
  _handleDatedComponents: function (response) {
    const [actors, agenda] = response,
      data = {
        ...agenda,
        actors,
      };

    // Store data.
    this._storeData(data);
  },

  /**
   * Handle error on fetching dated components.
   * @param {Object} response - response containing info about error (like HTTP status).
   * @private
   */
  _handleDatedComponentsError: function (response) {
    if (import.meta.env.DEV) {
      console.error(response);
    }

    const props = this.props,
      currentDate = props.currentDate.deref(),
      date = this.state.loadingDate;

    // Create error data.
    const data = {
      date: date, // Use loading date as date.
      currentDate: currentDate, // Current date has been determined earlier and can be used.
      agendaFetchTime: 0, // Make sure we never used cached error data.
      actors: null, // Fill relevant properties with null values.
      debates: null,
      categories: null,
      documents: null,
    };

    // Store error data.
    this._storeData(data);
  },

  /**
   * Store the data in the main structure.
   * @param {Object} data
   * @private
   */
  _storeData: function (data) {
    const { getCursor } = this.context;

    Object.entries(data).reduce((cursor, [key, data]) => cursor.update(key, () => immutable.fromJS(data)), getCursor(['data']));
  },

  /**
   * Set loading date.
   * @param {String} date - date in YYYY-MM-DD format.
   * @private
   */
  _setLoadingDate: function (date) {
    this.setState({ loadingDate: date });
    this.context.getCursor(['ui']).update('isLoading', () => Boolean(date));
  },

  /**
   * Generate day labels.
   * @returns {Array}
   * @private
   */
  _generateDayLabels: function () {
    const { getService, pathWith } = this.context,
      loadingDate = this.state.loadingDate,
      agendaDate = this.props.agendaDate.deref(),
      currentDate = this.props.currentDate.deref(),
      dateService = getService('date'),
      weekDates = dateService.createWeekDates(agendaDate),
      hasWeekendDebates = dateService.hasWeekendDebates(agendaDate, currentDate),
      relevantDays = hasWeekendDebates ? dayLabels : dayLabels.slice(0, 5);

    return relevantDays.map((label, index) => {
      const { category } = this.context.params,
        dateLabel = moment(weekDates[index]).format('YYYY-MM-DD'),
        isSelected = dateLabel === (loadingDate || agendaDate),
        isToday = dateLabel === currentDate,
        toPath = category ? 'category' : isToday ? 'home' : 'index-for-date',
        href = pathWith(toPath, { date: dateLabel, category }),
        ariaAttributes = {},
        className = classNames('DaySelector-Day', {
          DaySelected: isSelected,
          DayToday: isToday,
          FullWeek: hasWeekendDebates,
        });

      if (isSelected) {
        ariaAttributes['aria-current'] = 'page';
      }

      return (
        <InternalLink
          className={className}
          key={label.short}
          href={href}
          aria-label={label.long}
          data-long-label={label.long}
          data-short-label={label.short}
          {...ariaAttributes}
        />
      );
    });
  },

  /**
   * Get day component.
   * @returns {React.Component}
   * @private
   */
  _getDayComponent: function () {
    const debates = this.props.debates.deref(),
      agendaDate = this.state.loadingDate || this.props.agendaDate.deref();

    if (!debates) {
      return <DayErrorComponent />;
    }

    // disabled this for a smoother loading transition
    // if (this.state.loadingDate) {
    //   return null;
    // }

    const dateService = this.context.getService('date'),
      dayDifference = dateService.getAgendaDateDifference(agendaDate),
      Component = dayDifference === 0 ? TodayComponent : DayComponent;

    return <Component loading={!!this.state.loadingDate} agendaDate={agendaDate} params={this.props.params} route={this.props.route} />;
  },
};

/**
 * Render method of day selector.
 * @param {Object} props
 * @returns {React.Component|null}
 */
const render = function (props) {
  // During a recess week we show a recess message.
  if (this.context.getService('date').isRecessWeek()) {
    return <RecessComponent agendaDate={props.agendaDate.deref()} />;
  }

  // This element overlaps the original header, but it makes sure it gets read once by
  // the screen reader. Applying aria-live to the original header has bugs. See DDSB-3909
  const liveRegion = () => {
    const agendaDateString = this.context.getService('date').getUserFriendlyAgendaDate(),
      title = 'Debatten ' + (agendaDateString ? 'van ' + agendaDateString : '');
    return (
      <div aria-live="polite" aria-relevant="text" className="Main-content Main-heading u-opaque">
        {title}
      </div>
    );
  };

  return (
    <div className="Layer ViewStack-layer">
      <Helmet>
        <title>Deze week in de Tweede Kamer | Debat Direct</title>
        <meta name="description" content="Volg de debatten van de Tweede Kamer live met extra debat- en sprekersinformatie via Debat Direct." />
      </Helmet>
      <div className="Main-wrapper is-expanded" id="main" tabIndex="-1">
        <div className="DebateList-overlay" />
        <nav className="DaySelector-Strip">{this._generateDayLabels()}</nav>
        {liveRegion()}
        <div ref="dayContainer">{this._getDayComponent()}</div>
      </div>
    </div>
  );
};

/**
 * Public exports.
 */
export default observer(
  structure,
  {
    currentDate: ['data', 'currentDate'],
    agendaDate: ['data', 'date'],
    agendaOverview: ['data', 'agendaOverview'],
    debates: ['data', 'debates'],
  },
  component('DaySelectorComponent', definition, render),
);
