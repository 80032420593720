import React from 'react';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import moment from 'moment';
import observer from 'omnipotent/decorator/observer';
import PropTypes from 'prop-types';
import component from 'omniscient';

import { structure } from '../../core';
import DownloadUnmarkedModal from '../../components/Modals/DownloadUnmarkedModal';
import CircularProgress from '../../components/Progress/CircularProgress';
import ScrollView from '../../components/ScrollView/SmartScrollView';
import Button from '../../components/Button/Button';
import DownloadItemPopover from '../../components/Popovers/DownloadItemPopover';
import { DOWNLOAD_STATUS } from '../../services/DownloadService/DownloadService';
import { Delete, Download, MoreVert } from '../../icons';

import ConfirmationPopover from './popovers/ConfirmationPopover';

const /**
   * The component definition
   * @type {Object}
   */
  definition = {
    contextTypes: {
      pathTo: PropTypes.func.isRequired,
      getCursor: PropTypes.func.isRequired,
      getService: PropTypes.func.isRequired,
      params: PropTypes.object.isRequired,
    },

    componentWillMount: function () {
      this.context.getService('download').verifyFiles();
    },

    componentWillUnmount: function () {
      this.context.getService('download').markAllAsSeen();
    },

    getInitialState: function () {
      return {
        menuOpen: false,
        menuTarget: null,
        menuItem: null,
      };
    },

    removeDownload: function (index) {
      this.context.getService('download').remove(index);
    },

    handleMoreButtonClick: function (event, item) {
      this.setState({
        menuOpen: true,
        menuTarget: event.currentTarget,
        menuItem: item,
      });
    },

    handleMenuClose: function () {
      this.setState({
        menuOpen: false,
        menuTarget: null,
      });
    },

    handleDeleteAllClick: function () {
      this.setState({
        deleteConfirmationOpen: true,
      });
    },

    handleDeleteAllConfirm: function () {
      this.context.getService('download').removeAll();

      this.setState({
        deleteConfirmationOpen: false,
      });
    },

    renderDownload: function (item, index) {
      const status = item.get('status');
      const daysRemaining = moment(item.get('expires')).diff(moment(), 'days');

      // hide expired downloads
      if (daysRemaining <= 0 && status === DOWNLOAD_STATUS.GENERATED) {
        return null;
      }

      let altText = '';

      switch (status) {
        case DOWNLOAD_STATUS.GENERATING:
          altText = 'Video aan het genereren...';
          break;
        case DOWNLOAD_STATUS.DOWNLOADING:
          altText = 'Video aan het downloaden...';
          break;
        case DOWNLOAD_STATUS.ERROR:
          altText = 'Fout bij het downloaden van de video...';
          break;
        case DOWNLOAD_STATUS.DOWNLOADED:
          altText = `Video is gedownload`;
          break;
        case DOWNLOAD_STATUS.ABORTED:
          altText = `Video download onderbroken`;
          break;
        case DOWNLOAD_STATUS.GENERATED:
        default:
          altText = `Nog ${daysRemaining} dagen beschikbaar`;
      }

      const titlePrefix = item.get('seen', true) ? '' : '• ';

      return (
        <li className={classNames('Downloads-item', `Downloads-item--${status}`)} key={index}>
          <div className="Downloads-itemInfo">
            <h3 className="Downloads-itemTitle">
              {titlePrefix}
              {item.get('title')}
            </h3>
            <div className="Downloads-itemInfo">{item.get('label')}</div>
            <div className="Downloads-itemAlt">{altText}</div>
          </div>
          <div className="Downloads-itemActions">
            {status === DOWNLOAD_STATUS.DOWNLOADING || status === DOWNLOAD_STATUS.GENERATING ? (
              <CircularProgress size={24} style={{ margin: 6 }} />
            ) : (
              <Button className="IconButton" onClick={(event) => this.handleMoreButtonClick(event, item)} aria-label="Meer opties">
                <MoreVert />
              </Button>
            )}
          </div>
        </li>
      );
    },
  },
  /**
   * Renders the DownloadsComponent
   *
   * @return {React.Component}        An instance of DownloadsComponent
   */
  render = function () {
    const { downloads, downloadModal } = this.props;
    const isLoggedIn = this.context.getService('auth').isLoggedIn();
    const items = downloads.get('items').filter((item) => {
      return !item.get('type').includes('mxf') || isLoggedIn;
    });
    const count = items.count();
    const supportsFileDownload = this.context.getService('platform').supportsFileDownload();

    return (
      <div className="Layer ViewStack-layer Layer--white">
        <Helmet>
          <title>Downloads | Debat Direct</title>
          <meta name="description" content="Op deze pagina staan de bestanden die klaar zijn voor download naar de eigen omgeving." />
        </Helmet>
        <div className="Main-wrapper is-expanded" id="main" tabIndex="-1">
          <ScrollView>
            <div className="Main-content Content Downloads">
              <section>
                <h2 className="Heading">Downloads</h2>
                <p>
                  Hieronder staan de bestanden die klaar zijn om te downloaden naar de eigen omgeving. Klik op het icoon achter het bestand (download, deel of
                  verwijder) om een actie uit te voeren op het betreffende bestand.
                </p>
                <p>
                  U kunt ook een vergadering zonder verslaglegging downloaden. Een vergadering zonder verslaglegging is een vergadering waarvan de Dienst
                  Verslag en Redactie geen schriftelijk of video-verslag maakt. Voorbeelden zijn: een procedurevergadering, een gesprek of een
                  delegatievergadering.
                </p>
                <hr className="Main-hr" />
                {count > 0 ? (
                  <ul className="List">{downloads.get('items').map(this.renderDownload)}</ul>
                ) : (
                  <div className="u-mb20">Uw lijst downloads is nog leeg!</div>
                )}
                <div className="u-centerText">
                  {supportsFileDownload && (
                    <Button className="Button--primary Button--medium Button--uppercase u-mb20" onClick={() => downloadModal.set('open', true)}>
                      <Download className="Button-icon" />
                      <span className="Button-label">Vergadering zonder verslaglegging downloaden</span>
                    </Button>
                  )}
                  {count > 0 && (
                    <Button className="Button--primary Button--medium Button--uppercase" onClick={this.handleDeleteAllClick}>
                      <Delete className="Button-icon" />
                      <span className="Button-label">Alle downloads wissen</span>
                    </Button>
                  )}
                </div>
              </section>
            </div>
          </ScrollView>
        </div>
        <DownloadItemPopover
          open={this.state.menuOpen}
          anchor={this.state.menuTarget}
          transformOrigin={{
            horizontal: 'auto',
            vertical: 'auto',
          }}
          onClose={this.handleMenuClose}
          item={this.state.menuItem}
        />
        <DownloadUnmarkedModal open={downloadModal.get('open')} downloads={downloads.get('items')} onClose={() => downloadModal.set('open', false)} />
        <ConfirmationPopover
          open={this.state.deleteConfirmationOpen}
          title="Alle downloads verwijderen"
          body="U staat op het punt om alle downloads te verwijderen van uw apparaat. Klik op de knop hieronder om dit te bevestigen."
          onCancel={() => this.setState({ deleteConfirmationOpen: false })}
          onConfirm={this.handleDeleteAllConfirm}
        />
      </div>
    );
  };

const DownloadsComponent = component('DownloadsComponent', definition, render);

export default observer(
  structure,
  {
    downloads: ['ui', 'downloads'],
    downloadModal: ['ui', 'downloadModal'],
  },
  DownloadsComponent,
);
