import PropTypes from 'prop-types';
import React from 'react';

export default class EventListener extends React.Component {
  static propTypes = {
    target: PropTypes.object,
    event: PropTypes.string.isRequired,
    callback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    target: document,
  };

  handleEvent = (event) => {
    this.props.callback(event);
  };

  componentWillMount() {
    const { target, event } = this.props;

    target.addEventListener(event, this.handleEvent);
  }

  componentWillUnmount() {
    const { target, event } = this.props;

    target.removeEventListener(event, this.handleEvent);
  }

  render() {
    return null;
  }
}
