import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import component from 'omniscient';
import immutable from 'immutable';

import OnClickOutsideCreator from '../OnClickOutside/OnClickOutside';
import SwipeDetector from '../../utils/lib/SwipeDetector';
import ScrollView from '../../components/ScrollView/SmartScrollView';
import AgainstIcon from '../../pages/DebateMarkers/votings/icons/Against';
import FavourIcon from '../../pages/DebateMarkers/votings/icons/Favour';
import Icon from '../../components/Icon/Icon';
import Button from '../../components/Button/Button';

const OnClickOutside = OnClickOutsideCreator.withConfig({ ignoreClass: 'Link--infoPanel' }),
  /**
   * The component definition
   * @type {Object}
   */
  definition = {
    contextTypes: {
      getService: PropTypes.func.isRequired,
      getCursor: PropTypes.func.isRequired,
    },

    mixins: [OnClickOutside],

    propTypes: {
      infoPanel: PropTypes.object.isRequired,
    },

    componentDidMount: function () {
      this._setSwipeDetector();
    },

    componentWillUnmount: function () {
      if (this._swipeDetector) {
        this._swipeDetector.dispose();
        this._swipeDetector = null;
      }
    },

    /**
     * Set swipe detector.
     * @private
     */
    _setSwipeDetector: function () {
      const container = ReactDOM.findDOMNode(this);

      if (this._swipeDetector) {
        return;
      }

      this._swipeDetector = new SwipeDetector(container);
      this._swipeDetector.onSwipe = this._handleSwipe;
      this._swipeDetector.init();
    },

    /**
     * Handle swipe event.
     * @param event
     * @private
     */
    _handleSwipe: function (event) {
      if (event.direction === SwipeDetector.RIGHT()) {
        this.closePanel();
      }
    },

    /**
     * Closes the panel
     */
    closePanel: function () {
      this.context.getService('info-panel').hide();
    },

    handleClickOutside: function () {
      this.closePanel();
    },
  },
  /**
   * The TerminologyPanel component
   * @return {React.Component}       An instance of the TerminologyPanel component
   */
  render = function () {
    const { getCursor } = this.context,
      terminology = getCursor(['data', 'terminology']).reduce((obj, curr) => {
        return obj.set(curr.get('slug'), curr);
      }, immutable.fromJS({})),
      description = terminology.getIn(['live-registratie-stemmingen', 'description'], '');

    /* eslint-disable no-extra-parens */
    return (
      <aside ref="main" className="InfoPanel u-bgWhite">
        <Button className="InfoPanel-closeButton Button Button--close" onClick={this.closePanel} aria-label="Sluit paneel">
          <Icon name="close" />
        </Button>
        <ScrollView>
          <div className="InfoPanel-content Explanation">
            <div className="u-pt70 u-pl20 u-pb20 u-pr20 u-bgWhite">
              <header className="InfoPanel-header Header">
                <h3 className="InfoPanel-heading Heading">Stemmingen</h3>
              </header>
              {description.split('\n\n').map((paragraph, index) => (
                <p className="Text" key={index}>
                  {paragraph}
                </p>
              ))}
              <ul className="List Text">
                <li className="u-flex u-pb10">
                  <div>
                    <div className="VoteExplanation VoteExplanation--liveUnknown">
                      <span />
                    </div>
                  </div>
                  <span>{terminology.getIn(['stem-live-onbekend', 'name'], '')}</span>
                </li>
                <li className="u-flex u-pb10">
                  <div>
                    <div className="VoteExplanation VoteExplanation--liveFavour">
                      <span>
                        <FavourIcon />
                      </span>
                    </div>
                  </div>
                  <span>{terminology.getIn(['stem-live-voor', 'name'], '')}</span>
                </li>
                <li className="u-flex u-pb10">
                  <div>
                    <div className="VoteExplanation VoteExplanation--liveAgainst">
                      <span>
                        <AgainstIcon />
                      </span>
                    </div>
                  </div>
                  <span>{terminology.getIn(['stem-live-tegen', 'name'], '')}</span>
                </li>
                <li className="u-flex u-pb10">
                  <div>
                    <div className="VoteExplanation VoteExplanation--verifiedFavour">
                      <span>
                        <FavourIcon />
                      </span>
                    </div>
                  </div>
                  <span>{terminology.getIn(['stem-gecontroleerd-voor', 'name'], '')}</span>
                </li>
                <li className="u-flex u-pb10">
                  <div>
                    <div className="VoteExplanation VoteExplanation--verifiedAgainst">
                      <span>
                        <AgainstIcon />
                      </span>
                    </div>
                  </div>
                  <span>{terminology.getIn(['stem-gecontroleerd-tegen', 'name'], '')}</span>
                </li>
                <li className="u-flex">
                  <div>
                    <div className="VoteExplanation VoteExplanation--verifiedUnknown">
                      <span />
                    </div>
                  </div>
                  <span>{terminology.getIn(['stem-gecontroleerd-onbekend', 'name'], '')}</span>
                </li>
              </ul>
            </div>
          </div>
        </ScrollView>
      </aside>
    );
    /* eslint-enable no-extra-parens */
  };

export default component('VotingExplanationPanel', definition, render);
