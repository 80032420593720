import moment from 'moment';
import { getDataFromManifest } from '@debatdirect/core/utils/hls';

import * as logger from '../../utils/logger';

import EventDispatcher from './EventDispatcher';

class CastTextTrack extends EventDispatcher {
  kind = 'subtitles';
  inBandMetadataTrackDispatchType = '';
  readyState = 2;
  src = '';
  type = '';
  _mode = 'disabled';

  constructor(id, name, language) {
    super();
    this.id = id;
    this.name = name;
    this.language = language;
  }

  get mode() {
    return this._mode;
  }

  set mode(to) {
    this._mode = to;
    this.dispatchEvent({ type: 'change', track: this });
  }
}

/**
 * The CastPlayer bridges the cordova-plugin-cast API and aligns it with the Player API.
 */
export default class CastPlayer extends EventDispatcher {
  // is the cast plugin initialized
  _initialized = false;

  // is a cast receiver available
  _available = false;

  // current casting session
  _session = null;

  // current playing media
  _media = null;

  // source object to load (or is loaded)
  _source = null;

  // conditional logic for live streams
  _isLive = false;

  // initial media start time
  _startTime = 0;

  // the last known position notified via the timeupdate event
  _lastPosition = 0;

  // the last known duration notified via the timeupdate and durationchange events
  _lastDuration = 0;

  // last known player state
  _lastState = window.chrome.cast.media.PlayerState.IDLE;

  // the available textTracks (following the TextTrack API)
  _textTracks = [];

  // true when the media stream has subtitles available
  _hasTextTracks = false;

  // true when the subtitles are activated on the Chromecast device
  _textTrackActive = false;

  // the first PDT moment instance
  _firstPdt = undefined;

  static _instance = undefined;

  static getInstance() {
    if (!CastPlayer._instance) CastPlayer._instance = new CastPlayer();

    return CastPlayer._instance;
  }

  /**
   * Initialize the Cast service and plugin
   * @return {Promise<void>}
   */
  initialize = async (appId) => {
    if (!window.chrome) throw new Error('window.chrome is undefined');

    if (this._initialized) return;
    this._initialized = true;

    const apiConfig = new window.chrome.cast.ApiConfig(
      new window.chrome.cast.SessionRequest(appId),
      (session) => {
        this._onSession(session);

        this.dispatchEvent({ type: 'statechange', state: this.getState() });
      },
      (receiverAvailable) => {
        this.dispatchEvent({ type: 'statechange', state: this.getState() });
        this._available = receiverAvailable;
      },
    );

    // initialize chromecast, this must be done before using other chromecast features
    return new Promise((resolve) => {
      window.chrome.cast.initialize(apiConfig, resolve, function (err) {
        // Initialize failure
        logger.error('Initialize error', err);
        resolve();
      });
    });
  };

  requestSession = async (startTime = 0) => {
    this._startTime = startTime;

    return new Promise((resolve) => {
      window.chrome.cast.requestSession(
        (session) => {
          this._onSession(session);
          resolve();
        },
        function () {
          resolve();
        },
      );
    });
  };

  loadSource = async () => {
    // no sources defined
    if (!this._source) return;

    if (this._media && this._media.media?.contentId === this._source.url) {
      return this.dispatchEvent({ type: 'statechange', state: this.getState() });
    }

    if (!this._session) throw new Error('No cast session active...');

    // make sure to reset everything
    this._mediaTerminatedHandler();

    const videoUrl = this._source.url;
    const mediaInfo = new window.chrome.cast.media.MediaInfo(videoUrl, 'application/x-mpegurl');
    const mediaMetadata = new window.chrome.cast.media.GenericMediaMetadata();

    mediaMetadata.title = this._source.title;
    mediaMetadata.subtitle = this._source.subtitle;
    mediaInfo.metadata = mediaMetadata;

    const loadRequest = new window.chrome.cast.media.LoadRequest(mediaInfo);

    // `Math.round` is needed for the Android plugin to work, otherwise it results in an `invalid action` error
    loadRequest.currentTime = Math.round(this._startTime);

    const { firstPdt } = await getDataFromManifest(this._source.audio);

    this._firstPdt = firstPdt;

    // if it is a live stream, we don't know the duration yet. That's why we seek to a large number make it seek to
    // the live moment. Otherwise, it will start from the beginning of the live stream.
    if (this._isLive) {
      mediaInfo.streamType = window.chrome.cast.media.StreamType.LIVE;
      loadRequest.currentTime = this._startTime === 0 ? 999999 : this._startTime;

      // enable the seeking state until we are playing live
      this._seeking = true;
    }

    // reset start time for the next load
    this._startTime = 0;

    return new Promise((resolve) => {
      this._session.loadMedia(
        loadRequest,
        (media) => {
          this._onMedia(media);
          this.dispatchEvent({ type: 'statechange', state: this.getState() });

          resolve();
        },
        (err) => {
          logger.error('Load media error', err);
          resolve();
        },
      );
    });
  };

  play = () => {
    if (!this._media) return this.loadSource();

    return new Promise((resolve) => this._media.play({}, resolve));
  };

  pause = () => {
    if (!this._media) return this.loadSource();

    return new Promise((resolve) => this._media.pause({}, resolve));
  };

  seek = (value) => {
    if (!this._media) return this.loadSource();

    this.dispatchEvent({ type: 'seeking' });
    this._seeking = true;

    return new Promise((resolve) => this._media.seek({ currentTime: value }, resolve)).finally(() => {
      this.dispatchEvent({ type: 'seeked' });
      this._seeking = false;
    });
  };

  stop = () => {
    if (!this._session) throw new Error('No cast session active...');

    return new Promise((resolve) => {
      this._session.stop(resolve, resolve);
    });
  };

  isPlaying() {
    const PlayerState = window.chrome.cast.media.PlayerState;

    return [PlayerState.PLAYING, PlayerState.BUFFERING].includes(this._media?.playerState);
  }

  getState() {
    if (this._session) return 'connected';

    return this._available ? 'available' : 'unavailable';
  }

  getReceiverName() {
    return this._session?.receiver?.friendlyName || '';
  }

  getAvailable() {
    return this._available;
  }

  getCasting() {
    return !!this._session;
  }

  getCurrentTime() {
    return this._media?.getEstimatedTime() || 0;
  }

  getDuration() {
    if (this._media?.media?.duration === -1) return Infinity;

    return this._media?.media?.duration || 0;
  }

  getCurrentProgramDateTime() {
    if (!this._firstPdt) return new Date();

    return this._firstPdt.clone().add(this._lastPosition, 's').toDate();
  }

  getSeekableEnd() {
    const duration = this.getDuration();

    if (duration !== Infinity) return duration;
    if (!this._firstPdt) return 0;

    return Math.max(0, moment().diff(this._firstPdt, 's') - 35) || 0;
  }

  setSource(source, isLive) {
    this._source = source;
    this._isLive = isLive;

    if (this._session) {
      this.loadSource();
    }
  }

  getTextTracks() {
    return this._textTracks;
  }

  handleAppResume() {
    // timeout is needed to give the cast plugin some time to restore the media session
    // otherwise eventListeners are not bound correctly and will not work
    setTimeout(() => {
      if (this._session) this._onSession(this._session);
    }, 1000);
  }

  _startTimeUpdater = () => {
    if (typeof this._timeUpdaterInterval === 'number') return;
    this._timeUpdaterInterval = setInterval(this._timeUpdaterHandler, 500);
  };

  _timeUpdaterHandler = () => {
    const updatedPosition = Math.round(this._media?.getEstimatedTime());
    let updatedDuration = this._media?.media?.duration;

    // prevent time updates while performing a seek (there is a delay)
    if (this._seeking) return;

    if (updatedDuration === -1) updatedDuration = Infinity;

    if (updatedDuration !== this._lastDuration) {
      this._lastDuration = updatedDuration;
      this.dispatchEvent({ type: 'durationchange', duration: updatedDuration, currentTime: updatedPosition });
    }

    if (updatedPosition !== this._lastPosition) {
      this._lastPosition = updatedPosition;
      this.dispatchEvent({ type: 'timeupdate', duration: updatedDuration, currentTime: updatedPosition });
    }
  };

  _stopTimeUpdater = () => {
    clearInterval(this._timeUpdaterInterval);
    this._timeUpdaterInterval = undefined;
  };

  _mediaUpdateListener = () => {
    const playerState = this._media.playerState;

    if (!this._media.isAlive) {
      this._media = null;
      return this._mediaTerminatedHandler();
    }

    this._updatePlaybackTimer();
    this._maybeUpdatePlayerState();
    this._textTracksUpdater();

    this._lastState = playerState;
    this.dispatchEvent({ type: 'statechange', state: this.getState() });
  };

  _mediaTerminatedHandler = () => {
    // clear the state
    this._lastState = window.chrome.cast.media.PlayerState.IDLE;
    this._lastPosition = 0;
    this._lastDuration = 0;
    this._textTracks = [];
    this._seeking = false;
    this._hasTextTracks = false;

    this.dispatchEvent({ type: 'statechange', state: this.getState() });
    this._stopTimeUpdater();
  };

  _updatePlaybackTimer = () => {
    const playerState = this._media.playerState;

    if (playerState === window.chrome.cast.media.PlayerState.PLAYING) {
      this._startTimeUpdater();
    } else {
      this._stopTimeUpdater();
    }
  };

  _maybeUpdatePlayerState = () => {
    const playerState = this._media.playerState;

    // nothing changed
    if (this._lastState === playerState) return;

    // player state has changed, dispatch the corresponding event
    if (playerState === window.chrome.cast.media.PlayerState.PLAYING) {
      this.dispatchEvent({ type: 'playing' });
      this._seeking = false;
    } else if (playerState === window.chrome.cast.media.PlayerState.PAUSED) {
      this.dispatchEvent({ type: 'paused' });
    }
  };

  _textTracksUpdater = () => {
    if (!this._media) return;

    const tracks = this._media.media?.tracks;
    const streamHasTextTracks = tracks?.length > 1;

    if (streamHasTextTracks && !this._hasTextTracks) {
      // a textTrack has been added, create a CastTextTrack and dispatch an event
      const textTrack = tracks[1];
      const castTextTrack = new CastTextTrack(textTrack.trackId, textTrack.name, textTrack.language);

      castTextTrack.addEventListener('change', ({ track }) => {
        this._setTextTrackMode(track.mode === 'showing');
      });

      this._hasTextTracks = true;
      this._textTracks = [castTextTrack];
      this.dispatchEvent({ type: 'addtrack' });
    } else if (!streamHasTextTracks && this._hasTextTracks) {
      // textTrack is removed, first deactivate it
      this._hasTextTracks = false;
      this._textTrackActive = false;
      this.dispatchEvent({ type: 'changetrack' });

      // clear textTracks and dispatch the event
      this._textTracks = [];
      this.dispatchEvent({ type: 'removetrack' });
    }

    if (streamHasTextTracks) {
      // `true` when the text track is active on the Chromecast device
      const streamTextTrackActive = this._media?.activeTrackIds?.length > 1;

      if (streamTextTrackActive !== this._textTrackActive && this._textTracks.length > 0) {
        // the subtitles are (de)activated from the cast receiver using the remote control.
        this._textTrackActive = streamTextTrackActive;
        this._textTracks[0].mode = streamTextTrackActive ? 'showing' : 'disabled';
        this.dispatchEvent({ type: 'changetrack' });
      }
    }
  };

  _setTextTrackMode = (active) => {
    if (active === this._textTrackActive) return;

    this._media.editTracksInfo({ activeTrackIds: active ? [1, 2] : [1] }, () => {
      this.dispatchEvent({ type: 'changetrack' });
    });
  };

  _sessionUpdateListener = () => {
    if (this._session?.status === window.chrome.cast.SessionStatus.STOPPED) {
      this._onSessionTerminate();
    }
  };

  _onSession = async (session) => {
    this._session = session;

    this._session.removeUpdateListener(this._sessionUpdateListener);
    this._session.addUpdateListener(this._sessionUpdateListener);

    if (session.media?.[0]) {
      await this._onMedia(session.media?.[0]);
    }

    await this.loadSource();

    this.dispatchEvent({ type: 'statechange', state: this.getState() });
  };

  _onSessionTerminate = async () => {
    this._stopTimeUpdater();
    this._session = null;
    this._media = null;

    this.dispatchEvent({ type: 'disconnect', currentTime: this._lastPosition, state: this._lastState });
    this.dispatchEvent({ type: 'statechange', state: this.getState() });
  };

  _onMedia = async (media) => {
    this._media = media;
    this._media.removeUpdateListener(this._mediaUpdateListener);
    this._media.addUpdateListener(this._mediaUpdateListener);

    if (!this._firstPdt && this._source.audio) {
      const { firstPdt } = await getDataFromManifest(this._source.audio);
      this._firstPdt = firstPdt;
    }
  };
}
