import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import component from 'omniscient';

import { byId } from '../../predicates';
import { trackDebateName, trackEventThrottled } from '../../common/lib/trackEvent';

import Player from './Player';
import NativeMediaPlayer from './NativeMediaPlayer';

const componentDefinition = {
  initializePlayers: function () {
    // This needs to be an explicit check against function because the app boostrap
    // Already writes config data to window.theoplayer object before theoplayer is initialized
    if (typeof window.THEOplayer === 'undefined') {
      return setTimeout(() => this.initializePlayers(), 500);
    }

    const { getService } = this.context,
      playerConfig = this.context.getCursor(['config', 'theoplayer']),
      chromecastConfig = this.context.getCursor(['config', 'chromecast']),
      playerUrl = playerConfig.get('url'),
      license = playerConfig.get('license'),
      appId = chromecastConfig.get('appId'),
      sources = this.props.sources,
      poster = this.props.poster,
      audioPlayer = ReactDOM.findDOMNode(this.refs.audio),
      videoPlayer = ReactDOM.findDOMNode(this.refs.video),
      platformService = getService('platform'),
      useNativeAudio = !!window.cordova && platformService.isIOS(),
      // lowLatency doesn't work property in Safari, we currently disable it for all iOS devices and safari browsers
      lowLatency = !platformService.isIOS() && platformService.getPlatform() !== 'ios' && !platformService.isSafari(),
      debateName = this.props.debate ? this.props.debateName : this.props.location || 'Onbekende zaal',
      debateMoment = this.props.debate ? moment(this.props.debate.get('startsAt')) : moment(),
      subtitleFile = this.props.debate ? this.props.debate.getIn(['video', 'subtitleUrl']) : null,
      debateDate = debateMoment.format('YYYY-MM-DD'),
      debateTitle = `${debateDate} / ${debateName}`;

    const textTracks = subtitleFile
      ? [
          {
            src: subtitleFile,
            kind: 'subtitles',
            label: 'Nederlands',
            srclang: 'nl',
          },
        ]
      : [];

    let players;

    try {
      /*
       If theoplayer throws an exception during initialization,
       the callstack will unwind to such an extent that the app ends up in a state of disrepair.
       We want to prevent this.
      */
      players = {
        audio: useNativeAudio
          ? new NativeMediaPlayer()
          : new window.THEOplayer.Player(audioPlayer, {
              libraryLocation: playerUrl,
              license,
              isEmbeddable: true,
              persistVolume: true,
              cast:
                appId && !window.cordova
                  ? {
                      chromecast: {
                        appID: appId,
                      },
                    }
                  : false,
            }),
        video: new window.THEOplayer.Player(videoPlayer, {
          libraryLocation: playerUrl,
          license,
          isEmbeddable: true,
          persistVolume: true,
          cast:
            appId && !window.cordova
              ? {
                  chromecast: {
                    appID: appId,
                  },
                }
              : false,
        }),
      };

      players.video.preload = players.audio.preload = 'metadata';

      players.audio.source = {
        sources: [
          {
            src: sources.audio,
            type: 'application/x-mpegurl',
            lowLatency: false, // disabled because it doesn't play the audio only manifest
            poster,
          },
        ],
      };

      players.video.source = {
        sources: [
          {
            src: sources.video,
            type: 'application/x-mpegurl',
            lowLatency,
            poster,
          },
        ],
        textTracks,
      };

      players.video.videoTracks.addEventListener('addtrack', this.handleAddTrack);

      if (window.Matomo && window.Matomo.MediaAnalytics) {
        window.Matomo.MediaAnalytics.scanForMedia();
      }
    } catch (e) {
      if (import.meta.env.DEV) {
        console.error('Error during initialization of theoplayer: ', e);
      }

      return;
    }

    const configuration = {
      audioOnly: this.props.audioOnly,
      debateName: this.props.debateName,
      isLive: this.props.isLive,
      debateTitle: debateTitle,
      sources: sources,
      castAppId: appId,
    };

    this.player = new Player(players, configuration);
    this.player.init();

    // bootstrap.js in app needs to be able to call player methods!
    window.player = this.player;

    if (this.player) {
      this.player.waiting = false;

      this.player.addEventListener('error', this.handleError);
      this.player.addEventListener('waiting', this.handleWaiting);
      this.player.addEventListener('playing', this.handlePlaying);
      this.player.addEventListener('seeked', this.handleSeeked);

      const { getService } = this.context;

      getService('video-sync').setInstance(this.player);
      getService('video').setPlayer(this.player);

      if (platformService.isIOS()) {
        getService('video-sync').pdtFromUrl(sources.audio);
      }

      // Set debateTitle for Matomo event tracking and Media Analytics
      const mainContainer = ReactDOM.findDOMNode(this),
        setElementTitle = (element) => (element ? element.setAttribute('data-matomo-title', debateTitle) : null);

      trackDebateName(debateTitle);
      setElementTitle(mainContainer.querySelector('video'));
      setElementTitle(mainContainer.querySelector('audio'));
      setElementTitle(players.video.element.querySelector('video'));
      setElementTitle(players.video.element.querySelector('audio'));
    }

    this.clearTabIndexes();
  },

  componentDidMount: function () {
    const route = this.context.route;
    const isEmbedded = this.context.getService('router').isEmbedded(route);

    if (!isEmbedded) {
      this.initializePlayers();
    }

    this.syncDebateVideoTime();
  },

  componentWillUnmount: function () {
    try {
      const syncService = this.context.getService('video-sync'),
        videoService = this.context.getService('video');

      if (!this.player) {
        return;
      }

      // Delete global.
      window.player = null;

      videoService.unsetPlayer();

      syncService.stop();
      syncService.removeInstance();

      // Dispose player.
      this.player.dispose();
      this.player = null;
    } catch (e) {
      if (import.meta.env.DEV) {
        console.error('Error during player cleanup: ' + e.message);
      }
    }
  },

  componentWillUpdate: function (nextProps) {
    if (!this.player) {
      return;
    }

    const sources = this.props.sources,
      video = sources ? sources.video : '',
      nextSources = nextProps.sources,
      nextVideo = nextSources ? nextSources.video : '';

    if (nextVideo !== video || this.props.isLive !== nextProps.isLive) {
      this.player.setSources(nextSources, nextProps.isLive);
    }
  },

  componentDidUpdate: function (prevProps) {
    const video = this.props.sources?.video || null;
    const prevVideo = prevProps.sources?.video || null;

    if (video && prevVideo !== video) {
      this.syncDebateVideoTime();
    }

    this.clearTabIndexes();
  },

  componentWillReceiveProps: function (nextProps) {
    const route = this.context.route;
    const isEmbedded = this.context.getService('router').isEmbedded(route);

    if (isEmbedded && nextProps.playRequested && !this.player) {
      this.initializePlayers();

      if (this.player) {
        this.player.play();
      }
    }
  },

  syncDebateVideoTime: function () {
    const debate = this.props.debate;
    if (debate) {
      const locations = this.context.getCursor(['data', 'locations']);
      const location = locations.find(byId(debate.get('locationId')));
      const locationPdtOffset = location.getIn(['videoOffset', 'stream'], 0);
      const pdtOffset = debate.getIn(['video', 'pdtOffset'], locationPdtOffset);
      this.context.getService('video-sync').start('pdt', pdtOffset, debate.get('startedAt'));
    }
  },

  /**
   * Theoplayer sets tabindex attribute on its main container.
   * When it's container has keyboard focus it even performs keyboard actions (e.g. space bar for play/pause toggle).
   * This behavior causes unexpected behavior and needs to be overridden.
   */
  clearTabIndexes: function () {
    const platformService = this.context.getService('platform');
    const mainContainer = ReactDOM.findDOMNode(this);

    if (!mainContainer) {
      return;
    }

    const tabIndexElements = mainContainer.querySelectorAll('[tabindex]');

    for (let i = 0; i < tabIndexElements.length; i++) {
      tabIndexElements[i].removeAttribute('tabindex');
    }

    // The video element is by default reachable by tabbing in FF, we clear this.
    const videoElements = mainContainer.querySelectorAll('video');

    for (let i = 0; i < videoElements.length; i++) {
      videoElements[i].setAttribute('tabindex', '-1');

      // <video> element is accessible on Android TalkBack even though tabIndex is set to -1.
      // This causes the video element to be read as "media unplayable". We only apply this
      // fix for Android because on iOS VoiceOver it disrupts the tabbing order.
      if (platformService.isAndroid()) {
        videoElements[i].setAttribute('aria-hidden', 'true');
      }
    }
  },

  handleWaiting: function () {
    this.player.waiting = true;
  },

  handlePlaying: function () {
    if (!this.props.debate) return;
    this.player.waiting = false;
  },

  handleError: function (error) {
    const { getService } = this.context;

    // "Something went wrong during native playback" can be triggered solely by an old user agent string
    trackEventThrottled('debate', 'playerError', error.error, 1);

    if (import.meta.env.DEV) {
      console.error(`Player error: ${error.error}`, error.errorObject);
    }

    getService('prompt').prompt({
      identifier: 'VideoError',
      dismissable: true,
      error: error.error,
    });
  },

  handleSeeked: function () {
    this.player.waiting = false;
  },

  handleAddTrack: function () {
    if (this.player.videoTracks.length === 0) return;

    // find the second-highest quality
    const { qualities } = this.player.videoTracks[0];
    const targetQuality = qualities.find((quality) => quality.height >= 720);

    if (!targetQuality) return;

    this.player.videoTracks[0].targetQuality = targetQuality;

    // reset target quality when we have a buffer of +10 seconds (this enables automatic bitrate switching)
    const resetTargetQualityHandler = () => {
      const { buffered } = this.player;

      if (this.player.videoTracks.length && buffered.length > 0 && buffered.end(buffered.length - 1) > 10) {
        this.player.videoTracks[0].targetQuality = null;
        this.player.removeEventListener('progress', resetTargetQualityHandler);
      }
    };

    this.player.addEventListener('progress', resetTargetQualityHandler);
  },

  shouldComponentUpdate: function () {
    // don't update source while playing
    if (!this.player) {
      return true;
    }

    return this.player.paused;
  },

  getDefaultProps: function () {
    return {
      controls: false,
    };
  },

  contextTypes: {
    getCursor: PropTypes.func.isRequired,
    getService: PropTypes.func.isRequired,
    route: PropTypes.object.isRequired,
  },

  propTypes: {
    controls: PropTypes.bool,
    placeholder: PropTypes.string,
    sources: PropTypes.object,
    debateName: PropTypes.string,
    isLive: PropTypes.bool,
  },
};

export default component('TheoMedia', componentDefinition, function () {
  return (
    <div className="Media">
      <div className="Media__Audio">
        <div className="Video" ref="audio" />
      </div>
      <div className="Media__Video">
        <div className="Video" ref="video" />
      </div>
    </div>
  );
});
