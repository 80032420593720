import React from 'react';
import classNames from 'classnames';
import component from 'omniscient';

import Button from '../../components/Button/Button';
import Icon from '../../components/Icon/Icon';

import validators from './validators';

const getButtonLabel = (state) => {
    if (2 === state) {
      return 'Verzonden';
    }

    if (1 === state) {
      return 'Uw bericht wordt verzonden';
    }

    return 'Versturen';
  },
  renderButton = (state, isDisabled) => {
    const label = getButtonLabel(state),
      disabled = isDisabled || state > 0,
      className = classNames({
        'Form-button': true,
        'Button--primary': true,
        'Button--submit': true,
        'Button--gray': state > 0,
      }),
      props = {
        type: 'submit',
        className,
        disabled,
      };

    return <Button {...props}>{label}</Button>;
  },
  /**
   * Decorates the passed formFIeld with a label and optional error
   * @param  {String}          name        The field name
   * @param  {String}          label       The field label
   * @param  {String}          placeholder The placeholder and label value
   * @param  {React.Component} formField   The form field to render
   * @param  {Object}          [error]     The formField
   * @return {React.Component}             The dom tree
   */
  renderFormField = (name, label, formField, error) => {
    return (
      <div>
        <label className="Label Form-label" htmlFor={name}>
          {label}
        </label>
        {error && (
          <p className="Form-error" id={`error-${name}`}>
            {error.message + ':'}
          </p>
        )}
        {formField}
      </div>
    );
  },
  /**
   * Renders a select box
   * @param  {String}          id          The field id
   * @param  {String}          name        The field name
   * @param  {String}          label       The field label
   * @param  {String}          placeholder The placeholder and label value
   * @param  {Array}           errors      The form errors
   * @return {React.Component}             The dom tree
   */
  renderSelect = (id, name, label, placeholder, errors) => {
    const error = findRelevantError(errors, id);
    const className = classNames({
      'Form-input': true,
      'Form-input--select': true,
      'Form-input--error': !!error,
    });

    const formField = (
      <div className="Select">
        <select className={className} id="subjectType" name="onderwerp" aria-required>
          <option value="" disabled={true}>
            Soort bericht
          </option>
          <option value="question">Vragen over Debat Direct</option>
          <option value="question-tk">Vragen over de Tweede Kamer der Staten-Generaal</option>
          <option value="suggestion">Suggesties voor Debat Direct</option>
          <option value="other">Overige vragen of opmerkingen</option>
        </select>
        <Icon className="Select-icon Icon u-inline" name="expand-alt" width="16" height="16" aria-hidden="true" />
      </div>
    );

    return renderFormField(id, label, formField, error);
  },
  /**
   * Renders an email field
   * @param  {String}          id          The field id
   * @param  {String}          name        The field name
   * @param  {String}          label       The field label
   * @param  {String}          placeholder The placeholder and label value
   * @param  {Array}           errors      The form errors
   * @return {React.Component}             The dom tree
   */
  renderEmailField = (id, name, label, placeholder, errors) => {
    var className,
      formField,
      error = findRelevantError(errors, id),
      ariaAttributes = {};

    className = classNames({
      'Form-input': true,
      'Form-input--text': true,
      'Form-input--error': !!error,
    });

    if (error) {
      ariaAttributes['aria-invalid'] = true;
      ariaAttributes['aria-describedby'] = `error-${id}`;
    }

    formField = (
      <input type="email" className={className} id={id} name={name} placeholder={placeholder} {...ariaAttributes} autoComplete="email" aria-required />
    );

    return renderFormField(id, label, formField, error);
  },
  /**
   * Renders a textarea
   * @param  {String}          id          The field id
   * @param  {String}          name        The field name
   * @param  {String}          label       The field label
   * @param  {String}          placeholder The placeholder and label value
   * @param  {Array}           errors      The form errors
   * @return {React.Component}             The dom tree
   */
  renderTextarea = (id, name, label, placeholder, errors) => {
    var className,
      formField,
      error = findRelevantError(errors, id),
      ariaAttributes = {};

    className = classNames({
      'Form-input': true,
      'Form-input--textarea': true,
      'Form-input--error': !!error,
    });

    if (error) {
      ariaAttributes['aria-invalid'] = true;
      ariaAttributes['aria-describedby'] = `error-${id}`;
    }

    formField = <textarea ref={id} className={className} id={id} name={name} placeholder={placeholder} {...ariaAttributes} aria-required />;

    return renderFormField(id, label, formField, error);
  },
  /**
   * Renders a text input
   * @param  {String}                      id          The field id
   * @param  {String}                      name        The field name
   * @param  {String}                      label       The field label
   * @param  {String}                      placeholder The placeholder and label value
   * @param  {Array}                       errors      The form errors
   * @return {React.Component}                         The dom tree
   */
  renderTextfield = (id, name, label, placeholder, errors) => {
    var className,
      formField,
      error = findRelevantError(errors, id),
      ariaAttributes = {
        'aria-required': true,
      };

    className = classNames({
      'Form-input': true,
      'Form-input--text': true,
      'Form-input--error': !!error,
    });

    if (error) {
      ariaAttributes['aria-invalid'] = true;
      ariaAttributes['aria-describedby'] = `error-${id}`;
    }

    formField = <input ref={id} type="text" className={className} id={id} name={name} placeholder={placeholder} {...ariaAttributes} autoComplete="name" />;

    return renderFormField(id, label, formField, error);
  },
  /**
   * Find matching error in list.
   * @param {Array} errors
   * @param {String} field
   * @returns {Object|null}
   */
  findRelevantError = (errors, field) => {
    for (let i = 0; i < errors.length; i++) {
      let error = errors[i];

      if (error.field === field) {
        return error;
      }
    }

    return null;
  },
  /**
   * Component definition
   * @type {Object}
   */
  definition = {
    getInitialState: function () {
      return {
        errors: [],
        isValid: false,
      };
    },

    componentDidMount: function () {
      this.refs.form.addEventListener('focus', this.handleFocus, true);
    },

    componentWillUnmount: function () {
      this.refs.form.removeEventListener('focus', this.handleFocus, true);
    },

    handleFocus: function (event) {
      if (['input', 'textarea'].indexOf(event.target.nodeName.toLowerCase()) === -1) {
        return;
      }
    },

    onSubmitProxy: function (event) {
      event.preventDefault();

      const data = this.getFormData(event.target),
        errors = this.getErrors(data),
        isValid = !errors.length;

      this.setState({
        isValid: isValid,
        errors: errors,
      });

      if (!isValid) {
        return false;
      }

      this.props.onSubmit(event, data);
    },

    /**
     * Generate error list.
     * @param {Object} data
     * @returns {Array}
     */
    getErrors: function (data) {
      const result = [];

      for (let i = 0, keys = Object.keys(data); i < keys.length; i++) {
        let key = keys[i],
          value = data[key],
          validatorResult = validators[key](value);

        result.push.apply(result, validatorResult);
      }

      return result;
    },

    /**
     * Collect form data.
     * @param {HTMLFormElement} form
     * @returns {{name: string, email: string, subjectType: string, message: string}}
     */
    getFormData: function (form) {
      const result = {
          name: '',
          email: '',
          subjectType: '',
          message: '',
        },
        fields = form.querySelectorAll('input, textarea, select');

      for (let i = 0; i < fields.length; i++) {
        let field = fields[i],
          id = field.getAttribute('id'),
          value = field.value;

        if (id && Object.prototype.hasOwnProperty.call(result, id)) {
          result[id] = value;
        }
      }

      return result;
    },
  },
  /**
   * Component render function
   * @param  {Object}          props The component props
   * @return {React.Component}       The dom tree
   */
  render = function (props) {
    const { state } = props,
      { errors } = this.state,
      style = { marginBottom: '250px' }; // Make sure the bottom fields are visible when the on-screen keyboard is showing.

    return (
      <form ref="form" className="Contact-form Form" style={style} onSubmit={this.onSubmitProxy}>
        <div className="Form-body">
          {errors.length ? (
            <div role="alert" aria-live="assertive" className="Form-general-error">
              Verbeter de onderstaande velden en probeer het opnieuw
            </div>
          ) : null}
          <div className="Form-row">{renderTextfield('name', 'naam', 'Naam', 'Uw naam', errors)}</div>
          <div className="Form-row">{renderEmailField('email', 'email', 'E-mailadres', 'Uw e-mailadres', errors)}</div>
          <div className="Form-row">{renderSelect('subjectType', 'onderwerp', 'Onderwerp', 'Dit betreft een', errors)}</div>
          <div className="Form-row">{renderTextarea('message', 'bericht', 'Bericht', 'Uw bericht', errors)}</div>
        </div>
        <div className="Form-actions">
          {renderButton(state, false)}
          {state === 2 && (
            <div role="alert" aria-live="assertive" className="srOnly">
              Uw bericht is verzonden!
            </div>
          )}
        </div>
      </form>
    );
  };

export default component.withDefaults({ cursorField: 'cursor' })('ContactForm', definition, render);
