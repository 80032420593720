import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import component from 'omniscient';
import observer from 'omnipotent/decorator/observer';
import moment from 'moment';

import { wrapLastWordWith } from '../../common';
import { DEBATE_TYPE_SUBSCRIPTIONS } from '../../services/PushNotificationsService/subscriptionTypes';
import { structure } from '../../core';
import ScrollView from '../../components/ScrollView/SmartScrollView';
import Button from '../../components/Button/Button';
import Icon from '../../components/Icon/Icon';
import Link from '../../components/Link/Link';
import DebateNotificationButton from '../../components/DebateNotificationButton/DebateNotificationButton';
import Time from '../../components/Time/Time';
import { byId } from '../../predicates';
import { MoreVert } from '../../icons/index';
import DebateSubjectPopover from '../../components/Popovers/DebateSubjectPopover';

import DebatePlayLiveButton from './DebatePlayLiveButton';
import DebatePlayBeginButton from './DebatePlayBeginButton';
import DebateContinueButton from './DebateContinueButton';

const /**
   * Creates a PlayLiveButton
   * @param  {Function} getCursor     For Click handler
   * @param  {Function} getService    For Click handler
   * @param  {String}   className     Additional classNames
   * @return {React.Component}        An instance of PlayLiveButton
   */
  renderPlayLiveButton = (getCursor, getService, hasStarted) => {
    const currentTime = getService('video').getPosition(),
      delayType = getCursor(['ui', 'sync']).get('delayType'),
      isLive = delayType === 'dvr' && currentTime >= -10 && currentTime <= 0,
      disabled = isLive || !hasStarted,
      props = {
        className: classNames('Button u-flex', { 'Link--disabled': disabled }),
        onClick: handlePlayLiveButtonClick(getCursor, getService),
        disabled,
      };

    return <DebatePlayLiveButton {...props} />;
  },
  /**
   * Creates a ContinueButton
   * @param  {Function} getCursor     For Click handler
   * @param  {Function} getService    For Click handler
   * @param  {String}   className     Additional classNames
   * @return {React.Component}        An instance of PlayLiveButton
   */
  renderContinueButton = (debateId, getService) => {
    const wasStored = getService('continue-watching').wasStoredBeforeSession(debateId);

    if (!wasStored) return;

    return <DebateContinueButton className="Button u-flex" onClick={() => handleContinueButtonClick(debateId, getService)} />;
  },
  /**
   * Creates a PlayBeginButton
   * @param  {Boolean}  isPlaying     Determines whether button is visible and clickable
   * @param  {Function} getCursor     For Click handler
   * @param  {Function} getService    For Click handler
   * @param  {String}   className     Additional classNames
   * @return {React.Component}        An instance of PlayBeginButton
   */
  renderPlayBeginButton = (isPlaying, hasStarted, getCursor, getService, className) => {
    const props = {
      className: classNames(
        'Button u-flex',
        {
          'Link--disabled': !hasStarted,
        },
        className,
      ),
      disabled: !hasStarted,
      onClick: handlePlayBeginButtonClick(getCursor, getService),
    };

    return <DebatePlayBeginButton {...props} />;
  },
  /**
   * Render debate introduction
   * @param debate
   * @returns {*}
   */
  renderIntroduction = (debate) => {
    const debateType = debate.get('debateType'),
      description = debate.get('introduction') || 'Er is geen introductie voor dit debat beschikbaar';

    if (debateType === 'Stemmingen') {
      return <ul className={'List'}>{debate.get('votings').map(renderVotingItem)}</ul>;
    }

    return <p className="Text">{description}</p>;
  },
  /**
   * Because the tab panel is hidden after button click, we need to focus the play button
   * Otherwise we will lose the focus on mobile landscape
   */
  focusPlayButton = () => {
    const mobileLandscapeMediaQuery = window.matchMedia('(max-height: 600px) and (orientation: landscape)');
    if (mobileLandscapeMediaQuery.matches) {
      document.querySelector('button.Controlbar-buttonPlay')?.focus();
    }
  },
  /**
   * Creates an event handler to toggle video playback
   * @param  {Function} getCursor  Used to retrieve current root cursor
   * @param  {Function} getService Used to retrieve services
   * @return {Function}            The actual event handler
   */
  handlePlayLiveButtonClick = (getCursor, getService) => () => {
    getService('video').playLive();
    focusPlayButton();
  },
  /**
   * Creates an event handler to toggle video playback
   * @param  {Function} debateId   Used to get pdt from watchHistory
   * @param  {Function} getService Used to retrieve services
   * @return {Function}            The actual event handler
   */
  handleContinueButtonClick = (debateId, getService) => {
    const pdt = getService('continue-watching').getPdtFromWatchHistory(debateId);
    const videoSyncService = getService('video-sync');
    videoSyncService.seekToMoment(moment(pdt), true);
    focusPlayButton();
  },
  /**
   * Creates an event handler to toggle video playback
   * @param  {Function} getCursor  Used to retrieve current root cursor
   * @param  {Function} getService Used to retrieve services
   * @return {Function}            The actual event handler
   */
  handlePlayBeginButtonClick = (getCursor, getService) => () => {
    getService('video').playFromStart();
    focusPlayButton();
  },
  /**
   * Render voting item component
   *
   * @param {Cursor} voting
   * @returns {React.Component} Voting item component
   */
  renderVotingItem = (voting) => {
    const key = voting.get('id'),
      title = voting.get('title');

    return (
      <li className="Text u-bold u-offWhiteText" key={key}>
        {title}
      </li>
    );
  },
  timeFormatter = (ts) => moment(ts, 'YYYY-MM-DDTHH:mm:ssZ').format('HH:mm'),
  /**
   * The component definition
   * @type {Object}
   */
  definition = {
    getInitialState: () => ({
      settingsOpen: false,
      settingsAnchor: null,
    }),

    contextTypes: {
      getService: PropTypes.func.isRequired,
      getCursor: PropTypes.func.isRequired,
      pathWith: PropTypes.func.isRequired,
    },

    debateOptionsButtonRef: React.createRef(),

    createShowPanelHandler: function (type, props) {
      return () => {
        this.context.getService('info-panel').show(type, props);
      };
    },

    keyHandler: function (event, triggeredFunction) {
      const enterOrSpace = event.key === 'Enter' || event.key === ' ' || event.key === 'Spacebar' || event.which === 13 || event.which === 32;

      if (enterOrSpace) {
        triggeredFunction();
      }
    },

    renderChairman: function (chairmanId, politicians) {
      const chairman = politicians.find(byId(chairmanId));

      if (!chairman) {
        return null;
      }

      const firstName = chairman.get('firstName');
      const lastName = chairman.get('lastName');
      const name = firstName + ' ' + lastName;

      const handler = this.createShowPanelHandler('PoliticianPanel', {
        politicianId: chairmanId,
      });

      const content = wrapLastWordWith(name, <Icon name="arrow" className="u-inline" width="8.57" height="12" aria-hidden="true" />);

      return (
        <span>
          {' '}
          voorgezeten door&nbsp;
          <Link onClick={handler} onKeyPress={(event) => this.keyHandler(event, handler)} tabIndex="0" className="Link--infoPanel">
            {content}
          </Link>
        </span>
      );
    },

    renderLocation: function (location) {
      const locationName = location.get('name'),
        staticUrl = this.context.getCursor(['config']).get('staticUrl'),
        interactionService = this.context.getService('interaction-additions'),
        handler = this.createShowPanelHandler('TerminologyPanel', {
          title: locationName,
          description: location.get('description'),
          imageUrl: staticUrl + location.get('thumbnailUrl'),
          additionalContent: () => {
            return interactionService.getEmbedLocationButton(location);
          },
        }),
        content = wrapLastWordWith(locationName, <Icon name="arrow" className="u-inline" width="8.57" height="12" aria-hidden="true" />);

      return (
        <span>
          {' '}
          in de{' '}
          <Link className="Link--infoPanel" tabIndex="0" onClick={handler} onKeyPress={(event) => this.keyHandler(event, handler)}>
            {content}
          </Link>
        </span>
      );
    },

    renderType: function (debateType) {
      if ('Overig' === debateType) {
        return <span>{debateType}</span>;
      }

      const { getCursor } = this.context,
        explanations = getCursor(['data', 'terminology']),
        explanation = explanations.find((explanation) => explanation.get('name') === debateType),
        title = explanation?.get('name'),
        description = explanation?.get('description'),
        handler = this.createShowPanelHandler('TerminologyPanel', {
          title,
          description,
          imageUrl: null,
        }),
        content = wrapLastWordWith(debateType, <Icon name="arrow" className="u-inline" width="8.57" height="12" aria-hidden="true" />);

      return (
        <Link className="Link--infoPanel" tabIndex="0" onClick={handler} onKeyPress={(event) => this.keyHandler(event, handler)}>
          {content}
        </Link>
      );
    },

    /**
     * Creates the sub heading
     * @param  {Cursor}          debate          The debate cursor
     * @param  {Cursor}          current         The sync current cursor
     * @param  {Cursor}          data            The data cursor
     * @return {React.Component}                 The metadata line
     */
    renderSubHeader: function (debate, current, data) {
      const categoryNames = debate.get('categoryNames'),
        locationId = debate.get('locationId'),
        location = data.get('locations').find(byId(locationId)),
        politicians = data.getIn(['actors', 'politicians']),
        // Defaults to 'Overig' if unset
        debateType = debate.get('debateType', 'Overig'),
        chairmanId = current ? current.get('chairman', debate.getIn(['current', 'chairmanId'])) : null;

      return (
        <h3 className="Header-metadata Metadata">
          {categoryNames.count() && categoryNames.join(' - ') + ' - '}
          {this.renderType(debateType)}
          {chairmanId && this.renderChairman(chairmanId, politicians)}
          {location && this.renderLocation(location)}
        </h3>
      );
    },

    renderNotificationButton: function (debate, debateId) {
      // No notification button on website.
      if (!window.cordova) {
        return null;
      }

      const debateType = debate.get('debateType'),
        isSubscribable = DEBATE_TYPE_SUBSCRIPTIONS.includes(debateType);

      // Debate has ended and debate is not subscribable -> show disabled button.
      if (debate.has('endedAt') && !isSubscribable) {
        return null;
      }

      const events = debate.get('events'),
        lastEvent = events && events.count() > 0 ? events.first() : null;

      /// Debate is suspended -> show enabled button with adjusted message.
      if (lastEvent && lastEvent.get('eventType') === 'suspended') {
        return <DebateNotificationButton disabled={false} suspended={true} debate={debate} debateId={debateId} />;
      }

      // Debate has to start, has started or is a subscribable debate -> show enabled button with standard text.
      return <DebateNotificationButton disabled={false} suspended={false} debate={debate} debateId={debateId} />;
    },

    openSubjectPopover: function (event) {
      this.setState({
        settingsOpen: true,
        settingsAnchor: event.currentTarget,
        settingsInitiator: ReactDOM.findDOMNode(this.debateOptionsButtonRef.current),
      });
    },
  },
  /**
   * Renders the DebateSubjectComponent
   * @return {React.Component}        An instance of DebateSubjectComponent
   */
  render = function ({ debate, video, current }) {
    const { getService, getCursor } = this.context,
      data = getCursor(['data']),
      from = debate.has('startedAt') ? debate.get('startedAt') : debate.get('startsAt'),
      until = debate.has('endedAt') ? debate.get('endedAt') : debate.get('endsAt'),
      title = debate.get('name'),
      isPlaying = video.get('isPlaying', false),
      debateId = debate.get('id'),
      hasStarted = debate.has('startedAt'),
      hasEnded = debate.has('endedAt');

    return (
      <ScrollView>
        <main role="main" className="Main-content Content">
          <article>
            <header className="Content-header Header">
              <div className="DebateSubject-Header">
                <h2 className="Heading Heading--primary">{title}</h2>
                <Button
                  className="IconButton DebateSubject-moreButton"
                  onClick={(event) => this.openSubjectPopover(event)}
                  aria-label="Debat opties"
                  ref={this.debateOptionsButtonRef}
                >
                  <MoreVert />
                </Button>
              </div>
              <div className="Header-metadata Metadata u-bold u-white u-mt0" aria-label={`${timeFormatter(from)} tot ${timeFormatter(until)}`} role="text">
                <Time className="Metadata-time" dateTime={from} /> - <Time className="Metadata-time" dateTime={until} />
              </div>
              <p className="u-row u-parentWidth u-flex u-mt20 u-mb20">
                {!hasEnded && renderPlayLiveButton(getCursor, getService, hasStarted)}
                {hasStarted && renderPlayBeginButton(isPlaying, debate.has('startedAt'), getCursor, getService)}
                {hasStarted && hasEnded && renderContinueButton(debateId, getService)}
              </p>
              {this.renderSubHeader(debate, current, data)}
            </header>
            {renderIntroduction(debate)}
            {this.renderNotificationButton(debate, debateId)}
          </article>
        </main>
        <DebateSubjectPopover
          onClose={() =>
            this.setState({
              settingsOpen: false,
              settingsInitiator: null,
            })
          }
          open={this.state.settingsOpen}
          anchor={this.state.settingsAnchor}
          initiator={this.state.settingsInitiator}
          transformOrigin={{
            horizontal: 'left',
            vertical: 'center',
          }}
          debate={this.props.debate}
        />
      </ScrollView>
    );
  },
  DebateSubjectComponent = component('DebateSubjectComponent', definition, render);

export default observer(
  structure,
  {
    video: ['ui', 'video'],
    current: ['ui', 'sync', 'current'],
    delayType: ['ui', 'sync', 'delayType'],
    pdt: ['ui', 'sync', 'pdt'],
  },
  DebateSubjectComponent,
);
