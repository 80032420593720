import React, { Component } from 'react';
import classNames from 'classnames';
import component from 'omniscient';
import observer from 'omnipotent/decorator/observer';
import PropTypes from 'prop-types';
import formatters from '@debatdirect/core/common/lib/formatters';

import structure from '../../core/lib/structure/structure';

const optionHash = (item) => (item ? item.type + item.resolution : '');

class DownloadOptionsSelect extends Component {
  static contextTypes = {
    getService: PropTypes.func.isRequired,
  };

  static propTypes = {
    /**
     * The location id
     */
    locationId: PropTypes.string.isRequired,

    /**
     * Total seconds of the selected video fragment
     */
    seconds: PropTypes.number.isRequired,

    /**
     * Gets called when the user selects a quality
     *
     * @param {SyntheticEvent} event The react `SyntheticEvent`
     * @param {Object} value The selected value
     */
    onChange: PropTypes.func,
  };

  state = {
    options: [],
    current: null,
  };

  handleQualityChange = (event) => {
    const { value } = event.currentTarget;

    this.setState(
      (prevState) => ({
        current: prevState.options.find((option) => optionHash(option) === value),
      }),
      () => {
        if (this.props.onChange) {
          this.props.onChange(event, this.state.current);
        }
      },
    );
  };

  updateOptions = () => {
    const { locations, locationId } = this.props;

    let location = locations.find((current) => current.get('id') === locationId);

    if (!location) {
      location = locations.first();
    }

    const isLoggedIn = this.context.getService('auth').isLoggedIn();
    const options = location
      .get('downloadOptions')
      .toJS()
      .filter((option) => {
        return !/mxf/i.test(option.type) || isLoggedIn;
      });

    this.setState({
      options,
      current: options[0],
    });
  };

  componentDidMount() {
    // update options on mount
    this.updateOptions();
  }

  componentDidUpdate(prevProps, prevState) {
    // update options when locationId changes
    if (this.props.locationId !== prevProps.locationId) {
      this.updateOptions();
    }

    if (prevState.current !== this.state.current) {
      this.props.onChange({}, this.state.current);
    }
  }

  renderOption = (option) => {
    const { seconds } = this.props;
    const bytes = seconds * option.bandwidth;
    const size = formatters.formatSize(bytes);
    const time = formatters.formatTimeLong(Math.max(0, seconds));
    const key = optionHash(option);

    return (
      <option value={key} key={key} disabled={window.cordova && bytes >= 1e9}>
        {option.label} - {time} - (ongeveer {size})
      </option>
    );
  };

  render() {
    const { options, current } = this.state;
    const { className, seconds, prefix } = this.props;
    const sizeExceeded = window.cordova && (current ? current.bandwidth : 1) * seconds >= 1e9;

    return (
      <React.Fragment>
        <div className={classNames('NativeSelect', className)}>
          <label htmlFor={`${prefix}-video-quality`}>Videokwaliteit</label>
          <select id={`${prefix}-video-quality`} value={optionHash(current)} onChange={this.handleQualityChange}>
            {options.map(this.renderOption)}
          </select>
        </div>
        {sizeExceeded ? (
          <div className="u-textAlt u-pt15 u-pb10 u-redText">
            Het bestand is te groot om te downloaden op uw apparaat. Kies een lagere kwaliteit of download het bestand op een desktop of laptop.
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

const DownloadOptionsSelectComponent = component('DownloadOptionsSelect', (props) => {
  return <DownloadOptionsSelect {...props} />;
});

export default observer(
  structure,
  {
    locations: ['data', 'locations'],
  },
  DownloadOptionsSelectComponent,
);
