import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import component from 'omniscient';
import moment from 'moment';

import PoliticianImage from '../../components/PoliticianImage/PoliticianImage';
import { date } from '../../common/index';
import { byId } from '../../predicates';
import parsers from '../../common/lib/parsers';
import formatters from '../../common/lib/formatters';
import throttle from '../../utils/throttle';

const /**
   * Get coords object from native touch event.
   *
   * @param {Event} event
   * @returns {{x: (number), y: (number)}}
   */
  getCoords = function (event) {
    const originalEvent = event.originalEvent || event;
    const touches = originalEvent.touches?.length ? originalEvent.touches : [originalEvent];
    const e = originalEvent.changedTouches?.[0] || touches[0];

    // mouse event
    if (event.type.indexOf('touch') === -1) {
      return {
        x: event.clientX,
        y: event.clientY,
      };
    }

    // touch event
    return {
      x: e.clientX,
      y: e.clientY,
    };
  };

const componentDefinition = {
  getInitialState: () => ({
    live: false,
    dragging: false,
    played: false,
    railWidth: 0,
    position: 0,
    duration: 0,
    cursorProgress: 0,
    cursorVisible: false,
    thumbProgress: 0,
    railProgress: 0,
    startCoords: null,
    activeEvent: null,
  }),

  componentDidMount: function () {
    const { timeSlider } = this.refs;

    timeSlider.addEventListener('mousedown', this.handleStartInteraction);
    timeSlider.addEventListener('touchstart', this.handleStartInteraction);
    timeSlider.addEventListener('mousemove', this.handleMoveInteraction);
    timeSlider.addEventListener('mouseleave', this.handleEndInteraction);
    window.addEventListener('resize', this.handleResizeDelayed);

    this.handleResize();
  },

  componentWillMount: function () {
    const { getService } = this.context,
      videoService = getService('video');

    videoService.on('timeupdate', this.handleTimeUpdate);
    videoService.on('durationchange', this.handleDurationChange);

    this.findSpeakerThrottled = throttle(this.findSpeakerForProgress, 300);
  },

  componentWillUnmount: function () {
    const { timeSlider } = this.refs,
      { getService } = this.context,
      videoService = getService('video');

    videoService.off('timeupdate', this.handleTimeUpdate);
    videoService.off('durationchange', this.handleDurationChange);

    timeSlider.removeEventListener('mousedown', this.handleStartInteraction);
    timeSlider.removeEventListener('touchstart', this.handleStartInteraction);
    timeSlider.removeEventListener('mousemove', this.handleMoveInteraction);
    timeSlider.removeEventListener('mouseleave', this.handleEndInteraction);
    window.removeEventListener('resize', this.handleResizeDelayed);
  },

  componentDidUpdate: function () {
    const { railWidth, cursorProgress } = this.state;
    const { marker } = this.refs;

    if (!marker) {
      return;
    }

    const markerWidth = marker.offsetWidth;
    const min = 0;
    const max = railWidth - markerWidth;
    const offsetLeft = Math.max(min, Math.min(max, railWidth * cursorProgress - markerWidth / 2));

    marker.style.right = 'auto';
    marker.style.left = `${offsetLeft}px`;
  },

  handleResizeDelayed: function () {
    clearTimeout(this.resizeTimeout);

    this.resizeTimeout = setTimeout(this.handleResize, 500);
  },

  handleResize: function () {
    const { rail } = this.refs;

    if (!rail) {
      return;
    }

    const offsetWidth = rail.offsetWidth;

    if (this.state.railWidth !== offsetWidth) {
      this.setState({ railWidth: offsetWidth });
    }
  },

  handleStartInteraction: function (event) {
    const { timeSlider } = this.refs;

    if (!timeSlider) return;

    const coords = getCoords(event),
      leftOffset = Math.min(this.state.railWidth, Math.max(0, coords.x - timeSlider.offsetLeft)),
      progress = leftOffset / timeSlider.offsetWidth;

    event.preventDefault();

    this.setState({
      cursorVisible: false,
      dragging: true,
      thumbProgress: progress,
      startCoords: coords,
    });

    if (typeof this.props.onDragStart === 'function') {
      this.props.onDragStart(event, progress);
    }

    if (event.type === 'touchstart') {
      timeSlider.addEventListener('touchmove', this.handleMoveInteraction);
      timeSlider.addEventListener('touchend', this.handleEndInteraction);
    } else {
      timeSlider.addEventListener('mouseup', this.handleEndInteraction);
    }
  },

  handleMoveInteraction: function (event) {
    const { timeSlider } = this.refs;

    if (!timeSlider) return;

    const { railWidth } = this.state,
      coords = getCoords(event),
      leftOffset = Math.min(railWidth, Math.max(0, coords.x - timeSlider.offsetLeft)),
      progress = leftOffset / railWidth;

    if (this.state.dragging) {
      this.setState({
        cursorProgress: progress,
        thumbProgress: progress,
      });

      if (typeof this.props.onDragMove === 'function') {
        this.props.onDragMove(event, progress);
      }
    } else {
      this.setState({
        cursorProgress: progress,
        cursorVisible: true,
      });
    }

    this.findSpeakerThrottled(progress);
  },

  handleEndInteraction: function (event) {
    const { timeSlider } = this.refs;

    if (!timeSlider) return;

    const { dragging, duration } = this.state,
      { getService } = this.context,
      coords = getCoords(event),
      timeSliderWidth = timeSlider.offsetWidth,
      videoService = getService('video'),
      leftOffset = Math.min(timeSliderWidth, Math.max(0, coords.x - timeSlider.offsetLeft)),
      progress = leftOffset / timeSliderWidth;

    if (dragging) {
      let toPosition = progress * duration;

      if (this.state.duration < 0) {
        toPosition = (1 - progress) * duration;
      }

      if (event.type.indexOf('touch') === 0) {
        timeSlider.removeEventListener('touchmove', this.handleMoveInteraction);
        timeSlider.removeEventListener('touchend', this.handleEndInteraction);
      } else {
        timeSlider.removeEventListener('mouseup', this.handleEndInteraction);
      }

      videoService.seek(toPosition);
      videoService.play();

      if (typeof this.props.onDragEnd === 'function') {
        this.props.onDragEnd(event, progress);
      }
    }

    this.findSpeakerThrottled.cancel();

    this.setState({
      cursorVisible: false,
      dragging: false,
      activeEvent: null,
    });
  },

  findSpeakerForProgress: function (progress) {
    const { getService } = this.context;
    const { debate } = this.props;
    const videoSyncService = getService('video-sync');
    const videoStartDate = videoSyncService.getVideoStartDate();

    if (!videoStartDate || !debate) {
      return;
    }

    const progressDate = moment(videoStartDate)
      .add(Math.round(progress * this.state.duration), 's')
      .format('x');
    const events = debate
      .get('events')
      .toJS()
      .filter((event) => ['chairman', 'speaker', 'interrupter', 'suspended'].indexOf(event.eventType) !== -1);

    const activeEvent = events.find((event) => date.fromISO(event.eventStart).format('x') < progressDate);

    this.setState({
      activeEvent,
    });
  },

  shouldComponentUpdate: function () {
    return true;
  },

  handleTimeUpdate: function (event) {
    const { getService } = this.context;
    const videoService = getService('video');
    const player = videoService.getPlayer();
    const live = player.duration === Infinity;
    const played = !!player.played.length;

    const position = event.currentTime === Infinity ? this.state.duration : Math.floor(event.currentTime);
    const duration = Math.floor(live ? (player.seekable.length ? player.seekable.end(0) : 0) : player.duration);

    // we are dragging, don't update progress
    if (this.state.dragging) {
      return this.setState({
        duration,
        position,
        live,
        played,
      });
    }

    let progress = position / duration;

    // live, prevent the thumb from moving
    if (live && duration - position <= 20) {
      progress = 1;
    }

    return this.setState({
      thumbProgress: progress,
      railProgress: progress,
      duration,
      position,
      live,
      played,
    });
  },

  handleDurationChange: function (event) {
    const { getService } = this.context;
    const videoService = getService('video');
    const player = videoService.getPlayer();
    const live = event.duration === Infinity;

    const duration = Math.floor(live ? (player.seekable.length ? player.seekable.end(0) : 0) : event.duration);
    const position = player.currentTime === Infinity ? duration : Math.floor(player.currentTime);

    this.setState({
      position,
      duration,
      live,
    });
  },

  getThumbStyles() {
    const { thumbProgress, railWidth } = this.state;
    const left = thumbProgress * railWidth;

    return {
      left: `${left}px`,
    };
  },

  getRailStyles() {
    const { railProgress, railWidth } = this.state;
    const width = railProgress * railWidth;

    return {
      width: `${width}px`,
    };
  },

  getCursorStyles() {
    const { cursorProgress, railWidth } = this.state;
    const left = cursorProgress * railWidth;

    return {
      display: this.state.cursorVisible ? '' : 'none',
      left: `${left}px`,
    };
  },

  renderMarker() {
    if (!this.state.activeEvent?.objectId) {
      const layerVideo = document.querySelector('.Layer--video');

      if (layerVideo) {
        layerVideo.classList.remove('timeSlider-marker');
      }

      return null;
    }

    const { getCursor } = this.context;
    const { cursorProgress, duration, live } = this.state;

    const politician = getCursor(['data', 'actors', 'politicians']).find(byId(this.state.activeEvent.objectId));

    if (!politician) {
      return null;
    }

    let positionLabel = '';

    if (live) {
      positionLabel = `-${formatters.formatDuration(duration * (1 - cursorProgress))}`;
    } else {
      positionLabel = formatters.formatDuration(duration * cursorProgress);
    }

    document.querySelector('.Layer--video').classList.add('timeSlider-marker');

    const name = politician.get('firstName') + ' ' + politician.get('lastName');
    const title = parsers.parsePoliticianTitle(politician, getCursor(['data', 'actors', 'parties']));

    return (
      <div className="TimeSlider-marker u-flex" ref="marker">
        <PoliticianImage className="Politician-photo" politicianId={politician.get('id')} size={100} />
        <div className="u-flex u-col u-centerSelf u-grow">
          <span className="Politician-name">{name}</span>
          <span className="Politician-title">{title}</span>
          <hr />
          <span>{positionLabel}</span>
        </div>
      </div>
    );
  },

  handleTimeSliderKeyDownEvent: function (event) {
    const { getService } = this.context,
      videoService = getService('video');

    switch (event.key) {
      case 'ArrowLeft':
        videoService.seek(Math.max(0, videoService.getPosition() - 1));
        break;
      case 'ArrowRight':
        videoService.seek(Math.min(this.state.duration, videoService.getPosition() + 1));
        break;
      case 'PageDown':
        videoService.seek(Math.max(0, videoService.getPosition() - 5));
        break;
      case 'PageUp':
        videoService.seek(Math.min(this.state.duration, videoService.getPosition() + 5));
        break;
      case 'Home':
        videoService.seek(0);
        break;
      case 'End':
        videoService.seek(this.state.duration);
        break;
      default:
      // no-op
    }
  },

  formatPosition: (position) => {
    let seconds = position;

    const hours = Math.floor(seconds / 3600);
    seconds -= hours * 3600;

    const minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;

    const minutesSuffix = minutes === 1 ? 'minuut' : 'minuten';
    const secondsSuffix = seconds === 1 ? 'seconde' : 'seconden';

    const hoursLabel = hours > 0 ? `${hours} uur` : '';
    const minutesLabel = minutes > 0 ? `${minutes} ${minutesSuffix}` : '';
    const secondsLabel = seconds > 0 ? `${seconds} ${secondsSuffix}` : '';

    if (!hoursLabel && !minutesLabel) return `${seconds} ${secondsSuffix}`;

    return [hoursLabel, minutesLabel, secondsLabel].filter(Boolean).join(', ');
  },

  contextTypes: {
    getCursor: PropTypes.func.isRequired,
    getService: PropTypes.func.isRequired,
  },
};

export default component('TimeSlider', componentDefinition, function ({ className, style }) {
  const rootClassName = classNames('TimeSlider', className, {
    'TimeSlider--disabled': !this.state.played,
  });
  const { position, duration } = this.state;

  return (
    <div
      className={rootClassName}
      style={style}
      onKeyDown={this.handleTimeSliderKeyDownEvent}
      ref="timeSlider"
      role="slider"
      tabIndex={0}
      aria-disabled={!this.state.played}
      aria-label="video tijdlijn"
      aria-valuemin={0}
      aria-valuemax={duration}
      aria-valuenow={position}
      aria-valuetext={this.formatPosition(position)}
    >
      {this.renderMarker()}
      <div className="TimeSlider-rail" ref="rail">
        <div className="TimeSlider-progress" ref="progress" style={this.getRailStyles()} />
        <div className="TimeSlider-cursor" ref="cursor" style={this.getCursorStyles()} />
        <div className="TimeSlider-thumb" ref="thumb" style={this.getThumbStyles()} />
      </div>
    </div>
  );
});
