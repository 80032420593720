import moment from 'moment';

// eslint-disable-next-line import/no-unresolved
import LiveClientWorker from '../../live-client/worker.js?worker';
import { byId } from '../../predicates';
import { date } from '../../common';

import resolve from './util/resolve';

export const DELAY_TYPE = {
  VIDEO_VOD: 'vod',
  VIDEO_LIVE: 'live',
  VIDEO_DVR: 'dvr',
  REAL_TIME: 'realtime',
};

const ignoreEventTypes = {
    speaker_nonverbal: true,
  },
  ignoreEvent = ({ eventType }) => eventType === 'debate_part_start' || eventType === 'debate_part_end',
  getUpcomingEvents = function (events, time) {
    if (!events) {
      return [];
    }

    return events.filter((event) => date.fromISO(event.eventStart).format('x') > time && !ignoreEvent(event));
  },
  getPastEvents = function (events, time) {
    if (!events) {
      return [];
    }

    return events.filter((event) => date.fromISO(event.eventStart).format('x') <= time && !ignoreEvent(event));
  },
  byLocationId = (locationId) => (cursor) => cursor.get('locationId') === locationId,
  generateEventSignature = (event) => event.eventType + event.eventStart,
  determineSpeakerStats = (pastEvents) => {
    let length = pastEvents.length,
      index = 0,
      breakFor = false,
      suspended,
      ended,
      datum,
      chairman,
      speaker,
      interrupter,
      speaking;

    for (; index < length; index++) {
      datum = pastEvents[index];

      switch (datum.eventType) {
        case 'speaker':
        case 'speaker_motion_present':
        case 'speaker_motion_change':
          if (!speaker) {
            speaker = datum.objectId;
          }

          if (!speaking) {
            speaking = 'speaker';
          }
          break;
        case 'chairman_selection':
        case 'chairman':
          if (!chairman) {
            chairman = datum.objectId;
          }
          if (!speaking) {
            speaking = 'chairman';
          }
          break;
        case 'interrupter':
          if (!speaker) {
            if (!interrupter) {
              interrupter = datum.objectId;
            }
            if (!speaking) {
              speaking = 'interrupter';
            }
          }
          break;
        case 'debate_start':
          breakFor = true;
          break;
        default:
      }

      if (breakFor) {
        break;
      }
    }

    suspended = pastEvents[0] && pastEvents[0].eventType === 'suspended';
    ended = pastEvents[0] && pastEvents[0].eventType === 'debate_end';

    if (ended || suspended) {
      speaker = null;
      interrupter = null;
      speaking = null;
    }

    return {
      chairman,
      speaker,
      interrupter,
      speaking,
      ended,
      suspended,
    };
  },
  /**
   * Find object id for seek event.
   * @param {Cursor} debateCursor
   * @param {Object} seekEvent
   * @returns {String|null}
   */
  findSeekEventObjectId = (debateCursor, seekEvent) => {
    try {
      const debate = debateCursor.toJS();

      if (!debate?.events) {
        return null;
      }

      for (let event of debate.events) {
        if (event.eventType + event.eventStart === seekEvent.event) {
          return event.objectId || null;
        }
      }
    } catch (e) {
      // no-op
    }

    return null;
  },
  /**
   * The LiveDataService factory method
   * @param  {Reference} reference Immstruct reference to root cursor
   * @param  {Object}    config    Live API config
   * @return {Object}              The LiveDataService instance
   */
  factory = function (reference, config) {
    let LiveDataService,
      unobserve,
      worker,
      debateId,
      getService = this.getService;

    const findDebateById = (id) => {
      const debates = reference.cursor(['data', 'debates']);

      return debates.find(byId(id));
    };

    LiveDataService = {
      /**
       * A handler for data received from the worker
       * @param event
       */
      workerMessageHandler: (event) => {
        const { eventType } = event.data;
        const refreshService = getService('refresh');

        // The socket connection gets all event types (also the ones we don't want).
        // We skip unwanted events.
        if (eventType && ignoreEventTypes[eventType]) {
          return;
        }

        try {
          const result = resolve(reference, debateId, event.data, refreshService.refresh);

          if (result && 'index' !== debateId) {
            LiveDataService.updateCurrentTime();
          }
        } catch (error) {
          if (import.meta.env.DEV) {
            console.error(error);
          }
        }
      },

      getDelayType: () => {
        const isPlaying = reference.cursor(['ui', 'video']).get('isPlaying', false),
          videoService = getService('video'),
          duration = videoService.getDuration();

        if (isPlaying && duration > 0) {
          return DELAY_TYPE.VIDEO_VOD;
        }

        if (isPlaying && duration < 0) {
          return DELAY_TYPE.VIDEO_DVR;
        }

        return DELAY_TYPE.REAL_TIME;
      },

      overrideDebateEndPrompt: (endedDebate) => {
        const event = {
          endedDebate,
          handled: false,
        };

        if (typeof LiveDataService.onDebateEnd === 'function') {
          LiveDataService.onDebateEnd(event);
        }

        return event.handled;
      },

      showDebateEndPrompt: (endedDebate) => {
        // Check if a client overrides this behavior.
        if (LiveDataService.overrideDebateEndPrompt(endedDebate)) {
          return;
        }

        const seekEvent = reference.cursor(['ui', 'seekEvent']).deref(),
          isEmbedded = reference.cursor(['ui', 'isEmbedded']).deref();

        if (seekEvent || isEmbedded) {
          return;
        }

        const router = getService('router'),
          debateStartsAt = endedDebate.get('startsAt'),
          locationId = endedDebate.get('locationId'),
          location = reference.cursor(['data', 'locations']).find(byId(locationId)),
          categories = reference.cursor(['data', 'categories']),
          upcomingDebates = reference
            .cursor(['data', 'debates'])
            .filter(byLocationId(locationId))
            .filter((debate) => !debate.equals(endedDebate))
            .filter((debate) => debate.get('startsAt') > debateStartsAt)
            .sortBy((debate) => debate.get('startsAt')),
          nextDebate = upcomingDebates.first();

        if (!nextDebate) {
          return;
        }

        const categoryId = nextDebate.get('categoryIds').first(),
          category = categories.find(byId(categoryId)),
          path = router.generate('debate-subject', {
            date: getService('date').getAgendaDate(),
            debate: nextDebate.get('slug'),
            location: location.get('slug'),
            category: category.get('slug'),
          });

        getService('prompt').prompt({
          identifier: 'NextDebate',
          dismissable: true,
          onAccept: () => {
            router.navigate(path);
          },
          priority: 30,
        });
      },

      post: (type, values) => {
        if (!worker) {
          return;
        }

        worker.postMessage({ type, ...values });
      },

      updateCurrentTime: () => {
        if (!debateId) {
          return;
        }

        const debate = findDebateById(debateId),
          debateType = debate.get('debateType'),
          syncCursor = reference.cursor(['ui', 'sync']),
          currentCursor = syncCursor.get('current'),
          pdt = syncCursor.get('pdt'),
          momentTime = moment(pdt || undefined),
          time = momentTime.format('x'),
          debateEvents = debate.get('events'),
          debateEventsRaw = debateEvents ? debateEvents.toJS() : [],
          currentActiveEvent = currentCursor.get('activeEvent');

        const pastEvents = getPastEvents(debateEventsRaw, time),
          upcomingEvents = getUpcomingEvents(debateEventsRaw, time),
          speakerStats = determineSpeakerStats(pastEvents);

        let { chairman, speaking, ended, suspended } = speakerStats;

        let seekEvent = reference.cursor(['ui', 'seekEvent']).deref();
        let activeEvent = pastEvents[0] && generateEventSignature(pastEvents[0]);

        // if activeEvent is not set and we have upcoming events it means the PDT is currently before the first event
        // set the activeEvent to the first upcoming event
        if (!activeEvent && upcomingEvents.length) {
          const upcomingEvent = upcomingEvents[upcomingEvents.length - 1];

          activeEvent = upcomingEvent && generateEventSignature(upcomingEvent);
        }

        let updates;

        // default chairman to current.chairmanId from API
        if (!chairman) {
          chairman = debate.getIn(['current', 'chairmanId']);
        }

        if (seekEvent && Date.now() > seekEvent.disableTime) {
          seekEvent = null;
          reference.cursor(['ui', 'seekEvent']).set(null);
        }

        if ('Stemmingen' === debateType) {
          updates = {
            chairman,
            ended,
            suspended,
            activeEvent,
          };
        } else {
          if (seekEvent) {
            let marker = findSeekEventObjectId(debate, seekEvent);

            reference.cursor(['ui', 'marker']).update(() => marker);
          } else {
            let marker = speaking ? speakerStats[speaking] || null : null;

            reference.cursor(['ui', 'marker']).update(() => marker);
          }

          updates = {
            chairman,
            speaking,
            ended,
            suspended,
            activeEvent,
          };
        }

        if (pastEvents[0] && pastEvents[0].eventType === 'debate_end' && currentActiveEvent && currentActiveEvent !== activeEvent) {
          LiveDataService.showDebateEndPrompt(debate);
        }

        // update syncCursor
        reference.cursor(['ui', 'sync', 'current']).merge(updates);

        // update delayType
        reference.cursor(['ui', 'sync']).set('delayType', LiveDataService.getDelayType());
      },

      /**
       * Subscribe to events with the given debate id
       * @param _debateId
       */
      subscribeToDebate: (_debateId) => {
        const debate = findDebateById(_debateId),
          dateService = this.getService('date'),
          isToday = dateService.isToday();

        LiveDataService.unsubscribeFromDebate();

        debateId = _debateId;

        if (!debate.has('endedAt') && isToday) {
          LiveDataService.post('joinDebateRoom', {
            debateId: _debateId,
          });
        }

        LiveDataService.updateCurrentTime();

        unobserve = reference.reference(['ui', 'sync', 'pdt']).observe('swap', () => LiveDataService.updateCurrentTime());
      },

      /**
       * Unsubscribe from events from the current debate.
       */
      unsubscribeFromDebate: () => {
        if (debateId) {
          LiveDataService.post('leaveDebateRoom', {
            debateId,
          });
        }

        debateId = null;

        // Clear debate end listener.
        LiveDataService.onDebateEnd = null;

        if (unobserve) {
          unobserve();
        }

        reference.cursor(['ui', 'sync', 'current']).merge({
          speaking: null,
          chairman: null,
          ended: false,
          suspended: false,
          activeEvent: null,
        });

        reference.cursor(['ui', 'sync']).update('delayType', () => DELAY_TYPE.REAL_TIME);
      },

      /**
       * Starts the worker process
       */
      start: () => {
        const { maxDelay, url } = config,
          baseUrl = reference.cursor(['ui', 'settings']).get('baseUrl'),
          workerUrl = window.location.href.startsWith('http') ? config.worker : baseUrl + config.worker;

        // stop any existing services.
        LiveDataService.stop();

        try {
          worker = new LiveClientWorker(workerUrl);
          worker.addEventListener('message', LiveDataService.workerMessageHandler);

          LiveDataService.post('init', {
            maxDelay,
            url,
          });
        } catch (e) {
          if (window.console) {
            window.console.error('Error on init Worker: ', e);
          }

          worker = null;
        }
      },

      /**
       * Terminates the worker process
       */
      stop: () => {
        if (!worker) {
          return;
        }

        worker.terminate();
        worker = null;
      },

      clear: () => {
        reference.cursor(['ui', 'sync']).update('pdt', () => null);
      },

      getActiveEvent: () => {
        const activeEvent = reference.cursor(['ui', 'sync', 'current', 'activeEvent']).deref(),
          seekEvent = reference.cursor(['ui', 'seekEvent']).deref();

        if (seekEvent) {
          return seekEvent.event || '';
        }

        return activeEvent || '';
      },
    };

    return LiveDataService;
  };

export default factory;
