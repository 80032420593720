import React, { Component } from 'react';

import formatters from '../../common/lib/formatters';
import Button from '../Button/Button';
import Popover from '../Popover/Popover';
import { ExpandLess, ExpandMore } from '../../icons';

const parseInput = (input, field) => {
  const value = Math.max(0, parseInt(input));

  if (field === 'hours') {
    return Math.min(23, value);
  }

  return Math.min(59, value);
};

export default class TimePicker extends Component {
  state = {
    hours: 0,
    minutes: 0,
    seconds: 0,

    hoursFormatted: '00',
    minutesFormatted: '00',
    secondsFormatted: '00',
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.hours !== nextProps.hours || this.props.minutes !== nextProps.minutes || this.props.seconds !== nextProps.seconds) {
      this.setState({
        hours: nextProps.hours,
        minutes: nextProps.minutes,
        seconds: nextProps.seconds,

        hoursFormatted: formatters.formatTime(nextProps.hours),
        minutesFormatted: formatters.formatTime(nextProps.minutes),
        secondsFormatted: formatters.formatTime(nextProps.seconds),
      });
    }
  }

  less = (event) => {
    const field = event.currentTarget.getAttribute('data-field');
    let toValue = this.state[field] - 1;

    if (toValue < 0) {
      toValue = field === 'hours' ? 23 : 59;
    }

    this.setState({
      [field]: toValue,
      [`${field}Formatted`]: formatters.formatTime(toValue),
    });
  };

  more = (event) => {
    const field = event.currentTarget.getAttribute('data-field');
    let toValue = this.state[field] + 1;

    if ((field === 'hours' && toValue >= 24) || toValue >= 60) {
      toValue = 0;
    }

    this.setState({
      [field]: toValue,
      [`${field}Formatted`]: formatters.formatTime(toValue),
    });
  };

  handleBlur = (event) => {
    const field = event.currentTarget.getAttribute('data-field');
    let toValue = parseInput(event.currentTarget.value || 0, field);

    this.setState({
      [field]: toValue,
      [`${field}Formatted`]: formatters.formatTime(toValue),
    });
  };

  handleFocus = (event) => {
    event.currentTarget.select();
  };

  handleChange = (event) => {
    const field = event.currentTarget.getAttribute('data-field');
    let toValue = parseInput(event.currentTarget.value || 0, field);

    this.setState({
      [field]: toValue,
      [`${field}Formatted`]: event.currentTarget.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    this.props.onConfirm({ ...this.state });
  };

  render() {
    return (
      <Popover
        open={this.props.open}
        anchor={this.props.anchor}
        onClose={this.props.onClose}
        anchorOffset={{
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
      >
        <div className="TimePicker">
          <form onSubmit={this.handleSubmit}>
            <div className="TimePicker-title">Kies een tijd:</div>
            <div className="TimePicker-select">
              <div className="TimePicker-col">
                <Button
                  className="IconButton TimePicker-button"
                  onClick={this.more}
                  aria-label="Plus 1 uur"
                  aria-hidden="true"
                  data-field="hours"
                  tabIndex="-1"
                >
                  <ExpandLess />
                </Button>
                <input
                  type="number"
                  className="TimePicker-input"
                  onBlur={this.handleBlur}
                  onChange={this.handleChange}
                  onFocus={this.handleFocus}
                  value={this.state.hoursFormatted}
                  role="spinbutton"
                  aria-label="Uren"
                  data-field="hours"
                  aria-valuenow={this.state.hours}
                  min={0}
                  max={23}
                  readOnly={!this.props.keyboardEnabled}
                />
                <Button className="IconButton TimePicker-button" onClick={this.less} aria-label="Min 1 uur" data-field="hours" tabIndex="-1">
                  <ExpandMore />
                </Button>
              </div>
              <div className="TimePicker-col">:</div>
              <div className="TimePicker-col">
                <Button
                  className="IconButton TimePicker-button"
                  onClick={this.more}
                  aria-label="Plus 1 minuut"
                  aria-hidden="true"
                  data-field="minutes"
                  tabIndex="-1"
                >
                  <ExpandLess />
                </Button>
                <input
                  type="number"
                  className="TimePicker-input"
                  onBlur={this.handleBlur}
                  onChange={this.handleChange}
                  onFocus={this.handleFocus}
                  value={this.state.minutesFormatted}
                  aria-label="Minuten"
                  data-field="minutes"
                  min={0}
                  max={59}
                  readOnly={!this.props.keyboardEnabled}
                />
                <Button
                  className="IconButton TimePicker-button"
                  onClick={this.less}
                  data-field="minutes"
                  aria-label="Min 1 minuut"
                  aria-hidden="true"
                  tabIndex="-1"
                >
                  <ExpandMore />
                </Button>
              </div>
              <div className="TimePicker-col">:</div>
              <div className="TimePicker-col">
                <Button
                  className="IconButton TimePicker-button"
                  onClick={this.more}
                  data-field="seconds"
                  aria-label="Plus 1 seconde"
                  aria-hidden="true"
                  tabIndex="-1"
                >
                  <ExpandLess />
                </Button>
                <input
                  type="number"
                  className="TimePicker-input"
                  onBlur={this.handleBlur}
                  onChange={this.handleChange}
                  onFocus={this.handleFocus}
                  value={this.state.secondsFormatted}
                  aria-label="Seconden"
                  data-field="seconds"
                  min={0}
                  max={59}
                  readOnly={!this.props.keyboardEnabled}
                />
                <Button
                  className="IconButton TimePicker-button"
                  onClick={this.less}
                  data-field="seconds"
                  aria-label="Min 1 seconde"
                  aria-hidden="true"
                  tabIndex="-1"
                >
                  <ExpandMore />
                </Button>
              </div>
            </div>
            <div className="TimePicker-actions">
              <Button className="Button--light Button--medium" onClick={this.props.onClose} aria-label="Annuleer">
                <span className="Button-label">Annuleer</span>
              </Button>
              <Button type="submit" className="Button--primary Button--medium" aria-label="Selecteer tijd">
                <span className="Button-label">Selecteer tijd</span>
              </Button>
            </div>
          </form>
        </div>
      </Popover>
    );
  }
}
