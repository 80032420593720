import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Close, Code } from '../../icons';
import DownloadAgreement from '../DownloadAgreement/DownloadAgreement';
import LocationsSelectComponent from '../LocationsSelect/LocationsSelect';
import Button from '../Button/Button';
import MenuList from '../MenuList/MenuList';
import MenuItem from '../MenuList/MenuItem';
import Modal from '../Modal/Modal';
import Radio from '../Radio/Radio';
import { trackEvent } from '../../common';
import { copyToClipboard } from '../../utils/copyToClipboard';
import MenuListHeader from '../MenuList/MenuListHeader';

class EmbedDebateModal extends Component {
  static contextTypes = {
    getCursor: PropTypes.func.isRequired,
    getService: PropTypes.func.isRequired,
  };

  state = {
    locationId: 'plenaire-zaal',

    embedType: 'debate',
    embedCode: '',
    copiedEmbedCode: false,
    copiedEmbedCodeFailure: false,

    agreementAccepted: false,
  };

  componentWillMount() {
    this.setState({
      embedCode: this.generateEmbedCode(),
    });
  }

  componentWillUpdate(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.setState({
        embedCode: this.generateEmbedCode(),
        embedType: 'debate',
      });
    }
  }

  close = () => {
    this.props.onClose();
  };

  generateEmbedCode() {
    if (this.state.embedType === 'location') {
      return this.context.getService('share').createLocationEmbedCode(this.state.locationId);
    }

    return this.context.getService('share').createEmbedCode(this.props.debate);
  }

  handleDebateTypeChange = (event) => {
    this.setState(
      {
        embedType: event.currentTarget.value,
        embedCode: this.generateEmbedCode(),
      },
      () => this.setState({ embedCode: this.generateEmbedCode() }),
    );
  };

  handleLocationChange = (event, value) => {
    this.setState(
      {
        locationId: value,
      },
      () => this.setState({ embedCode: this.generateEmbedCode() }),
    );
  };

  handleCopyClick = () => {
    const copied = copyToClipboard(this.state.embedCode);

    this.setState({
      copiedEmbedCode: copied,
      copiedEmbedCodeFailure: !copied,
    });

    this.trackEmbedAction();

    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(
      () =>
        this.setState({
          copiedEmbedCode: false,
          copiedEmbedCodeFailure: false,
        }),
      2000,
    );
  };

  trackEmbedAction = () => {
    const name = this.props.debate.get('name');
    const date = moment(this.props.debate.get('startsAt')).format('YYYY-MM-DD');
    const title = this.state.embedType === 'debate' ? `${date} / ${name}` : this.state.locationId;

    trackEvent(this.state.embedType, 'copyEmbed', title, 1);
  };

  render() {
    const { embedCode, copiedEmbedCode, copiedEmbedCodeFailure } = this.state;
    const buttonLabel = copiedEmbedCode ? 'EMBED CODE GEKOPIEERD' : copiedEmbedCodeFailure ? 'KOPIEREN MISLUKT' : 'EMBED CODE KOPIËREN';

    return (
      <Modal
        type="auto"
        className="DownloadModal"
        open={this.props.open}
        onClose={this.close}
        aria-labelledby="modal-title"
        aria-describedby="modal-descr"
        initiator={this.props.initiator}
      >
        <MenuListHeader onClick={() => this.close()} label="Embedden" titleId="modal-title" />
        <div className="Modal-body">
          <div className="Modal-body-title">Stream embedden</div>
          <div id="modal-descr">
            <p>Plaats de volgende code op uw website om Debat Direct te embedden.</p>
            <p>Wat wilt u embedden</p>
          </div>
          <div className="u-mb15" role="radiogroup" aria-label="Embed type">
            <label className="u-inlineFlex u-alignItemsCenter u-pointer">
              <Radio name="type" value="debate" onChange={this.handleDebateTypeChange} checked={this.state.embedType === 'debate'} />
              Debat
            </label>
            <label className="u-inlineFlex u-alignItemsCenter u-pointer">
              <Radio name="type" value="location" onChange={this.handleDebateTypeChange} checked={this.state.embedType === 'location'} />
              Zaal
            </label>
          </div>
          {this.state.embedType === 'location' && (
            <LocationsSelectComponent className="u-mb15" onChange={this.handleLocationChange} defaultValue={this.props.debate.get('locationId')} />
          )}
          <textarea className="TextArea u-mb15" value={embedCode} rows={5} readOnly />
          <DownloadAgreement
            id="embed-agreement"
            onChange={(event) => this.setState({ agreementAccepted: event.currentTarget.checked })}
            checked={this.state.agreementAccepted}
          />
          <Button className="Button--primary Button--block Button--medium" onClick={this.handleCopyClick} disabled={!this.state.agreementAccepted}>
            <Code className="Button-icon" />
            <span className="Button-label">{buttonLabel}</span>
          </Button>
        </div>
        <MenuList>
          <MenuItem className="MenuItem--footer" icon={<Close />} label="Sluiten" onClick={() => this.close()} />
        </MenuList>
      </Modal>
    );
  }
}

export default EmbedDebateModal;
