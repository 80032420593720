import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import component from 'omniscient';
import classNames from 'classnames';

import InternalLink from '../../components/InternalLink/InternalLink';

const TAB_COUNT = 4,
  /**
   * The component definition
   * @type {Object}
   */
  definition = {
    tablistRef: React.createRef(),
    getInitialState: () => ({
      currentIndex: 0,
    }),
    /**
     * Move focus to a tab for accesbility
     * @param {Event} event
     * @param {Number} index
     */
    moveFocusToTab: function (event, index) {
      const tablist = ReactDOM.findDOMNode(this.tablistRef.current);
      event.preventDefault();
      event.stopPropagation();
      tablist.querySelectorAll('[role="tab"]')[index]?.focus();
    },
    /**
     * Composes the props
     * @param  {String}   routeName        Name of the route whose template
     *                                     is used for rendering the href
     * @param  {boolean}  disabled
     * @param  {number}   index
     */
    composeLinkProps: function (routeName, disabled, index) {
      const { pathTo, params, getService } = this.context,
        key = routeName,
        href = disabled ? null : pathTo(routeName, params),
        isMobile = getService('platform').isIOS() || getService('platform').isAndroid(),
        isActiveRoute = this.props.route?.name === routeName,
        activeClassName = 'is-selected',
        tabIndexPreferred = disabled || !isActiveRoute ? -1 : 0,
        tabIndex = index === 0 && this.props.route?.name === 'debate-video' ? 0 : tabIndexPreferred,
        role = 'tab',
        ariaAttributes = {
          'aria-selected': isActiveRoute,
        },
        className = classNames({
          'NavBar-link': true,
          'Link--disabled': disabled,
          'u-flex': true,
        }),
        onFocus = () => {
          this.setState({ currentIndex: index });
        },
        onClick = () => {
          // Programmaticly set focus to tab content on mobile for accessibility reasons
          if (isMobile && !disabled && this.props.controls) {
            document.querySelector('#' + this.props.controls)?.focus();
          }
        },
        onKeyDown = (event) => {
          const max = TAB_COUNT - 1;
          switch (event.key) {
            case 'ArrowLeft':
              this.moveFocusToTab(event, this.state.currentIndex === 0 ? max : this.state.currentIndex - 1);
              break;
            case 'ArrowRight':
              this.moveFocusToTab(event, this.state.currentIndex === max ? 0 : this.state.currentIndex + 1);
              break;
            case 'Home':
              this.moveFocusToTab(event, 0);
              break;
            case 'End':
              this.moveFocusToTab(event, max);
              break;
            default:
              break;
          }
        };

      if (this.props.controls) {
        ariaAttributes['aria-controls'] = this.props.controls;
      }

      if (disabled) {
        ariaAttributes['aria-disabled'] = true;
      }

      return {
        ...ariaAttributes,
        onClick,
        onFocus,
        onKeyDown,
        activeClassName,
        className,
        tabIndex,
        role,
        href,
        key,
      };
    },

    /**
     * Composes a single link
     * @return {Function}                        The actual mapper function
     */
    composeLink: function ({ disabled, routeName, label }, index) {
      const props = this.composeLinkProps(routeName, disabled, index);

      return <InternalLink {...props}>{label}</InternalLink>;
    },

    shouldComponentUpdate: () => true,

    contextTypes: {
      pathTo: PropTypes.func.isRequired,
      params: PropTypes.object.isRequired,
      route: PropTypes.object.isRequired,
      getService: PropTypes.func.isRequired,
    },
  },
  /**
   * The TabBar component
   * @param {Object} props Determines what elements are shown
   * @return {React.Component}          An instance of the TabBar component
   */
  render = function ({ debate }) {
    const documentIds = debate.get('documentIds'),
      politicianIds = debate.get('politicianIds'),
      documentCount = documentIds ? documentIds.count() : 0,
      isUpcomingDebate = !debate.has('startedAt') && !debate.has('endedAt'),
      politiciansCount = politicianIds ? politicianIds.count() : 0,
      currentEmpty = !debate.has('current') || debate.get('current').isEmpty(),
      markersLabel = debate.get('debateType') === 'Stemmingen' ? 'Stemmingen' : 'Sprekers',
      tabs = [
        {
          label: 'Algemeen',
          routeName: 'debate-subject',
        },
        {
          label: markersLabel,
          routeName: 'debate-markers',
          disabled: isUpcomingDebate,
        },
        {
          label: 'In de zaal',
          routeName: 'debate-location',
          disabled: 0 === politiciansCount && currentEmpty,
        },
        {
          label: 'Stukken',
          routeName: 'debate-documents',
          disabled: 0 === documentCount,
        },
      ];
    return (
      <nav className="Main-navBar NavBar">
        <div className="NavBar-wrapper u-flex u-row u-justify" role="tablist" ref={this.tablistRef}>
          {tabs.map(this.composeLink)}
        </div>
      </nav>
    );
  };

export default component('TabBar', definition, render);
