import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';

import Backdrop from '../Backdrop/Backdrop';
import Portal from '../Modal/Portal';
import modalManager from '../Modal/ModalManager';

const MOBILE_QUERY = window.matchMedia('screen and (max-width: 680px)');

export default class Modal extends React.Component {
  static propTypes = {
    open: PropTypes.bool,
    inert: PropTypes.bool,
    onClose: PropTypes.func,
    type: PropTypes.oneOf(['auto', 'default', 'full', 'bottom']),
  };

  static defaultProps = {
    open: false,
    inert: false,
    type: 'default',
  };

  state = {
    mobile: MOBILE_QUERY.matches,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      modalManager.addVisibleModal(this);
    } else if (!nextProps.open && this.props.open) {
      modalManager.removeVisibleModal(this);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.open && !prevProps.open) {
      this.lastFocusedElement = document.activeElement;
      window.requestAnimationFrame(() => {
        this.refs.modal.querySelectorAll('button, a')[0]?.focus({ preventScroll: true });
      });
    } else if (!this.props.open && prevProps.open) {
      // prioritize the initiator and fallback to the last focused element before opening this modal
      if (this.props.initiator) {
        this.props.initiator.focus();
      } else {
        this.lastFocusedElement?.focus();
      }
    }
  }

  componentDidMount() {
    MOBILE_QUERY.addListener(this.handleMediaQueryChange);
  }

  componentWillUnmount() {
    if (this.props.open) {
      modalManager.removeVisibleModal(this);
    }

    MOBILE_QUERY.removeListener(this.handleMediaQueryChange);
  }

  handleMediaQueryChange = () => {
    if (this.props.type === 'auto') {
      this.setState({ mobile: MOBILE_QUERY.matches });
    }
  };

  render() {
    const { type, inert, onClose, open, children, className, ...rest } = this.props;
    const autoType = type === 'auto' ? (this.state.mobile ? 'bottom' : 'default') : type;
    const modalClassName = classNames('Modal', `Modal--${autoType}`, className);

    return (
      <Portal>
        <Backdrop open={open} onClick={onClose}>
          <CSSTransition classNames="Modal--animation" timeout={200} in={open} appear mountOnEnter unmountOnExit>
            <div ref="modal" className={modalClassName} inert={inert ? '' : null} role="dialog" aria-modal="true" {...rest}>
              {children}
            </div>
          </CSSTransition>
        </Backdrop>
      </Portal>
    );
  }
}
