import { service } from '@debatdirect/core/core';
import { api } from '@debatdirect/core/api';
import DownloadService from '@debatdirect/core/services/DownloadService/DownloadService';
import PlatformService from '@debatdirect/core/services/PlatformService/PlatformService';
import LiveDataService from '@debatdirect/core/services/LiveDataService/LiveDataService';
import VideoService from '@debatdirect/core/services/VideoService/VideoService';
import VideoSyncService from '@debatdirect/core/services/VideoSyncService/VideoSyncService';
import ShareService from '@debatdirect/core/services/ShareService/ShareService';
import PromptService from '@debatdirect/core/services/PromptService/PromptService';
import AdditionsServiceFactory from '@debatdirect/core/services/InteractionAdditionsService/AdditionsServiceFactory';
import DebugService from '@debatdirect/core/services/DebugService/DebugService';
import DateService from '@debatdirect/core/services/DateService/DateService';
import InfoPanelService from '@debatdirect/core/services/InfoPanelService/InfoPanelService';
import RefreshService from '@debatdirect/core/services/RefreshService/RefreshService';
import AuthService from '@debatdirect/core/services/AuthService/AuthService';
import ElasticService from '@debatdirect/core/services/ElasticService/ElasticService';
import NotificationsService from '@debatdirect/core/services/NotificationsService/NotificationsService';
import ContinueWatchingService from '@debatdirect/core/services/ContinueWatchingService/ContinueWatchingService';

const { factory } = service,
  { Router } = factory;

export default [
  {
    name: 'api',
    factory: api,
    keyPath: [],
    props: [{ source: 'config', keyPath: ['api'] }],
  },
  {
    name: 'router',
    factory: Router,
    keyPath: [],
    props: [{ source: 'config', keyPath: ['routes'] }],
  },
  {
    name: 'video',
    factory: VideoService,
    keyPath: [],
  },
  {
    name: 'info-panel',
    factory: InfoPanelService,
    keyPath: ['ui', 'infoPanel'],
  },
  {
    name: 'debug',
    factory: DebugService,
    keyPath: [],
  },
  {
    name: 'live',
    factory: LiveDataService,
    keyPath: [],
    props: [{ source: 'config', keyPath: ['liveApi'] }],
  },
  {
    name: 'platform',
    factory: PlatformService,
    keyPath: [],
  },
  {
    name: 'prompt',
    factory: PromptService,
    keyPath: ['ui', 'prompt'],
  },
  {
    name: 'refresh',
    factory: RefreshService,
    keyPath: ['data'],
  },
  {
    name: 'interaction-additions',
    factory: AdditionsServiceFactory,
    keyPath: [],
    props: [{ source: 'config', keyPath: [] }],
  },
  {
    name: 'date',
    factory: DateService,
    keyPath: [],
    props: [{ source: 'config', keyPath: ['date'] }],
  },
  {
    name: 'share',
    factory: ShareService,
    keyPath: [],
    props: [{ source: 'config', keyPath: [] }],
  },
  {
    name: 'video-sync',
    factory: VideoSyncService,
    keyPath: [],
  },
  {
    name: 'auth',
    factory: AuthService,
    keyPath: ['auth'],
    props: [{ source: 'config', keyPath: ['auth'] }],
  },
  {
    name: 'download',
    factory: DownloadService,
    keyPath: ['ui', 'downloads'],
    props: [{ source: 'config', keyPath: ['fragments'] }],
  },
  {
    name: 'search',
    factory: ElasticService,
    keyPath: [],
    props: [{ source: 'config', keyPath: ['search'] }],
  },
  {
    name: 'notifications',
    factory: NotificationsService,
    keyPath: [],
    props: [],
  },
  {
    name: 'continue-watching',
    factory: ContinueWatchingService,
    keyPath: [],
    props: [],
  },
];
