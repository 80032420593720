import React, { Component } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import moment from 'moment';
import { createSelectorCreator, defaultMemoize } from 'reselect';

import PreviewFragmentModal from '../Modals/PreviewFragmentModal';
import TimePicker from '../TimePicker/TimePicker';
import addSourceParameters from '../../utils/addSourceParameters';
import DownloadOptionsSelect from '../../components/DownloadOptionsSelect/DownloadOptionsSelect';
import DownloadAgreement from '../DownloadAgreement/DownloadAgreement';
import formatters from '../../common/lib/formatters';
import Button from '../Button/Button';
import { AddCircle, AlertOutline, PlayArrow, Download, CheckCircle, RemoveCircle } from '../../icons';

const MOBILE_QUERY = window.matchMedia('screen and (max-width: 680px)');

const generateEventId = (event) => event.eventType + event.eventStart;

const toDict = (collection, key = 'id') => {
  return collection?.reduce((acc, cursor) => {
    acc[cursor.get(key)] = cursor;

    return acc;
  }, {});
};

const createSelector = createSelectorCreator(defaultMemoize, Immutable.is);

const politiciansDictSelector = createSelector(
  (state) => state.politicians,
  (politicians) => toDict(politicians),
);

const eventsDict = {
  chairman_change: 'is tot voorzitter benoemd',
  chairman: 'spreekt',
  chairman_selection: 'is tot voorzitter benoemd',
  debate_end: 'Het debat is afgelopen',
  debate_start: 'Het debat wordt geopend',
  interrupter: 'interrumpeert',
  speaker: 'spreekt',
  speaker_motion_change: 'dient een motiewijziging in',
  speaker_motion_present: 'dient een motie in',
  speaker_motion_withdraw: 'trekt een motie terug',
  suspended: 'Het debat is geschorst',
};

const eventFilter = (event) => !!eventsDict[event.eventType];

const renderIncludedMarker = (event, politicians, complete, onClick, canRemove) => {
  let name, eventName;
  const politician = politicians[event.objectId];
  const politicianName = politician && `${politician.get('firstName')} ${politician.get('lastName')}`;
  const noSpeakMoment = ['debate_end', 'debate_start', 'suspended'].indexOf(event.eventType) > -1;

  // Allow for display of unknown speaker
  if (noSpeakMoment) {
    name = politician ? politicianName : eventsDict[event.eventType];
    eventName = politician ? eventsDict[event.eventType] : '';
  } else {
    name = politician ? politicianName : 'Onbekende spreker';
    eventName = eventsDict[event.eventType];
  }

  if (!canRemove) {
    return (
      <div className="FragmentSelect-marker">
        <div className="FragmentSelect-marker-status">{complete ? <CheckCircle /> : <AlertOutline />}</div>
        <div className="FragmentSelect-marker-time">{moment(event.eventStart).format('HH:mm:ss')}</div>
        <div className="FragmentSelect-marker-text">
          <div className="FragmentSelect-marker-speaker">{name}</div>
          <div className="FragmentSelect-marker-event">{eventName}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="FragmentSelect-marker FragmentSelect-marker--included" onClick={onClick}>
      <div className="FragmentSelect-marker-status">{complete ? <CheckCircle /> : <AlertOutline />}</div>
      <div className="FragmentSelect-marker-time">{moment(event.eventStart).format('HH:mm:ss')}</div>
      <div className="FragmentSelect-marker-text">
        <div className="FragmentSelect-marker-speaker">{name}</div>
        <div className="FragmentSelect-marker-event">{eventName}</div>
      </div>
      <div className="FragmentSelect-marker-action">
        <RemoveCircle />
      </div>
    </div>
  );
};

const renderExcludedMarker = (event, politicians, onClick) => {
  let name, eventName;
  const politician = politicians[event.objectId];
  const politicianName = politician && `${politician.get('firstName')} ${politician.get('lastName')}`;
  const noSpeakMoment = ['debate_end', 'debate_start', 'suspended'].indexOf(event.eventType) > -1;
  const canAdd = event.eventType !== 'debate_end';

  // Allow for display of unknown speaker
  if (noSpeakMoment) {
    name = politician ? politicianName : eventsDict[event.eventType];
    eventName = politician ? eventsDict[event.eventType] : '';
  } else {
    name = politician ? politicianName : 'Onbekende spreker';
    eventName = eventsDict[event.eventType];
  }

  if (!canAdd) {
    return (
      <div className="FragmentSelect-marker">
        <div className="FragmentSelect-marker-time">{moment(event.eventStart).format('HH:mm:ss')}</div>
        <div className="FragmentSelect-marker-text">
          <div className="FragmentSelect-marker-speaker">{name}</div>
          <div className="FragmentSelect-marker-event">{eventName}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="FragmentSelect-marker FragmentSelect-marker--excluded" onClick={onClick}>
      <div className="FragmentSelect-marker-time">{moment(event.eventStart).format('HH:mm:ss')}</div>
      <div className="FragmentSelect-marker-text">
        <div className="FragmentSelect-marker-speaker">{name}</div>
        <div className="FragmentSelect-marker-event">{eventName}</div>
      </div>
      <div className="FragmentSelect-marker-action">
        <AddCircle />
      </div>
    </div>
  );
};

export default class FragmentSelect extends Component {
  static contextTypes = {
    getService: PropTypes.func.isRequired,
  };

  state = {
    maxDate: null,
    markers: [],
    previousMarker: null,
    nextMarker: null,
    selectedMarkers: [],
    firstMarkerComplete: true,
    lastMarkerComplete: true,
    fromDateTime: null,
    toDateTime: null,
    fromSeconds: 0,
    toSeconds: 0,
    previewFragmentSrc: null,
    quality: null,
    agreementAccepted: false,
  };

  constructor(props, context) {
    super(props);

    this.state.isTouchDevice = context.getService('platform').isTouchDevice();

    if (props.fromDate && props.toDate) {
      const fromDateTimeMoment = moment(props.fromDate);
      const toDateTimeMoment = moment(props.toDate);
      const markers = props.debate.get('events').toJS().filter(eventFilter);

      this.state.fromDateTime = props.fromDate;
      this.state.toDateTime = props.toDate;

      const orderedMarkers = markers
        .slice()
        .reverse()
        .map((cur, index, all) => {
          cur.nextEventStart = all[index + 1] && all[index + 1].eventStart;

          return cur;
        });

      const firstMarker = orderedMarkers[0];
      const lastMarker = orderedMarkers[orderedMarkers.length - 1];

      // all between start and end
      this.state.selectedMarkers = orderedMarkers.filter(({ eventStart, nextEventStart }) => {
        return moment(eventStart).isBetween(fromDateTimeMoment, toDateTimeMoment) || moment(nextEventStart).isBetween(fromDateTimeMoment, toDateTimeMoment);
      });

      // between a single marker
      if (!this.state.selectedMarkers.length) {
        if (fromDateTimeMoment.isSameOrBefore(firstMarker.eventStart)) {
          this.state.selectedMarkers = [firstMarker];
        } else if (fromDateTimeMoment.isSameOrAfter(lastMarker.eventStart)) {
          this.state.selectedMarkers = [lastMarker];
        } else {
          const nextMarkerIndex = orderedMarkers.findIndex(({ eventStart }) => fromDateTimeMoment.isBefore(eventStart));
          const selectedMarker = orderedMarkers[nextMarkerIndex - 1];

          this.state.selectedMarkers = selectedMarker ? [selectedMarker] : [];
        }
      }

      this.state.previousMarker = this.calculatePreviousMarker(markers, this.state.selectedMarkers);
      this.state.nextMarker = this.calculateNextMarker(markers, this.state.selectedMarkers);

      this.state.firstMarkerComplete = this.calculateMarkerIsComplete(this.state.selectedMarkers[0], this.state);
      this.state.lastMarkerComplete = this.calculateMarkerIsComplete(this.state.selectedMarkers[this.state.selectedMarkers.length - 1], this.state);
    }
  }

  componentWillMount() {
    const debate = this.props.debate;
    const videoStartMoment = this.context.getService('video-sync').getVideoStartDate();

    this.videoStartMoment = videoStartMoment ? videoStartMoment.clone().milliseconds(0) : moment(debate.get('startedAt'));

    this.updateMarkers(debate.get('events').toJS().filter(eventFilter));
    this.context.getService('video-sync').addEventListener('change', this.handleVideoSyncChange);

    MOBILE_QUERY.addListener(this.handleResize);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.debate.get('events') !== nextProps.debate.get('events')) {
      this.updateMarkers(nextProps.debate.get('events').toJS().filter(eventFilter));
    }
  }

  componentWillUnmount() {
    this.context.getService('video-sync').removeEventListener('change', this.handleVideoSyncChange);

    MOBILE_QUERY.removeListener(this.handleResize);
  }

  handleResize = () => {
    this.forceUpdate();
  };

  handleVideoSyncChange = ({ videoStartMoment }) => {
    this.videoStartMoment = videoStartMoment && videoStartMoment.clone().milliseconds(0);
    this.updateMarkers(this.state.markers);
  };

  resize() {
    if (this.context.swipeableViews) {
      this.context.swipeableViews.slideUpdateHeight();
    }
  }

  updateMarkers(events) {
    const selectMarkerIndex = this.props.marker
      ? events.findIndex((event) => generateEventId(event) === generateEventId(this.props.marker))
      : events.length - 1;
    const selectedMarkers = this.state.selectedMarkers.length > 0 ? this.state.selectedMarkers : [events[selectMarkerIndex]];
    const previousMarker = this.calculatePreviousMarker(events, selectedMarkers);
    const nextMarker = this.calculateNextMarker(events, selectedMarkers);

    const fromDateTime = this.state.fromDateTime || this.calculateFromDateTime(selectedMarkers, previousMarker);
    const toDateTime = this.state.toDateTime || this.calculateToDateTime(selectedMarkers, nextMarker);

    let fromSeconds = this.state.fromSeconds;
    let toSeconds = this.state.toSeconds;

    if (this.videoStartMoment) {
      fromSeconds = this.state.fromSeconds || moment(fromDateTime).diff(this.videoStartMoment, 's');
      toSeconds = this.state.toSeconds || moment(toDateTime).diff(this.videoStartMoment, 's');
    }

    this.setState({
      maxDate: this.state.maxDate || this.props.debate.get('endedAt') || moment().format(),
      markers: events,
      selectedMarkers,
      previousMarker,
      nextMarker,
      fromDateTime,
      toDateTime,
      fromSeconds,
      toSeconds,
    });
  }

  calculateFromDateTime(selectedMarkers) {
    return selectedMarkers[0]?.eventStart;
  }

  calculateToDateTime(selectedMarkers, nextMarker) {
    if (nextMarker) {
      return nextMarker.eventStart;
    }

    const lastIndex = selectedMarkers.length - 1;

    return selectedMarkers[lastIndex]?.eventStart;
  }

  calculatePreviousMarker(markers, selectedMarkers) {
    const firstMarker = selectedMarkers[0];

    if (!firstMarker) {
      return null;
    }

    const firstMarkerId = generateEventId(firstMarker);
    const firstMarkerIndex = markers.findIndex((marker) => generateEventId(marker) === firstMarkerId);

    return markers[firstMarkerIndex + 1];
  }

  calculateMarkerIsComplete(marker, state) {
    const { fromDateTime, toDateTime, markers } = state;

    if (!marker || moment(fromDateTime).isAfter(marker.eventStart)) {
      return false;
    }

    const markerId = generateEventId(marker);
    const index = markers.findIndex((event) => generateEventId(event) === markerId);
    const nextMarker = markers[index - 1];

    if (!nextMarker) {
      return false;
    }

    return moment(toDateTime).isSameOrAfter(nextMarker.eventStart);
  }

  calculateNextMarker(markers, selectedMarkers) {
    const lastMarker = selectedMarkers[selectedMarkers.length - 1];

    if (!lastMarker) {
      return null;
    }

    const lastMarkerId = generateEventId(lastMarker);
    const lastMarkerIndex = markers.findIndex((marker) => generateEventId(marker) === lastMarkerId);

    return markers[lastMarkerIndex - 1];
  }

  addMarker(marker, append) {
    const selectedMarkers = append ? [...this.state.selectedMarkers, marker] : [marker, ...this.state.selectedMarkers];
    const previousMarker = this.calculatePreviousMarker(this.state.markers, selectedMarkers);
    const nextMarker = this.calculateNextMarker(this.state.markers, selectedMarkers);

    const fromDateTime = !append ? this.calculateFromDateTime(selectedMarkers, previousMarker) : this.state.fromDateTime;
    const toDateTime = append ? this.calculateToDateTime(selectedMarkers, nextMarker) : this.state.toDateTime;

    let fromSeconds, toSeconds;

    if (this.videoStartMoment) {
      fromSeconds = !append ? moment(fromDateTime).diff(this.videoStartMoment, 's') : this.state.fromSeconds;
      toSeconds = append ? moment(toDateTime).diff(this.videoStartMoment, 's') : this.state.toSeconds;
    }

    const tmpState = { fromDateTime, toDateTime, markers: this.state.markers };

    this.setState(
      {
        firstMarkerComplete: this.calculateMarkerIsComplete(selectedMarkers[0], tmpState),
        lastMarkerComplete: this.calculateMarkerIsComplete(selectedMarkers[selectedMarkers.length - 1], tmpState),
        selectedMarkers,
        previousMarker,
        nextMarker,
        fromDateTime,
        toDateTime,
        fromSeconds,
        toSeconds,
      },
      this.resize,
    );
  }

  removeMarker(first) {
    const selectedMarkers = first ? this.state.selectedMarkers.slice(1) : this.state.selectedMarkers.slice(0, -1);

    if (selectedMarkers.length === 0) {
      return null;
    }

    const previousMarker = this.calculatePreviousMarker(this.state.markers, selectedMarkers);
    const nextMarker = this.calculateNextMarker(this.state.markers, selectedMarkers);

    const fromDateTime = first ? this.calculateFromDateTime(selectedMarkers, previousMarker) : this.state.fromDateTime;
    const toDateTime = !first ? this.calculateToDateTime(selectedMarkers, nextMarker) : this.state.toDateTime;

    let fromSeconds, toSeconds;

    if (this.videoStartMoment) {
      fromSeconds = first ? moment(fromDateTime).diff(this.videoStartMoment, 's') : this.state.fromSeconds;
      toSeconds = !first ? moment(toDateTime).diff(this.videoStartMoment, 's') : this.state.toSeconds;
    }

    const tmpState = { fromDateTime, toDateTime, markers: this.state.markers };

    this.setState(
      {
        firstMarkerComplete: this.calculateMarkerIsComplete(selectedMarkers[0], tmpState),
        lastMarkerComplete: this.calculateMarkerIsComplete(selectedMarkers[selectedMarkers.length - 1], tmpState),
        selectedMarkers,
        previousMarker,
        nextMarker,
        fromDateTime,
        toDateTime,
        fromSeconds,
        toSeconds,
      },
      this.resize,
    );
  }

  openTimePicker = (key) => (event) => {
    const current = this.state[key];

    let hours;
    let minutes;
    let seconds;

    if (typeof current === 'number') {
      seconds = current;

      hours = Math.floor(current / 3600);
      seconds -= hours * 3600;
      minutes = Math.floor(seconds / 60);
      seconds -= minutes * 60;
    } else {
      const dateTime = moment(current);

      hours = dateTime.hours();
      minutes = dateTime.minutes();
      seconds = dateTime.seconds();
    }

    this.setState({
      timePickerOpen: true,
      timePickerKey: key,
      timePickerAnchor: event.currentTarget,
      timePickerHours: hours,
      timePickerMinutes: minutes,
      timePickerSeconds: seconds,
    });
  };

  handleTimePickerClose = () => {
    this.setState({
      timePickerOpen: false,
      timePickerAnchor: null,
    });
  };

  handleTimePickerConfirm = ({ hours, minutes, seconds }) => {
    const { timePickerKey, maxDate } = this.state;
    const videoEndMoment = moment(maxDate);

    const toState = {
      ...this.state,
      timePickerOpen: false,
      timePickerAnchor: null,
    };

    if (typeof this.state[timePickerKey] === 'number') {
      toState[timePickerKey] = Math.max(Math.min(hours * 3600 + minutes * 60 + seconds, videoEndMoment.diff(this.videoStartMoment, 's')), 0);
    } else {
      let toMoment = this.videoStartMoment.clone().hours(hours).minutes(minutes).seconds(seconds);

      // determine day
      if (toMoment.isBefore(this.videoStartMoment) && hours <= 4) {
        toMoment = videoEndMoment.clone().hours(hours).minutes(minutes).seconds(seconds);
      }

      toState[timePickerKey] = moment.max(moment.min(toMoment, videoEndMoment), this.videoStartMoment).format('YYYY-MM-DDTHH:mm:ssZ');
    }

    // eslint-disable-next-line default-case
    switch (timePickerKey) {
      case 'fromDateTime':
        toState.fromSeconds = moment(toState.fromDateTime).diff(this.videoStartMoment, 's');
        break;
      case 'toDateTime':
        toState.toSeconds = moment(toState.toDateTime).diff(this.videoStartMoment, 's');
        break;
      case 'fromSeconds':
        toState.fromDateTime = this.videoStartMoment.clone().add(toState.fromSeconds, 's').format();
        break;
      case 'toSeconds':
        toState.toDateTime = this.videoStartMoment.clone().add(toState.toSeconds, 's').format();
        break;
    }

    if (toState.fromSeconds > toState.toSeconds) {
      if (timePickerKey === 'fromDateTime' || timePickerKey === 'fromSeconds') {
        toState.toDateTime = toState.fromDateTime;
        toState.toSeconds = toState.fromSeconds;
      } else {
        toState.fromDateTime = toState.toDateTime;
        toState.fromSeconds = toState.toSeconds;
      }
    }

    // lets calculate the new selected markers
    const fromDateTimeMoment = moment(toState.fromDateTime);
    const toDateTimeMoment = moment(toState.toDateTime);

    const orderedMarkers = this.state.markers
      .slice()
      .reverse()
      .map((cur, index, all) => {
        cur.nextEventStart = all[index + 1] && all[index + 1].eventStart;

        return cur;
      });

    const firstMarker = orderedMarkers[0];
    const lastMarker = orderedMarkers[orderedMarkers.length - 1];

    // all between start and end
    toState.selectedMarkers = orderedMarkers.filter(({ eventStart, nextEventStart }) => {
      return moment(eventStart).isBetween(fromDateTimeMoment, toDateTimeMoment) || moment(nextEventStart).isBetween(fromDateTimeMoment, toDateTimeMoment);
    });

    // between a single marker
    if (!toState.selectedMarkers.length) {
      if (fromDateTimeMoment.isSameOrBefore(firstMarker.eventStart)) {
        toState.selectedMarkers = [firstMarker];
      } else if (fromDateTimeMoment.isSameOrAfter(lastMarker.eventStart)) {
        toState.selectedMarkers = [lastMarker];
      } else {
        const nextMarkerIndex = orderedMarkers.findIndex(({ eventStart }) => fromDateTimeMoment.isBefore(eventStart));
        const selectedMarker = orderedMarkers[nextMarkerIndex - 1];

        toState.selectedMarkers = selectedMarker ? [selectedMarker] : [];
      }
    }

    toState.previousMarker = this.calculatePreviousMarker(this.state.markers, toState.selectedMarkers);
    toState.nextMarker = this.calculateNextMarker(this.state.markers, toState.selectedMarkers);

    toState.firstMarkerComplete = this.calculateMarkerIsComplete(toState.selectedMarkers[0], toState);
    toState.lastMarkerComplete = this.calculateMarkerIsComplete(toState.selectedMarkers[toState.selectedMarkers.length - 1], toState);

    this.setState(toState);
  };

  handlePreviewFragmentClick = () => {
    const { fromDateTime, toDateTime } = this.state;
    const { onPreviewFragmentOpen } = this.props;
    const videoUrl = this.props.debate.getIn(['video', 'catchup', 'url']);

    this.setState({
      previewFragmentSrc: addSourceParameters(videoUrl, fromDateTime, toDateTime),
      previewFragmentStart: fromDateTime,
      previewFragmentEnd: toDateTime,
    });

    onPreviewFragmentOpen?.();
  };

  handlePreviewFragmentClose = () => {
    const { onPreviewFragmentClose } = this.props;

    this.setState({
      previewFragmentUrl: false,
      previewFragmentStart: null,
      previewFragmentEnd: null,
    });

    onPreviewFragmentClose?.();
  };

  handleConfirmClick = () => {
    if (typeof this.props.onConfirm !== 'function') {
      return;
    }

    this.props.onConfirm({
      start: this.state.fromDateTime,
      end: this.state.toDateTime,
      quality: this.state.quality,
    });
  };

  handleQualityChange = (event, quality) => {
    this.setState({
      quality,
    });
  };

  renderStartTimeFields() {
    return (
      <div className="FragmentSelect-startTime">
        <div className="FragmentSelect-timeTitle">Starttijd</div>
        <div className="FragmentSelect-timeGroup">
          <div className="FragmentSelect-time" onClick={this.openTimePicker('fromDateTime')}>
            <div className="FragmentSelect-timeLabel">Tijd op de dag</div>
            <div className="FragmentSelect-timeInput">{moment(this.state.fromDateTime).format('HH:mm:ss')}</div>
          </div>
          <div className="FragmentSelect-time" onClick={this.openTimePicker('fromSeconds')}>
            <div className="FragmentSelect-timeLabel">Tijd in de video</div>
            <div className="FragmentSelect-timeInput">{formatters.formatDuration(this.state.fromSeconds)}</div>
          </div>
        </div>
      </div>
    );
  }

  renderEndTimeFields() {
    return (
      <div className="FragmentSelect-endTime">
        <div className="FragmentSelect-timeTitle">Eindtijd</div>
        <div className="FragmentSelect-timeGroup">
          <div className="FragmentSelect-time" onClick={this.openTimePicker('toDateTime')}>
            <div className="FragmentSelect-timeLabel">Tijd op de dag</div>
            <div className="FragmentSelect-timeInput">{moment(this.state.toDateTime).format('HH:mm:ss')}</div>
          </div>
          <div className="FragmentSelect-time" onClick={this.openTimePicker('toSeconds')}>
            <div className="FragmentSelect-timeLabel">Tijd in de video</div>
            <div className="FragmentSelect-timeInput">{formatters.formatDuration(this.state.toSeconds)}</div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { selectedMarkers, previousMarker, nextMarker, firstMarkerComplete, lastMarkerComplete } = this.state;
    const { previewFragmentOpen } = this.props;

    const selectedMarkersCount = selectedMarkers.length;
    const firstSelectedMarker = selectedMarkers[0];
    const politiciansDict = politiciansDictSelector({ politicians: this.props.politicians });

    const totalSeconds = this.state.toSeconds - this.state.fromSeconds;
    const quality = this.state.quality;
    const sizeExceeded = window.cordova && (quality ? quality.bandwidth : 1) * totalSeconds >= 1e9;

    const mobile = MOBILE_QUERY.matches;

    return (
      <form className="FragmentSelect">
        {mobile ? this.renderStartTimeFields() : null}
        <div className="FragmentSelect-markers">
          <div className="FragmentSelect-markers-prev">
            <div className="FragmentSelect-col">
              {previousMarker ? renderExcludedMarker(previousMarker, politiciansDict, () => this.addMarker(previousMarker, false)) : null}
            </div>
          </div>
          <div className="FragmentSelect-markers-selection">
            <div className="FragmentSelect-col u-centerSelf">
              {firstSelectedMarker
                ? renderIncludedMarker(firstSelectedMarker, politiciansDict, firstMarkerComplete, () => this.removeMarker(true), selectedMarkersCount > 1)
                : null}
              {selectedMarkersCount > 1 ? (
                <div className="FragmentSelect-markers-intermediate">
                  <div className="FragmentSelect-markers-circle">
                    <span>{selectedMarkersCount - 2}</span>
                  </div>
                  Tussenliggende spreekmomenten
                </div>
              ) : null}
              {selectedMarkersCount > 1
                ? renderIncludedMarker(selectedMarkers[selectedMarkers.length - 1], politiciansDict, lastMarkerComplete, () => this.removeMarker(false), true)
                : null}
            </div>
            {!mobile ? (
              <div className="FragmentSelect-col">
                {this.renderStartTimeFields()}
                {this.renderEndTimeFields()}
              </div>
            ) : null}
          </div>
          <div className="FragmentSelect-markers-next">
            <div className="FragmentSelect-col">
              {nextMarker ? renderExcludedMarker(nextMarker, politiciansDict, () => this.addMarker(nextMarker, true)) : null}
            </div>
          </div>
        </div>
        {mobile ? this.renderEndTimeFields() : null}
        <div className="FragmentSelect-quality">
          <DownloadOptionsSelect
            seconds={totalSeconds}
            onChange={this.handleQualityChange}
            locationId={this.props.debate.get('locationId')}
            prefix="fragment"
          />
        </div>
        <div>
          <DownloadAgreement
            id="fragment-agreement"
            checked={this.state.agreementAccepted}
            onChange={(event) => this.setState({ agreementAccepted: event.currentTarget.checked })}
          />
        </div>
        <div className="FragmentSelect-actions">
          <Button className="Button--primary Button--medium Button--uppercase" onClick={this.handlePreviewFragmentClick} disabled={totalSeconds < 1}>
            <PlayArrow className="Button-icon" /> <span className="Button-label">Bekijk videofragment</span>
          </Button>
          <Button
            className="Button--primary Button--medium Button--uppercase"
            onClick={this.handleConfirmClick}
            disabled={totalSeconds < 1 || sizeExceeded || !this.state.agreementAccepted}
          >
            <Download className="Button-icon" /> <span className="Button-label">Download videofragment</span>
          </Button>
        </div>
        <TimePicker
          open={this.state.timePickerOpen}
          anchor={this.state.timePickerAnchor}
          hours={this.state.timePickerHours}
          minutes={this.state.timePickerMinutes}
          seconds={this.state.timePickerSeconds}
          onClose={this.handleTimePickerClose}
          onConfirm={this.handleTimePickerConfirm}
          keyboardEnabled={!this.state.isTouchDevice}
        />
        <PreviewFragmentModal
          open={previewFragmentOpen}
          src={this.state.previewFragmentSrc}
          start={this.state.previewFragmentStart}
          end={this.state.previewFragmentEnd}
          onClose={this.handlePreviewFragmentClose}
        />
      </form>
    );
  }
}
