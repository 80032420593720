import PropTypes from 'prop-types';
import React from 'react';
import component from 'omniscient';

import SmartScrollView from '../../components/ScrollView/SmartScrollView';

/**
 * Definition of DayErrorComponent.
 */
const definition = {
  contextTypes: {
    getService: PropTypes.func.isRequired,
  },
};

/**
 * Render method of DayErrorComponent.
 */
const render = function () {
  const agendaDateString = this.context.getService('date').getUserFriendlyAgendaDate(),
    heading = 'Debatten ' + (agendaDateString ? 'van ' + agendaDateString : '');

  return (
    <div className="DayContainer">
      <SmartScrollView ref="scrollView" externalWheelHandling={true}>
        <div className="Main-content Content">
          <section className="Content-section Section">
            <h2 className="Section-heading Heading u-bordered u-borderBottom">{heading}</h2>
            <p>Er is een fout opgetreden bij het laden van de gegevens.</p>
          </section>
        </div>
      </SmartScrollView>
    </div>
  );
};

/**
 * Public exports.
 */
export default component('DayErrorComponent', definition, render);
