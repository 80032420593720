import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import component from 'omniscient';
import observer from 'omnipotent/decorator/observer';

import { structure } from '../../core';
import SwipeDetector from '../../utils/lib/SwipeDetector';
import Button from '../../components/Button/Button';
import Icon from '../../components/Icon/Icon';
import InternalLink from '../../components/InternalLink/InternalLink';
import ScrollView from '../../components/ScrollView/SmartScrollView';
import OnClickOutsideCreator from '../OnClickOutside/OnClickOutside';
import EventListener from '../../components/EventListener/EventListener';

const OnClickOutside = OnClickOutsideCreator.withConfig({ ignoreClass: 'js-ocoMenuIgnore' });

/**
 * Creates a category menu item
 */
const createCategoryItem = (pathTo, props) => (cursor) => {
    // we already have a menu item for votings
    if (cursor.get('id') === 'stemmingen') {
      return null;
    }


    const agendaCategories = props.categories;
    const agendaCategory = agendaCategories.find((current) => current.get('id') === cursor.get('id'));
    const name = cursor.get('name');
    const slug = cursor.get('slug');
    const path = pathTo('category', { date: props.agendaDate.deref(), category: slug });
    const className = classNames('Menu-item Menu-itemSecondary');
    const debateCount = agendaCategory?.get('debateIds').count() || 0;

    return (
      <InternalLink className={className} activeClassName="Menu-item--active" href={path} key={slug} aria-label={`${name} ${debateCount}`}>
        <div className="Menu-itemContent">
          <span className="Menu-itemTitle">{name}</span>
          <span className="Menu-itemAmount">{debateCount}</span>
        </div>
      </InternalLink>
    );
  },
  getNotificationCount = (notifications) => {
    const debateSubscriptions = notifications.getIn(['attributes', 'debateSubscriptions']);
    const politicianSubscriptions = notifications.getIn(['attributes', 'politicianSubscriptions']);
    const debateTypeSubscriptions = notifications.getIn(['attributes', 'debateTypeSubscriptions']);
    const subjectSubscriptions = notifications.getIn(['attributes', 'subjectSubscriptions']);

    const debateSubscriptionsCount = debateSubscriptions ? debateSubscriptions.count() : 0;
    const politicianSubscriptionsCount = politicianSubscriptions ? politicianSubscriptions.count() : 0;
    const debateTypeSubscriptionsCount = debateTypeSubscriptions ? debateTypeSubscriptions.count() : 0;
    const subjectSubscriptionsCount = subjectSubscriptions ? subjectSubscriptions.count() : 0;

    return debateSubscriptionsCount + politicianSubscriptionsCount + debateTypeSubscriptionsCount + subjectSubscriptionsCount;
  },
  categorySorter = (a, b) => {
    if ('Overig' === a.get('name')) {
      return 1;
    }

    if ('Overig' === b.get('name')) {
      return -1;
    }

    return a.get('name').localeCompare(b.get('name'));
  },
  // Component definition

  /**
   * Component definition
   * @type {Object}
   */
  definition = {
    contextTypes: {
      pathTo: PropTypes.func.isRequired,
      getService: PropTypes.func.isRequired,
      getCursor: PropTypes.func.isRequired,
    },

    getInitialState: () => ({
      categoriesOpened: false,
    }),

    mixins: [OnClickOutside],

    handleClickOutside: function () {
      this.closeMenu();
    },

    componentDidMount: function () {
      this._setSwipeDetector();

      const platformService = this.context.getService('platform');

      // Android and IOS don't take into account that there can be a URL bar present when the 100vw height calculation is done.
      // This causes the footer to disappear.
      // Platform specific fix with JS.
      if (!window.cordova && (platformService.isIOS() || platformService.isAndroid())) {
        this.setHeight();
        window.addEventListener('resize', this.setHeight);
      }
    },

    componentWillUnmount: function () {
      window.removeEventListener('resize', this.setHeight);

      if (this._swipeDetector) {
        this._swipeDetector.dispose();
        this._swipeDetector = null;
      }
    },

    setHeight: function () {
      this.refs.menuContainer.style.height = window.innerHeight + 'px';
    },

    componentDidUpdate: function (prevProps) {
      const isVisible = this.props.menu.get('isVisible');
      const wasVisible = prevProps.menu.get('isVisible');

      if (isVisible && !wasVisible) {
        this.lastFocusedElement = document.activeElement;
        if (this.lastFocusedElement?.tagName === 'BODY') {
          // Fallback when the last focused element cannot be found (happens on VoiceOver, iOS).
          this.lastFocusedElement = document.querySelector('.Button--menu');
        }
        setTimeout(() => {
          this.refs.main.querySelector('.Menu-item').focus({ preventScroll: true });
        }, 100); // wait for the menu to be visible. otherwise the focus will be lost.
      } else if (!isVisible && wasVisible) {
        this.lastFocusedElement?.focus();
      }
      this.afterRender();
    },

    /**
     * Set swipe detector.
     * @private
     */
    _setSwipeDetector: function () {
      if (this._swipeDetector) {
        return;
      }

      this._swipeDetector = new SwipeDetector(this.refs.main);
      this._swipeDetector.onSwipe = this._handleSwipe;
      this._swipeDetector.init();
    },

    /**
     * Handle swipe event.
     * @param event
     * @private
     */
    _handleSwipe: function (event) {
      if (event.direction === SwipeDetector.LEFT()) {
        this.closeMenu();
      }
    },

    /**
     * After render updates.
     */
    afterRender: function () {
      const { categoriesHeader, categoriesContainer } = this.refs;

      // Bail out if we don't have categories.
      if (!categoriesHeader || !categoriesContainer) {
        this.setState({ categoriesOpened: false });
        return;
      }

      // Update categories header based on selection of category.
      const selectedCategory = categoriesContainer.querySelector('.Menu-item--active');

      categoriesHeader.classList[selectedCategory ? 'add' : 'remove']('navCategoryActive');

      // Update categories height.
      this.updateCategoriesHeight();
    },

    /**
     * Update the height set on the categories' container.
     * This is needed
     */
    updateCategoriesHeight: function () {
      // If categories are closed, there is no need to update height.
      if (!this.state.categoriesOpened) {
        return;
      }

      const { categoriesContainer, categoriesSubContainer } = this.refs,
        targetHeight = categoriesSubContainer.offsetHeight || 0;

      categoriesContainer.style.height = targetHeight + 'px';
    },

    /**
     * Closes the menu
     * @param  {Event} [event] Event
     */
    closeMenu: function (event) {
      event?.preventDefault();

      if (!this.props.menu.get('isVisible')) {
        return;
      }

      window.requestAnimationFrame(() => this.props.menu.update('isVisible', () => false));
    },

    /**
     * Toggle open state of categories.
     */
    toggleCategories: function () {
      this.setCategories(!this.state.categoriesOpened);
    },

    /**
     * Open or close categories.
     * @param {Boolean} open
     */
    setCategories: function (open) {
      const { categoriesHeader, categoriesContainer, categoriesSubContainer } = this.refs,
        targetHeight = open ? categoriesSubContainer.offsetHeight : 0;

      clearTimeout(this.hideTimeout);

      categoriesContainer.style.height = targetHeight + 'px';
      this.setState({ categoriesOpened: open });

      if (open) {
        categoriesHeader.classList.add('navCategories--Opened');
        categoriesContainer.classList.add('navCategories--Opened');
      } else {
        categoriesHeader.classList.remove('navCategories--Opened');

        // Remove visibility with a little timeout because of animation.
        this.hideTimeout = setTimeout(() => {
          categoriesContainer.classList.remove('navCategories--Opened');
        }, 300);
      }
    },

    getTabIndex: function () {
      return this.props.menu.get('isVisible') ? 0 : -1;
    },

    handleKeyUp: function (evt) {
      if (evt.key === 'Escape' && this.props.menu.get('isVisible')) {
        this.closeMenu();
      }
    },
  },
  /**
   * Menu render method
   * @param  {Cursor}          cursor  a cursor containing isVisibile, categories and notifications
   * @return {React.Component}         The component
   */
  render = function render({ menu, persistentCategories, notifications }) {
    const { pathTo, getService } = this.context,
      isVisible = menu.get('isVisible'),
      sortedCategories = persistentCategories.sort(categorySorter),
      notificationCount = getNotificationCount(notifications),
      itemClassName = classNames('Menu-item'),
      layerClass = classNames('ViewStack-layer Layer Layer--menu', {
        'is-visible': isVisible,
        'is-hidden': !isVisible,
      }),
      homePath = pathTo('home'),
      isLoggedIn = getService('auth').isLoggedIn(),
      supportsFileDownload = getService('platform').supportsFileDownload(),
      ariaAttributes = {};

    if (!isVisible) {
      // Force unfocusable menu elements because of CSS visibility transition issue
      ariaAttributes['aria-hidden'] = true;
    }

    return (
      <div className={layerClass} ref="main">
        <nav className="Menu" ref="menuContainer" id="main-menu" {...ariaAttributes}>
          <header className="Menu-header">
            <h3 className="Menu-headerLabel">Debat Direct</h3>
            <Button title="Sluit het menu" aria-label="Sluit het menu" className="Button--close" tabIndex={this.getTabIndex()} onClick={this.closeMenu}>
              <Icon name="close" />
            </Button>
          </header>

          <div className="Menu-content">
            <ScrollView>
              <EventListener event="keyup" callback={this.handleKeyUp} />
              <InternalLink
                className={itemClassName + ' navHome'}
                activeClassName="Menu-item--active"
                onlyActiveOnIndex={true}
                href={homePath}
                tabIndex={this.getTabIndex()}
                onClick={this.closeMenu}
                aria-label="Debatten van vandaag"
              >
                <div className="Menu-itemContent">
                  <span className="Menu-itemTitle">Debatten van vandaag</span>
                </div>
                <span className="Menu-itemBorder" />
              </InternalLink>
              <InternalLink
                className={itemClassName + ' navVotings'}
                activeClassName="Menu-item--active"
                href={pathTo('votings')}
                tabIndex={this.getTabIndex()}
                onClick={this.closeMenu}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    pathTo('votings');
                    this.closeMenu();
                  }
                }}
                aria-label="Stemmingen van deze week"
              >
                <div className="Menu-itemContent">
                  <span className="Menu-itemTitle">Stemmingen van deze week</span>
                </div>
                <span className="Menu-itemBorder" />
              </InternalLink>
              <div
                className={itemClassName + ' navCategoriesHeader'}
                ref="categoriesHeader"
                role="button"
                tabIndex={this.getTabIndex()}
                onClick={this.toggleCategories}
                aria-expanded={this.state.categoriesOpened}
                aria-controls="menu-categories"
                onKeyUp={(event) => {
                  if (event.key === 'Enter') {
                    this.toggleCategories();
                  }
                }}
              >
                <div className="Menu-itemContent">
                  <span className="Menu-itemTitle">Overzicht per categorie</span>
                </div>
              </div>
              <div className="Menu-categoriesMain" ref="categoriesContainer" onClick={this.closeMenu} id="menu-categories">
                <div className="Menu-categoriesSub" ref="categoriesSubContainer">
                  {sortedCategories.map(createCategoryItem(pathTo, this.props))}
                </div>
              </div>
              <div className="Menu-separator" />
              <InternalLink
                className={itemClassName + ' navKamerleden'}
                activeClassName="Menu-item--active"
                href={pathTo('parliament-members')}
                tabIndex={this.getTabIndex()}
                onClick={this.closeMenu}
              >
                <div className="Menu-itemContent">
                  <span className="Menu-itemTitle">Kamerleden</span>
                </div>
                <span className="Menu-itemBorder" />
              </InternalLink>

              <InternalLink
                className={itemClassName + ' navKabinetsleden'}
                activeClassName="Menu-item--active"
                href={pathTo('cabinet-members')}
                tabIndex={this.getTabIndex()}
                onClick={this.closeMenu}
              >
                <div className="Menu-itemContent">
                  <span className="Menu-itemTitle">Kabinetsleden</span>
                </div>
                <span className="Menu-itemBorder" />
              </InternalLink>

              {window.cordova ? (
                <InternalLink
                  className={itemClassName + ' navNotificaties'}
                  activeClassName="Menu-item--active"
                  href={pathTo('notifications')}
                  tabIndex={this.getTabIndex()}
                  onClick={this.closeMenu}
                >
                  <div className="Menu-itemContent">
                    <span className="Menu-itemTitle">Notificaties</span>
                    <span className="Menu-itemAmount">{notificationCount}</span>
                  </div>
                  <span className="Menu-itemBorder" />
                </InternalLink>
              ) : null}

              <InternalLink
                className={itemClassName + ' navPlenair'}
                activeClassName="Menu-item--active"
                href={pathTo('location-plenary')}
                tabIndex={this.getTabIndex()}
                onClick={this.closeMenu}
              >
                <div className="Menu-itemContent">
                  <span className="Menu-itemTitle">Over de Plenaire zaal</span>
                  <Icon name="arrow" className="u-inline u-centerSelf" width="10" height="14" aria-hidden="true" />
                </div>
              </InternalLink>

              <div className="Menu-separator" />

              {isLoggedIn ? (
                <InternalLink
                  className={itemClassName + ' navKamerbeelden'}
                  activeClassName="Menu-item--active"
                  href={pathTo('kamerbeelden')}
                  tabIndex={this.getTabIndex()}
                  onClick={this.closeMenu}
                >
                  <div className="Menu-itemContent">
                    <span className="Menu-itemTitle">Kamerbeelden</span>
                  </div>
                  <span className="Menu-itemBorder" />
                </InternalLink>
              ) : null}
              {supportsFileDownload ? <span className="Menu-itemBorder" /> : null}
              {supportsFileDownload && (
                <InternalLink
                  className={itemClassName + ' navDownloads'}
                  activeClassName="Menu-item--active"
                  href={pathTo('downloads')}
                  tabIndex={this.getTabIndex()}
                  onClick={this.closeMenu}
                >
                  <div className="Menu-itemContent">
                    <span className="Menu-itemTitle">Downloads</span>
                  </div>
                </InternalLink>
              )}
            </ScrollView>
          </div>

          <footer className="Footer Menu-footer u-flex u-row" onClick={this.closeMenu}>
            <InternalLink className="Menu-footerItem u-flex u-grow" href={pathTo('support')} tabIndex={this.getTabIndex()}>
              <span className="Menu-itemTitle u-flex u-grow">Support</span>
            </InternalLink>
            <InternalLink className="Menu-footerItem u-flex u-grow" href={pathTo('about')} tabIndex={this.getTabIndex()}>
              <span className="Menu-itemTitle u-flex u-grow">Over de app</span>
            </InternalLink>
            <InternalLink className="Menu-footerItem u-flex u-grow" href={pathTo('disclaimer')} tabIndex={this.getTabIndex()}>
              <span className="Menu-itemTitle u-flex u-grow">Disclaimer</span>
            </InternalLink>
          </footer>
        </nav>
      </div>
    );
  },
  Menu = component('Menu', definition, render),
  MenuObserved = observer(
    structure,
    {
      agendaDate: ['data', 'date'],
      currentDate: ['data', 'currentDate'],
      menu: ['ui', 'menu'],
      categories: ['data', 'categories'],
      persistentCategories: ['data', 'persistent', 'agendaOverview', 'categories'],
      notifications: ['data', 'notifications'],
    },
    Menu,
  );

export default withRouter(MenuObserved);
