import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import component from 'omniscient';
import observer from 'omnipotent/decorator/observer';
import { throttle } from 'throttle-debounce';

import { Close } from '../../icons/index';
import { structure } from '../../core';
import { wrapLastWordWith, date as dateUtils } from '../../common';
import { byId, bySlug } from '../../predicates';
import SmartScrollView from '../../components/ScrollView/SmartScrollView';
import ExternalLink from '../../components/ExternalLink/ExternalLink';
import Icon from '../../components/Icon/Icon';
import InternalLink from '../../components/InternalLink/InternalLink';
import Time from '../../components/Time/Time';

/**
 * Creates the metadata string for the debate list item
 * @param  {Cursor} debate
 * @return {String}
 */
const parseMetadata = (debate) => {
  const categories = debate.get('categoryNames'),
    type = debate.get('debateType'),
    location = debate.get('locationName');

  if (!location) {
    return type;
  }

  if (0 === categories.count()) {
    return `${type} in de ${location}`;
  }

  const parsedCategories = categories.join(' - ');

  return `${parsedCategories} - ${type} in de ${location}`;
};

/**
 * Create DebateIndexItem
 * @param   {Function} pathToDebateSubject
 * @param   {Number} headingLevel
 * @returns {Function} - wrapped function which creates debate index item
 */
const createDebateIndexItem = (pathToDebateSubject, headingLevel) => (debate) => {
  const href = pathToDebateSubject(debate),
    debateTimeClassName = classNames('DebateList-time u-flex', {
      'u-gray': !debate.has('endedAt'),
    }),
    startTime = debate.has('startedAt') ? debate.get('startedAt') : debate.get('startsAt'),
    endsAt = debate.has('endedAt') ? debate.get('endedAt') : debate.get('endsAt'),
    HeadingTag = `h${headingLevel}`;

  return (
    <li className="List-item" key={debate.get('id')}>
      <InternalLink href={href} className="Link DebateList-item u-pointer">
        <div className="DebateList-description u-shrink">
          <HeadingTag className="DebateList-heading">
            {wrapLastWordWith(debate.get('name'), <Icon className="u-inline" name="arrow" width="8.57" height="12" aria-hidden="true" />)}
          </HeadingTag>
          <div aria-label={`${dateUtils.timeFormatter(startTime)} tot ${dateUtils.timeFormatter(endsAt)}`} className={debateTimeClassName} role="text">
            <Time dateTime={startTime} /> - <Time dateTime={endsAt} />
          </div>
          <p className="List-itemMetadata Metadata">{parseMetadata(debate)}</p>
        </div>
      </InternalLink>
    </li>
  );
};

const renderFilterCategory = (category, pathTo, date) => {
  if (!category) {
    return null;
  }

  return (
    <span className="Main-heading-filter">
      GEFILTERD OP:&nbsp;
      <InternalLink title="Verwijder filter" href={pathTo('index-for-date', { date })}>
        {category.get('name')} <Close className="Main-heading-filter-close" />
      </InternalLink>
    </span>
  );
};

/**
 * Path to debate subject wrapper
 * @param {Cursor} locations
 * @param {Function} pathTo
 * @param {Cursor} categories
 * @param {String} agendaDate
 * @returns {Function}
 */
const pathToDebateSubjectWrapper = (pathTo, locations, categories, agendaDate) => (debate) => {
  let firstCategoryId = debate.get('categoryIds').first(),
    category = categories.find(byId(firstCategoryId)),
    location = locations.find(byId(debate.get('locationId'))),
    props = { debate: debate.get('slug'), date: agendaDate };

  props.category = category ? category.get('slug') : firstCategoryId;

  if (location) {
    props.location = location.get('slug');
  }

  return pathTo('debate-subject', props);
};

/**
 * Get debates for given category.
 * @param {Object} debates
 * @param {String} category
 */
const getDebatesForCategory = (debates, category) => {
  return debates.filter((debate) => {
    return category ? debate.get('categoryIds').contains(category) : true;
  });
};

/**
 * Get category for given slug.
 * @param {Object} categories
 * @param {String} categorySlug
 */
const getCategory = (categories, categorySlug) => {
  return categories.find(bySlug(categorySlug));
};

/**
 * Get props used for rendering.
 * @param {Cursor} debates
 * @param {Cursor} categories
 * @param {Cursor} categoriesDated
 * @param {String} categorySlug
 */
const getRenderProps = (debates, categories, categoriesDated, categorySlug) => {
  const allCategories = categories
    .concat(categoriesDated)
    .groupBy((category) => category.get('slug'))
    .map((group) => group.first())
    .toList();

  const filterCategory = getCategory(allCategories, categorySlug),
    filteredDebates = getDebatesForCategory(debates, filterCategory ? categorySlug : null);

  return {
    allCategories,
    filterCategory,
    filteredDebates,
    pastDebates: filteredDebates.filter((debate) => debate.has('endedAt')),
    currentDebates: filteredDebates.filter((debate) => debate.has('startedAt') && !debate.has('endedAt')),
    futureDebates: filteredDebates.filter((debate) => !debate.has('startedAt')),
  };
};

/**
 * Render message when no debates have been found.
 * @param {Object} context
 * @returns {React.Component}
 */
const createNoDebatesMessage = function (context, category, params) {
  const { pathTo } = context,
    agendaDateString = context.getService('date').getUserFriendlyAgendaDate(),
    heading = 'Debatten ' + (agendaDateString ? 'van ' + agendaDateString : '');

  return (
    <div className="DayContainer">
      <SmartScrollView ref="scrollView" externalWheelHandling={true}>
        <main role="main" className="Main-content Content">
          <h2 className="Main-heading Heading u-bordered u-borderBottom">
            <span>{heading}</span>
            {renderFilterCategory(category, pathTo, params.date)}
          </h2>
          <section className="Content-section Section">
            <p>Er zijn geen debatten gevonden.</p>
          </section>
        </main>
      </SmartScrollView>
    </div>
  );
};

/**
 * Context types for day and today component.
 */
const contextTypes = {
  getCursor: PropTypes.func.isRequired,
  getService: PropTypes.func.isRequired,
  pathTo: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
};

/**
 * Definition of day component.
 */
const dayDefinition = {
  contextTypes,

  /**
   * Create index section for debates.
   * @param {Object} debates
   * @param {Object} mapper
   * @returns {React.Component}
   */
  _createDebateIndexSection: function (debates, mapper, params, filterCategory, pathTo) {
    const { date } = params,
      className = 'DebateList List DebateList--otherday',
      agendaDateString = this.context.getService('date').getUserFriendlyAgendaDate(),
      heading = 'Debatten ' + (agendaDateString ? 'van ' + agendaDateString : '');

    return (
      <section className="Content-section Section">
        <h2 className="Main-heading Heading u-bordered u-borderBottom">
          <span>{heading}</span>
          {renderFilterCategory(filterCategory, pathTo, date)}
        </h2>
        <ul className={className}>{debates.map(mapper)}</ul>
      </section>
    );
  },
};

/**
 * Render function of day component.
 * @param {Object} props
 * @returns {React.Component}
 */
const dayRender = function ({ debates, categories, categoriesDated, locations, params, agendaDate }) {
  const allCategories = categories
    .concat(categoriesDated)
    .groupBy((category) => category.get('slug'))
    .map((group) => group.first())
    .toList();

  const { pathTo } = this.context,
    { category } = params,
    filterCategory = getCategory(allCategories, category),
    filteredDebates = getDebatesForCategory(debates, filterCategory ? category : null),
    pathToDebateSubject = pathToDebateSubjectWrapper(pathTo, locations, allCategories, agendaDate),
    debateMapper = createDebateIndexItem(pathToDebateSubject, 3);

  if (filteredDebates.isEmpty()) {
    return createNoDebatesMessage(this.context, filterCategory, params);
  }

  return (
    <div className="DayContainer">
      <SmartScrollView ref="scrollView" externalWheelHandling={true}>
        <main role="main" className="Main-content Content">
          {this._createDebateIndexSection(filteredDebates, debateMapper, params, filterCategory, pathTo)}
        </main>
      </SmartScrollView>
    </div>
  );
};

/**
 * Component definition for rendering the debates of today.
 * This component renders the debates divided in three sections: Geweest, Nu, Straks.
 * On init it will scroll automatically to the Nu section or - when Nu is missing - the Straks section.
 */
const todayDefinition = {
  contextTypes,

  getInitialState: () => ({
    scrollInfo: null,
    showAgendaUpdatePrompt: false,
  }),

  componentDidMount: function () {
    window.addEventListener('resize', this._handleResize, false);
    this._showAgendaUpdatePromptThrottled = throttle(60 * 1000, this._showAgendaUpdatePrompt, { noTrailing: true });
    this._setScrollAfterRender();
  },

  componentDidUpdate: function (prevProps) {
    const prevRenderProps = getRenderProps(prevProps.debates, prevProps.categories, prevProps.categoriesDated, prevProps.params.category),
      renderProps = getRenderProps(this.props.debates, this.props.categories, this.props.categoriesDated, this.props.params.category),
      hasChanged = {
        loading: this.props.loading !== prevProps.loading,
        pastDebates: renderProps.pastDebates.count() !== prevRenderProps.pastDebates.count(),
        currentDebates: renderProps.currentDebates.count() !== prevRenderProps.currentDebates.count(),
        futureDebates: renderProps.futureDebates.count() !== prevRenderProps.futureDebates.count(),
      };

    if (!hasChanged.loading && (hasChanged.pastDebates || hasChanged.currentDebates || hasChanged.futureDebates)) {
      this._showAgendaUpdatePromptThrottled();
    }

    this._setScrollAfterRender();
  },

  componentWillUnmount: function () {
    window.removeEventListener('resize', this._handleResize, false);
    this._showAgendaUpdatePromptThrottled.cancel();
  },

  /**
   * Notify screen readers about changed layout (geweest/nu/staks).
   * @link https://www.w3.org/WAI/WCAG21/Understanding/status-messages.html
   */
  _showAgendaUpdatePrompt: function () {
    this.setState({ showAgendaUpdatePrompt: true });

    setTimeout(() => {
      this.setState({ showAgendaUpdatePrompt: false });
    }, 10_000);
  },

  /**
   * Returns RSS button.
   * @param {Object} category
   * @param {Function} getService
   * @returns {React.Component|null}
   */
  _createAdditonalContent: function (category, getService) {
    // Only show button on unfiltered data.
    if (category) {
      return null;
    }

    const feeds = getService('interaction-additions').getFeedUrls();

    if (!feeds) {
      return null;
    }

    if (!feeds.RSS) {
      return null;
    }

    return <ExternalLink href={feeds.RSS} className="Button--rss" />;
  },

  /**
   * Create index section for debates.
   * @param {Object} debates
   * @param {Object} mapper
   * @param {String} type
   * @returns {React.Component}
   */
  _createDebateIndexSection: function (debates, mapper, type) {
    const headers = {
        past: 'Geweest',
        now: 'Nu',
        future: 'Straks',
      },
      className = classNames({
        DebateList: true,
        List: true,
        'DebateList--past': type === 'past',
        'DebateList--now': type === 'now',
        'DebateList--future': type === 'future',
      }),
      heading = headers[type],
      label = `Debatten van ${heading.toLowerCase()}`;

    return (
      <section className="Content-section Section" data-time-type={type}>
        <h3 className="Section-heading Heading u-bordered u-borderBottom" aria-label={label}>
          {heading}
        </h3>
        <ul className={className}>{debates.map(mapper)}</ul>
      </section>
    );
  },

  /**
   * Handle resize.
   */
  _handleResize: function () {
    this._setMinimumScrollHeight();
  },

  /**
   * Scroll actions after render.
   */
  _setScrollAfterRender: function () {
    // Collect scroll info.
    this.state.scrollInfo = this._collectScrollInfo();

    // Make sure we use just the right amount of padding below.
    this._setMinimumScrollHeight();

    // Scroll to currently relevant section.
    this._scrollToDebateSection();
  },

  /**
   * Collect info relevant for scrolling to debate section.
   */
  _collectScrollInfo: function () {
    const container = ReactDOM.findDOMNode(this),
      scrollView = this.refs.scrollView;

    if (!scrollView) {
      return null;
    }

    const scrollInfo = {
      scrollView: scrollView,
      scrollElement: container.querySelector('[data-scroll-body]'),
      past: container.querySelector('[data-time-type="past"]'),
      now: container.querySelector('[data-time-type="now"]'),
      future: container.querySelector('[data-time-type="future"]'),
    };

    if (scrollInfo.now) {
      scrollInfo.now.style.height = '';
    }

    if (scrollInfo.future) {
      scrollInfo.future.style.height = '';
    }

    // If there are currently active debates, scroll to that section.
    // If there aren't, but there are future debates, scroll to that section.
    // Otherwise, do not scroll.
    scrollInfo.target = scrollInfo.now ? 'now' : scrollInfo.future ? 'future' : null;

    // Return result.
    return scrollInfo;
  },

  /**
   * Set minimum scroll height.
   */
  _setMinimumScrollHeight: function () {
    const scrollInfo = this.state.scrollInfo;

    if (!scrollInfo?.target) {
      return;
    }

    const targetElement = scrollInfo[scrollInfo.target],
      belowElement = scrollInfo.target === 'now' ? scrollInfo.future : null,
      adaptElement = belowElement || targetElement,
      targetHeight = targetElement.offsetHeight,
      belowHeight = belowElement ? belowElement.offsetHeight : 0,
      minimumHeight = scrollInfo.scrollElement.offsetHeight - 130,
      adaptHeight = targetHeight + belowHeight < minimumHeight;

    adaptElement.style.height = adaptHeight ? minimumHeight + 'px' : '';
  },

  /**
   * Scroll to relevant debate section.
   */
  _scrollToDebateSection: function () {
    const scrollInfo = this.state.scrollInfo;

    if (!scrollInfo) {
      return;
    }

    // Perform actual scroll.
    if (scrollInfo.target) {
      scrollInfo.scrollView.scrollTo(0, scrollInfo[scrollInfo.target].offsetTop - 65);
    } else {
      scrollInfo.scrollView.scrollTo(0, 0);
    }
  },
};

/**
 * Render method for today component.
 * @param {Object} props
 * @returns {React.Component}
 */
const todayRender = function ({ debates, categories, categoriesDated, locations, params, agendaDate }) {
  const { pathTo, getService } = this.context,
    { category, date } = params,
    { allCategories, filterCategory, filteredDebates, pastDebates, currentDebates, futureDebates } = getRenderProps(
      debates,
      categories,
      categoriesDated,
      category,
    ),
    pathToDebateSubject = pathToDebateSubjectWrapper(pathTo, locations, allCategories, agendaDate),
    debateMapper = createDebateIndexItem(pathToDebateSubject, 4);

  if (filteredDebates.isEmpty()) {
    return createNoDebatesMessage(this.context, filterCategory, params);
  }

  return (
    <div className="DayContainer">
      <SmartScrollView ref="scrollView" externalWheelHandling={true}>
        <main role="main" className="Main-content Content">
          <h2 className="Main-heading Heading u-bordered u-borderBottom u-mb10">
            <span>DEBATTEN VAN VANDAAG</span>
            {renderFilterCategory(filterCategory, pathTo, date)}
          </h2>
          {this._createAdditonalContent(category, getService)}
          {!pastDebates.isEmpty() && this._createDebateIndexSection(pastDebates, debateMapper, 'past')}
          {!currentDebates.isEmpty() && this._createDebateIndexSection(currentDebates, debateMapper, 'now')}
          {!futureDebates.isEmpty() && this._createDebateIndexSection(futureDebates, debateMapper, 'future')}
        </main>
      </SmartScrollView>
      {this.state.showAgendaUpdatePrompt ? (
        <div className="srOnly" role="alert" aria-live="assertive">
          De agenda van vandaag is geüpdatet.
        </div>
      ) : null}
    </div>
  );
};

/**
 * Public exports.
 */
export const DayComponent = observer(
  structure,
  {
    debates: ['data', 'debates'],
    categoriesDated: ['data', 'categories'],
    categories: ['data', 'persistent', 'agendaOverview', 'categories'],
    locations: ['data', 'locations'],
  },
  component('DayComponent', dayDefinition, dayRender),
);

export const TodayComponent = observer(
  structure,
  {
    debates: ['data', 'debates'],
    categoriesDated: ['data', 'categories'],
    categories: ['data', 'persistent', 'agendaOverview', 'categories'],
    locations: ['data', 'locations'],
  },
  component('TodayComponent', todayDefinition, todayRender),
);
