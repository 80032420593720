import moment from 'moment';

export function getDataFromManifest(url) {
  return new Promise((resolve) => {
    const handleLoad = function () {
      if (this.readyState === 4) {
        const text = this.responseText;
        const firstPdt = text.match(/#EXT-X-PROGRAM-DATE-TIME:(.+)/);
        const isLive = text.match(/#EXT-X-ENDLIST/) === null;

        resolve({
          firstPdt: firstPdt?.[1] ? moment(firstPdt[1]) : undefined,
          isLive,
        });
      }
    };

    if (!url) resolve();

    const request = new XMLHttpRequest();

    request.onload = handleLoad;
    request.open('GET', url);
    request.send(null);
  });
}
