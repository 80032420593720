import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import component from 'omniscient';
import observer from 'omnipotent/decorator/observer';

import { structure } from '../../core';
import { wrapLastWordWith, date as dateUtils } from '../../common';
import { byId } from '../../predicates';
import SmartScrollView from '../../components/ScrollView/SmartScrollView';
import Icon from '../../components/Icon/Icon';
import InternalLink from '../../components/InternalLink/InternalLink';
import Time from '../../components/Time/Time';

/**
 * Creates the metadata string for the debate list item
 * @param  {Cursor} debate
 * @param  {Cursor} locations
 * @return {String}
 */
const parseMetadata = (debate, locations) => {
  const category = debate.get('category'),
    type = debate.get('debateType'),
    locationId = debate.get('locationId'),
    location = locations.find(byId(locationId));

  if (!location) {
    return type;
  }

  const locationName = location.get('name');

  if (!category) {
    return `${type} in de ${locationName}`;
  }

  return `${category.get('name')} - ${type} in de ${locationName}`;
};

/**
 * Create DebateIndexItem
 * @param   {Function} pathToDebateSubject
 * @param   {Cursor} locations -   Locations list cursor
 * @param   {Function} handleClick - List item click handler
 * @returns {Function} - wrapped function which creates debate index item
 */
const createDebateIndexItem = (pathToDebateSubject, locations, handleClick) => (date) => (debate) => {
  const href = pathToDebateSubject(debate, date),
    debateTimeClassName = classNames('DebateList-time u-flex', {
      'u-gray': !debate.has('endedAt'),
    }),
    startTime = debate.has('startedAt') ? debate.get('startedAt') : debate.get('startsAt'),
    endsAt = debate.has('endedAt') ? debate.get('endedAt') : debate.get('endsAt');

  return (
    <li className="List-item" key={debate.get('id')} onClick={handleClick(href)}>
      <InternalLink href={href} className="Link DebateList-item u-pointer">
        <div className="DebateList-description u-shrink">
          <h3 className="DebateList-heading">
            {wrapLastWordWith(debate.get('name'), <Icon className="u-inline" name="arrow" width="8.57" height="12" aria-hidden="true" />)}
          </h3>
          <div aria-label={`${dateUtils.timeFormatter(startTime)} tot ${dateUtils.timeFormatter(endsAt)}`} className={debateTimeClassName} role="text">
            <Time dateTime={startTime} /> - <Time dateTime={endsAt} />
          </div>
          <p className="List-itemMetadata Metadata">{parseMetadata(debate, locations)}</p>
        </div>
      </InternalLink>
    </li>
  );
};

/**
 * Path to debate subject wrapper
 * @param {Cursor} locations
 * @param {Function} pathTo
 * @returns {Function}
 */
const pathToDebateSubjectWrapper = (pathTo, locations) => (debate, date) => {
  let category = debate.get('category'),
    location = locations.find(byId(debate.get('locationId'))),
    props = { debate: debate.get('slug'), date, category: 'overig' };

  if (category) {
    props.category = category.get('slug');
  }

  if (location) {
    props.location = location.get('slug');
  }

  return pathTo('debate-subject', props);
};

/**
 * Component definition for rendering the debates of today.
 * This component renders the debates divided in three sections: Geweest, Nu, Straks.
 * On init it will scroll automatically to the Nu section or - when Nu is missing - the Straks section.
 */
const votingsDefinition = {
  contextTypes: {
    getCursor: PropTypes.func.isRequired,
    getService: PropTypes.func.isRequired,
    pathTo: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
  },

  getInitialState: () => ({}),

  _handleListItemClick: function (href) {
    return (event) => {
      event.preventDefault();
      this.context.navigate(href);
    };
  },
};

/**
 * Render method for votings component.
 * @param {Object} props
 * @returns {React.Component}
 */
const votingsRender = function ({ locations, votings }) {
  const { pathTo } = this.context,
    pathToDebateSubject = pathToDebateSubjectWrapper(pathTo, locations),
    debateMapper = createDebateIndexItem(pathToDebateSubject, locations, this._handleListItemClick),
    dateService = this.context.getService('date'),
    weekDates = dateService.createWeekDates();

  if (!votings) {
    return null;
  }

  // only get days which is in the week range and contains debates
  const populatedDays = votings.get('days').filter((voting) => voting.has('debates') && weekDates.indexOf(voting.get('date')) !== -1);

  return (
    <div className="Layer ViewStack-layer">
      <Helmet>
        <title>Stemmingen van deze week | Debat Direct</title>
        <meta name="description" content="Volg Tweede Kamer-stemmingen live met extra informatie via Debat Direct." />
      </Helmet>
      <div className="Main-wrapper is-expanded" id="main" tabIndex="-1">
        <div className="DebateList-overlay" />
        <SmartScrollView ref="scrollView" externalWheelHandling={true}>
          <div className="Main-content Content">
            {!populatedDays.count() ? (
              <div>
                <h2 className="Main-heading Heading u-bordered u-borderBottom">Geen stemmingen</h2>
                <section className="Content-section Section">
                  <p>Er zijn geen stemmingen gevonden.</p>
                </section>
              </div>
            ) : (
              populatedDays.toArray().map((day) => (
                <section className="Content-section Section" key={day.get('date')}>
                  <h2 className="Main-heading Heading u-bordered u-borderBottom">{dateService.getUserFriendlyAgendaDate(day.get('date'))}</h2>
                  <ul className="DebateList List">
                    {day
                      .get('debates')
                      .toArray()
                      .map(debateMapper(day.get('date')))}
                  </ul>
                </section>
              ))
            )}
          </div>
        </SmartScrollView>
      </div>
    </div>
  );
};

/**
 * Public exports.
 */
export default observer(
  structure,
  {
    votings: ['data', 'votings'],
    locations: ['data', 'locations'],
  },
  component('VotingsCalendarComponent', votingsDefinition, votingsRender),
);
