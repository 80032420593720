/**
 * Wrapper around audio and video element.
 * It hides the internals of switching between video and audio.
 */
import trackEvent from '@debatdirect/core/common/lib/trackEvent';
import CastPlayer from '@debatdirect/core/containers/Video/CastPlayer';

class Player {
  /**
   * Constructor.
   * @param {Object} players - Object containing audio and video player.
   * @param configuration - Configuration properties.
   */
  constructor(players, configuration) {
    this._players = players;
    this._activeType = configuration.audioOnly ? 'audio' : 'video';
    this._debateName = configuration.debateName;
    this._debateTitle = configuration.debateTitle;
    this._sources = configuration.sources;
    this._castAppId = configuration.castAppId;
    this._isLive = configuration.isLive;

    this._isAndroidPlatform = !!window.cordova && window.device.platform === 'android';
    this._isAndroidWeb = !window.cordova && /android/i.test(navigator.userAgent || '');
    this._enableBackgroundMode = this._isAndroidPlatform;

    this._currentQuality = null;

    this._buffering = false;
    this._bufferingTime = undefined;

    this._castPlayer = !!window.cordova && !!window.chrome ? CastPlayer.getInstance() : undefined;

    this._state = {
      audio: {
        listeners: this._createInternalListeners('audio'),
        seek: null,
      },
      video: {
        listeners: this._createInternalListeners('video'),
        seek: null,
      },
    };

    this._externalListeners = {};
  }

  /**
   * Initialize.
   */
  init() {
    // Set listeners on both players.
    this._setInternalListeners(this._players.audio, this._state.audio.listeners, true);
    this._setInternalListeners(this._players.video, this._state.video.listeners, true);

    this._initializeCast();
  }

  /**
   * Dispose.
   */
  dispose() {
    // A convenient way to remove all the external listeners.
    this._externalListeners = {};

    // Remove the listeners we added to the audio and the video player.
    this._setInternalListeners(this._players.audio, this._state.audio.listeners, false);
    this._setInternalListeners(this._players.video, this._state.video.listeners, false);

    // Reset seek state.
    this._resetSeekState();

    // Stopping the Chromecast session will improve robustness when switching videos on Android. See: DDSB-4192
    if (this.cast.chromecast.casting && this._isAndroidWeb) {
      this.cast.chromecast.stop();
    }

    // Get all native media elements.
    const nativePlayers = document.querySelectorAll('audio, video');

    // Remove theoplayer instances in the next tick to prevent in-violation error
    setTimeout(this._destroyPlayers.bind(this, nativePlayers), 15);

    // Reset activeType to video
    this._activeType = 'video';

    if (this._castPlayer) this._castPlayer.removeAllEventListeners();
  }

  /**
   * Add external listener.
   * @param {String} eventName
   * @param {Function} eventHandler
   */
  addEventListener(eventName, eventHandler) {
    const externalListeners = this._externalListeners;

    if (!(eventName in externalListeners)) {
      externalListeners[eventName] = [eventHandler];
    } else {
      externalListeners[eventName].push(eventHandler);
    }
  }

  /**
   * Remove external listener.
   * @param {String} eventName
   * @param {Function} eventHandler
   */
  removeEventListener(eventName, eventHandler) {
    const externalListeners = this._externalListeners;

    if (!eventName || !(eventName in externalListeners)) {
      return;
    }

    externalListeners[eventName] = externalListeners[eventName].filter((listener) => listener !== eventHandler);
  }

  /**
   * Play.
   */
  prepareWithUserAction() {
    return this._getActivePlayer().prepareWithUserAction();
  }

  /**
   * Play.
   */
  play() {
    if (this._castPlayer?.getCasting()) return this._castPlayer.play();

    return this._getActivePlayer().play();
  }

  /**
   * Pause.
   */
  pause() {
    if (this._castPlayer?.getCasting()) return this._castPlayer.pause();

    return this._getActivePlayer().pause();
  }

  /**
   * Set player type.
   * @param {String} playerType - audio or video
   */
  setActivePlayerType(playerType) {
    if (playerType === this._activeType) {
      return;
    }

    const oldPlayer = this._players[this._activeType],
      newPlayer = this._players[playerType],
      targetTime = this._getTargetTimeAtPlayerSwitch(oldPlayer);

    // Update active type.
    this._activeType = playerType;

    // Copy volume and unmute new player
    newPlayer.volume = oldPlayer.volume;
    newPlayer.muted = false;

    // Pause and clear source of old player.
    oldPlayer.pause();

    // Set source of new player and play.
    newPlayer.play();

    // Copy playbackRate
    newPlayer.playbackRate = oldPlayer.playbackRate;

    if (targetTime > 0) {
      newPlayer.currentTime = targetTime;

      // Create a seek object, so we can seek once the new player triggers a canplay event.
      const seek = this._setSeekState('currentTime', targetTime);

      seek.afterPlayerSwitch = true;

      // Fallback timer (in case new player will not trigger canplay).
      seek.timer = setTimeout(this._forceCorrectSeek.bind(this, seek, 'timer'), 3500);
    }

    if (this._enableBackgroundMode) {
      if (this._activeType === 'audio') {
        window.cordova.plugins.backgroundMode.disableBatteryOptimizations();
      } else {
        window.cordova.plugins.backgroundMode.disable();
      }
    }
  }

  /**
   * Set src for audio and video.
   * @param {Object} sources
   * @param {Boolean} isLive
   */
  setSources(sources, isLive) {
    if (import.meta.env.DEV) {
      console.warn('New player sources: ', sources);
    }

    this._sources = sources;
    this._isLive = isLive;

    const activePlayer = this._getActivePlayer();

    activePlayer.src = sources[this._activeType];

    if (this._castPlayer) {
      this._castPlayer.setSource(
        {
          audio: this._sources.audio,
          url: this._sources.video,
          title: this._debateTitle,
          subtitle: this._debateName,
        },
        this._isLive,
      );
    }
  }

  /**
   * Get sources.
   */
  getSources() {
    return {
      ...this._sources,
    };
  }

  resumeCastSession() {
    if (this._castPlayer?.getCasting()) {
      this._castPlayer.handleAppResume();
    }
  }

  get readyState() {
    if (this._castPlayer?.getCasting()) return 4;

    const player = this._getActivePlayer();

    if (typeof player?.readyState === 'number') {
      return player.readyState;
    }

    return 2;
  }

  get qualities() {
    const player = this._getActivePlayer();

    if (player?.videoTracks?.length) {
      return player.videoTracks.item(0).qualities;
    }

    return [];
  }

  get activeQuality() {
    const player = this._getActivePlayer();

    if (player?.videoTracks?.length) {
      return player.videoTracks.item(0).activeQuality;
    }

    return null;
  }

  get targetQuality() {
    const player = this._getActivePlayer();

    if (player?.videoTracks?.length) {
      return player.videoTracks.item(0).targetQuality;
    }

    return null;
  }

  get textTracks() {
    if (this._castPlayer?.getCasting()) return this._castPlayer.getTextTracks();

    const player = this._getActivePlayer();

    if (player) {
      return player.textTracks;
    }

    return null;
  }

  get androidPictureInPictureSupported() {
    return this._isAndroidPlatform && 'PictureInPicture' in window.Capacitor.Plugins;
  }

  get pictureInPictureSupported() {
    const hasAndroidSupport = this.androidPictureInPictureSupported;
    const hasWebSupport = !this._isAndroidPlatform && 'pictureInPictureEnabled' in document && document.pictureInPictureEnabled;
    const isNativeCasting = !!this._castPlayer?.getCasting();

    return this._activeType === 'video' && !isNativeCasting && (hasWebSupport || hasAndroidSupport);
  }

  async togglePictureInPicture() {
    const player = this._getActivePlayer();

    if (!this.pictureInPictureSupported) return;

    if (this.androidPictureInPictureSupported) {
      const pipPlugin = window.Capacitor.Plugins.PictureInPicture;

      if (this.paused) this.play();
      pipPlugin.enterPictureInPicture();
      return;
    }

    if (document.pictureInPictureElement) {
      return document.exitPictureInPicture();
    }

    const videoElement = new Promise((resolve) => {
      const element = player.element.querySelector('video[src]');
      if (element) return resolve(element);
      player.addEventListener('playing', () => resolve(player.element.querySelector('video[src]')));
    });

    if (this.paused) this.play();
    (await videoElement).requestPictureInPicture();
  }

  /**
   * Get currentTime.
   * @returns {Number}
   */
  get currentTime() {
    if (this._castPlayer?.getCasting()) return this._castPlayer.getCurrentTime();

    return this._getActivePlayer().currentTime;
  }

  get seekable() {
    if (this._castPlayer?.getCasting()) {
      return {
        length: 1,
        start: () => 0,
        end: () => this._castPlayer.getSeekableEnd(),
      };
    }

    return this._getActivePlayer().seekable;
  }

  get buffered() {
    if (this._castPlayer?.getCasting()) {
      return {
        length: 1,
        start: () => 0,
        end: () => this._castPlayer.getSeekableEnd(),
      };
    }

    return this._getActivePlayer().buffered;
  }

  get played() {
    if (this._castPlayer?.getCasting()) return [1];

    return this._getActivePlayer().played;
  }

  get videoTracks() {
    return this._getActivePlayer().videoTracks;
  }

  get audioTracks() {
    return this._getActivePlayer().audioTracks;
  }

  get muted() {
    return this._getActivePlayer().muted;
  }

  set muted(muted) {
    this._getActivePlayer().muted = muted;
  }

  get volume() {
    return this._getActivePlayer().volume;
  }

  set volume(volume) {
    this._getActivePlayer().volume = volume;
  }

  get cast() {
    const cast = this._getActivePlayer().cast;

    if (this._castPlayer) {
      const airplay = cast?.airplay || {
        addEventListener: () => undefined,
        removeEventListener: () => undefined,
        casting: false,
        start: () => undefined,
        state: 'unavailable',
        stop: () => undefined,
      };

      return {
        casting: this._castPlayer.getCasting() || cast?.casting || false,
        chromecast: {
          addEventListener: this._castPlayer.on,
          removeEventListener: this._castPlayer.off,
          casting: this._castPlayer.getCasting(),
          state: this._castPlayer.getState(),
          receiverName: this._castPlayer.getReceiverName(),
          start: () => {
            const activePlayer = this._getActivePlayer();
            const startTime = activePlayer?.currentTime || 0;

            this._castPlayer.requestSession(startTime);
          },
          stop: () => this._castPlayer.stop(),
          leave: () => undefined,
        },
        airplay,
      };
    }

    return cast;
  }

  /**
   * Set currentTime
   * @param {Number} value
   */
  set currentTime(value) {
    if (this._castPlayer?.getCasting()) {
      this._castPlayer.seek(value);
      return;
    }

    const activePlayer = this._getActivePlayer(),
      seek = this._setSeekState('currentTime', value);

    seek.timer = setTimeout(this._forceCorrectSeek.bind(this, seek, 'timer'), 3000);
    activePlayer.currentTime = value;
  }

  /**
   * Get currentMonotonicTime
   * @returns {Number}
   */
  get currentMonotonicTime() {
    return this._getActivePlayer().currentMonotonicTime;
  }

  /**
   * Set currentMonotonicTime
   * @param {Number} value
   */
  set currentMonotonicTime(value) {
    this._resetSeekState();
    this._getActivePlayer().currentMonotonicTime = value;
  }

  /**
   * Get current program date time.
   * @returns {?Date}
   */
  get currentProgramDateTime() {
    if (this._castPlayer?.getCasting()) return this._castPlayer.getCurrentProgramDateTime();

    return this._getActivePlayer().currentProgramDateTime;
  }

  /**
   * Get duration.
   * @returns {Number}
   */
  get duration() {
    if (this._castPlayer?.getCasting()) return this._castPlayer.getDuration();

    return this._getActivePlayer().duration;
  }

  /**
   * Get paused state.
   * @returns {Boolean}
   */
  get paused() {
    if (this._castPlayer?.getCasting()) return !this._castPlayer.isPlaying();

    return this._getActivePlayer().paused;
  }

  /**
   * Get seeking state (relevant after switch from video to audio or vice versa).
   * @returns {Boolean}
   */
  get isSeekingAfterPlayerSwitch() {
    const seek = this._getSeekState(this._activeType);

    return !seek ? false : seek.afterPlayerSwitch || false;
  }

  /**
   * Get playbackRate
   * @returns {*|number|AudioParam}
   */
  get playbackRate() {
    return this._getActivePlayer().playbackRate;
  }

  /**
   * Set playbackRate
   * @param playbackRate
   */
  set playbackRate(playbackRate) {
    this._getActivePlayer().playbackRate = playbackRate;
  }

  get textTrackMarginBottom() {
    return this._players.video.textTrackStyle.marginBottom;
  }

  set textTrackMarginBottom(margin) {
    this._players.video.textTrackStyle.marginBottom = margin ?? 60;
  }

  /**
   * Create handlers for audio or video.
   * @param {String} playerType
   * @returns {Object}
   * @private
   */
  _createInternalListeners(playerType) {
    const initializedHandler = this._handleInitialized.bind(this, playerType);
    const canPlayHandler = this._handleCanPlay.bind(this, playerType);
    const seekedHandler = this._handleSeeked.bind(this, playerType);
    const waitingHandler = this._handleWaiting.bind(this, playerType);
    const playingHandler = this._handlePlaying.bind(this, playerType);
    const genericHandler = this._handlePlayerEvent.bind(this, playerType);
    const videoTrackHandler = this._handleVideoTrackEvent.bind(this, playerType);
    const textTrackAddHandler = this._handleTextTrackAddEvent.bind(this, playerType);
    const textTrackRemoveHandler = this._handleTextTrackRemoveEvent.bind(this, playerType);
    const textTrackChangeHandler = this._handleTextTrackChangeEvent.bind(this, playerType);
    const chromecastStateChangeHandler = this._handleChromecastStateChange.bind(this, playerType);
    const airplayStateChangeHandler = this._handleAirplayStateChange.bind(this, playerType);

    return {
      initialized: initializedHandler,
      readystatechange: genericHandler,
      canplay: canPlayHandler,
      error: genericHandler,
      unsupportedPlatform: genericHandler,
      waiting: waitingHandler,
      playing: playingHandler,
      pause: genericHandler,
      seeking: genericHandler,
      seeked: seekedHandler,
      timeupdate: genericHandler,
      durationchange: genericHandler,
      progress: genericHandler,
      ended: genericHandler,
      loadeddata: genericHandler,
      loadedmetadata: genericHandler,
      ratechange: genericHandler,
      representationchange: genericHandler,
      segmentnotfound: genericHandler,
      volumechange: genericHandler,
      textTracks: {
        addtrack: textTrackAddHandler,
        removetrack: textTrackRemoveHandler,
        change: textTrackChangeHandler,
      },
      videoTracks: {
        addtrack: videoTrackHandler,
      },
      cast: {
        chromecast: {
          statechange: chromecastStateChangeHandler,
        },
        airplay: {
          statechange: airplayStateChangeHandler,
        },
      },
    };
  }

  /**
   * Add or remove listeners for internal use.
   * @param {Object} player - TheoPlayer instance.
   * @param {Object} eventHandlers
   * @param {Boolean} activate
   * @private
   */
  _setInternalListeners(player, eventHandlers, activate) {
    const method = activate ? 'addEventListener' : 'removeEventListener';

    for (let i = 0, keys = Object.keys(eventHandlers); i < keys.length; i++) {
      let key = keys[i];
      let value = eventHandlers[key];

      if (Object.prototype.toString.call(value) === '[object Object]') {
        if (player[key]) {
          this._setInternalListeners(player[key], value, activate);
        }
      } else {
        player[method](key, value);
      }
    }
  }

  async _initializeCast() {
    if (!this._castPlayer) return;

    await this._castPlayer.initialize(this._castAppId);

    this._castPlayer.setSource(
      {
        audio: this._sources.audio,
        url: this._sources.video,
        title: this._debateTitle,
        subtitle: this._debateName,
      },
      this._isLive,
    );

    this._castPlayer.addEventListener('statechange', this._state.video.listeners.cast.chromecast.statechange);
    this._castPlayer.addEventListener('timeupdate', this._state.video.listeners.timeupdate);
    this._castPlayer.addEventListener('durationchange', this._state.video.listeners.durationchange);
    this._castPlayer.addEventListener('playing', this._state.video.listeners.playing);
    this._castPlayer.addEventListener('seeking', this._state.video.listeners.seeking);
    this._castPlayer.addEventListener('seeked', this._state.video.listeners.seeked);
    this._castPlayer.addEventListener('addtrack', this._state.video.listeners.textTracks.addtrack);
    this._castPlayer.addEventListener('removetrack', this._state.video.listeners.textTracks.removetrack);
    this._castPlayer.addEventListener('changetrack', this._state.video.listeners.textTracks.change);
    this._castPlayer.addEventListener('disconnect', this._handleCastDisconnect);
  }

  /**
   * Dispatch external event.
   * @param {String} playerType
   * @param {Object} event
   * @private
   */
  _dispatchEvent(playerType, event) {
    // An inactive player can still trigger durationchange events, we ignore those.
    if (playerType !== this._activeType) {
      return;
    }

    // Dispatch event to all external listeners.
    const listeners = this._externalListeners[event.type] || [];

    for (let listener of listeners) {
      listener(event);
    }
  }

  _handleCastDisconnect = (event) => {
    const player = this._getActivePlayer();

    // continue playing from where we disconnected
    if (player && event.state === 'PLAYING') {
      player.currentTime = event.currentTime;
      player.play();
    }

    // make sure to update all subscribers with the updated texttracks
    this._dispatchEvent(this._activeType, { type: 'texttrackchange' });
  };

  /**
   * Handle initialized event.
   * @param {String} playerType
   * @param {Object} event
   * @private
   */
  _handleInitialized(playerType, event) {
    this._dispatchEvent(playerType, event);
  }

  /**
   * Handle canplay event.
   * @param {String} playerType
   * @param {Object} event
   * @private
   */
  _handleCanPlay(playerType, event) {
    this._dispatchEvent(playerType, event);

    // Check if we need to seek after a player switch.
    const seek = this._getSeekState(playerType);

    if (seek?.afterPlayerSwitch) {
      this._forceCorrectSeek(seek, 'canplay');
    }
  }

  /**
   * Handle waiting event.
   * @param {String} playerType
   * @param {Object} event
   * @private
   */
  _handleWaiting(playerType, event) {
    if (playerType !== this._activeType) {
      return;
    }

    this._dispatchEvent(playerType, event);

    // if the player is in seeking state, it is expected that it needs to buffer
    if (this._getActivePlayer().seeking || this._buffering) {
      return;
    }

    // set state to buffering, so we can trigger an event buffer_end later
    this._buffering = true;
    this._bufferingTime = Date.now();
  }

  /**
   * Handle playing event.
   * @param {String} playerType
   * @param {Object} event
   * @private
   */
  _handlePlaying(playerType, event) {
    if (playerType !== this._activeType) {
      return;
    }

    this._dispatchEvent(playerType, event);

    if (this._buffering) {
      this._buffering = false;
      this._bufferingTime = undefined;
    }

    // Seek after player switch after playing event, canPlay is not always fired
    const seek = this._getSeekState(playerType);

    if (seek?.afterPlayerSwitch) {
      this._forceCorrectSeek(seek, 'seeked');
    }

    // prevent audio only from being stopped on Android devices. For iOS we use a native media player, for Android
    // we use a foreground/background process in order to keep the JS player active while in background mode.
    if (this._enableBackgroundMode && this._activeType === 'audio') {
      const backgroundIsActive = window.cordova.plugins.backgroundMode.isActive();

      const config = {
        title: 'Debat Direct Audiomodus',
        text: this._debateName,
        icon: 'icon',
        channelName: 'Debat Direct Audiomodus',
        allowClose: false,
        hidden: false,
        visibility: 'public',
      };

      if (!backgroundIsActive) {
        window.cordova.plugins.backgroundMode.setDefaults(config);
        window.cordova.plugins.backgroundMode.enable();
      } else {
        window.cordova.plugins.backgroundMode.configure(config);
      }
    }
  }

  /**
   * Handle seeked event.
   * @param {String} playerType
   * @param {Object} event
   * @private
   */
  _handleSeeked(playerType, event) {
    if (playerType !== this._activeType) {
      return;
    }

    this._dispatchEvent(playerType, event);

    // Check if seek was correct. If not -> second try.
    const seek = this._getSeekState(playerType);

    if (seek && !seek.afterPlayerSwitch) {
      this._forceCorrectSeek(seek, 'seeked');
    }
  }

  /**
   * Handle different player events.
   * @param {String} playerType
   * @param {Object} event
   * @private
   */
  _handlePlayerEvent(playerType, event) {
    if (this._enableBackgroundMode) {
      if (event.type === 'ended' || event.type === 'pause') {
        window.cordova.plugins.backgroundMode.disable();
      }
    }

    this._dispatchEvent(playerType, event);
  }

  /**
   * Handle video track event
   * @param playerType
   * @param event
   * @private
   */
  _handleVideoTrackEvent(playerType, event) {
    if (playerType !== this._activeType) {
      return;
    }

    this._dispatchEvent(playerType, { ...event, type: 'videotrack' });

    event.track.addEventListener('activequalitychanged', this._handleQualityChangedEvent.bind(this, playerType));
    event.track.addEventListener('targetqualitychanged', this._handleTargetQualityChangedEvent.bind(this, playerType));
  }

  /**
   * Handle quality changed event
   * @param playerType
   * @param event
   * @private
   */
  _handleQualityChangedEvent(playerType, event) {
    if (playerType !== this._activeType) {
      return;
    }

    this._dispatchEvent(playerType, event);

    this._currentQuality = event.quality.height;
  }

  /**
   * Handle target quality changed event
   * @param playerType
   * @param event
   * @private
   */
  _handleTargetQualityChangedEvent(playerType, event) {
    if (playerType !== this._activeType) {
      return;
    }

    this._dispatchEvent(playerType, event);
  }

  /**
   * Handle text track add event
   * @param playerType
   * @param event
   * @private
   */
  _handleTextTrackAddEvent(playerType, event) {
    if (playerType !== this._activeType) {
      return;
    }

    this._dispatchEvent(playerType, { ...event, type: 'texttrackadd' });
  }

  /**
   * Handle text track remove event
   * @param playerType
   * @param event
   * @private
   */
  _handleTextTrackRemoveEvent(playerType, event) {
    if (playerType !== this._activeType) {
      return;
    }

    this._dispatchEvent(playerType, { ...event, type: 'texttrackremove' });
  }

  /**
   * Handle text track change event
   * @param playerType
   * @param event
   * @private
   */
  _handleTextTrackChangeEvent(playerType, event) {
    if (playerType !== this._activeType) {
      return;
    }

    if (event.track && event.track.mode === 'showing') {
      trackEvent('debate', 'textTrack', this._debateTitle, 1);
    }

    this._dispatchEvent(playerType, { ...event, type: 'texttrackchange' });
  }

  /**
   * Handle chromecast state change event
   * @param playerType
   * @param event
   * @private
   */
  _handleChromecastStateChange(playerType, event) {
    if (playerType !== this._activeType) {
      return;
    }

    if (event.state === 'connected') {
      trackEvent('debate', 'chromecast', this._debateTitle, 1);
    }

    this._dispatchEvent(playerType, { ...event, type: 'chromecaststatechange' });

    // make sure video or audio players are paused
    if (event.state === 'connected' && this._castPlayer) {
      const activePlayer = this._getActivePlayer();

      if (!activePlayer.paused) activePlayer.pause();
    }
  }

  /**
   * Handle airplay state change event
   * @param playerType
   * @param event
   * @private
   */
  _handleAirplayStateChange(playerType, event) {
    if (playerType !== this._activeType) {
      return;
    }

    if (event.state === 'connected') {
      trackEvent('debate', 'airplay', this._debateTitle, 1);
    }

    this._dispatchEvent(playerType, { ...event, type: 'airplaystatechange' });
  }

  /**
   * Get currently active player.
   * @returns {Object}
   * @private
   */
  _getActivePlayer() {
    return this._players[this._activeType];
  }

  /**
   * Get currently relevant source.
   * @returns {String}
   * @private
   */
  _getActiveSource() {
    return this._sources[this._activeType];
  }

  /**
   * Get correct seek time before player switch.
   * @param {Object} oldPlayer
   * @returns {Number}
   * @private
   */
  _getTargetTimeAtPlayerSwitch(oldPlayer) {
    return oldPlayer.currentTime || 0;
  }

  /**
   * Get seek state for given player type.
   * @param {String} playerType
   * @private
   */
  _getSeekState(playerType) {
    return this._state[playerType].seek;
  }

  /**
   * Reset seek state.
   * @private
   */
  _resetSeekState() {
    const audioSeek = this._state.audio.seek,
      videoSeek = this._state.video.seek;

    if (audioSeek) {
      clearTimeout(audioSeek.timer);
      this._state.audio.seek = null;
    }

    if (videoSeek) {
      clearTimeout(videoSeek.timer);
      this._state.video.seek = null;
    }
  }

  /**
   * Clear former seek state and create a new one.
   * @param {String} seekType
   * @param {Number} seekTime
   * @returns {Object}
   * @private
   */
  _setSeekState(seekType, seekTime) {
    // Clear former seek state.
    this._resetSeekState();

    const seek = {
      seekType,
      seekTime,
      playerType: this._activeType,
      timer: null, // Relevant for standard seek attempts.
      afterPlayerSwitch: false, // Relevant for initial seek after player switch.
    };

    this._state[this._activeType].seek = seek;

    return seek;
  }

  /**
   * Force seek if needed.
   * @param {Object} seek
   * @param {String} eventType
   * @private
   */
  _forceCorrectSeek(seek, eventType) {
    // Do nothing on irrelevant calls.
    if (!seek || seek.playerType !== this._activeType) {
      return;
    }

    // Clear seek state and timer.
    this._state[seek.playerType].seek = null;
    clearTimeout(seek.timer);

    // Check if player has seeked correctly.
    const player = this._players[seek.playerType],
      seekTime = seek.seekTime,
      actualTime = player[seek.seekType];

    if (Math.abs(seekTime - actualTime) > 10) {
      if (import.meta.env.DEV) {
        const debugMessage = ['Force correct seek after event: ' + eventType, 'Attempt to set ' + seek.seekType + ' on ' + seekTime];

        console.warn(debugMessage.join('\n'));
      }

      setTimeout(() => {
        player[seek.seekType] = seekTime;
      }, 15);
    }
  }

  /**
   * Destroy players.
   * @param {NodeList} nativePlayers
   * @private
   */
  _destroyPlayers(nativePlayers) {
    // Destroy theoplayer instances.
    this._destroyTheoPlayers();

    // Even when a theoplayer instance is destroyed, it keeps a reference to the native media element and it does not even clear the src.
    // This results in problems in the IOS Now Playing center.
    // Therefore we also clear the source of the native players used by theoplayer.
    this._destroyNativePlayers(nativePlayers);
  }

  /**
   * Destroy theoplayers.
   * @private
   */
  _destroyTheoPlayers() {
    for (let i = 0, players = this._players, keys = Object.keys(players); i < keys.length; i++) {
      const key = keys[i];

      try {
        this._players[key].destroy();
      } catch (e) {
        if (import.meta.env.DEV) {
          console.error(`Error on ${key} destroy(): `, e);
        }
      }
    }
  }

  /**
   * Destroy native players.
   * @param {NodeList} nativePlayers
   * @private
   */
  _destroyNativePlayers(nativePlayers) {
    for (let i = 0; i < nativePlayers.length; i++) {
      let player = nativePlayers[i];

      try {
        player.pause();
        player.src = '';
      } catch (e) {
        if (import.meta.env.DEV) {
          console.error('Error on native player destroy', e);
        }
      }
    }
  }
}

export default Player;
