import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import Portal from '@debatdirect/core/components/Modal/Portal';
import classNames from 'classnames';

import Button from '../Button/Button';
import Close from '../../icons/Close';

export default class SnackBar extends Component {
  static propTypes = {
    autoClose: PropTypes.bool,
    autoCloseDuration: PropTypes.number,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    wide: PropTypes.bool,
  };

  static defaultProps = {
    autoClose: false,
    wide: false,
    autoCloseDuration: 4000,
  };

  static contextTypes = {
    snackBarProvider: PropTypes.object.isRequired,
  };

  state = {
    offset: 0,
  };

  timeoutId = null;

  constructor(props, context) {
    super(props, context);

    if (props.open) {
      context.snackBarProvider.addSnackBar(this);

      if (this.props.autoClose) {
        this.timeoutId = setTimeout(() => this.props.onClose(), this.props.autoCloseDuration);
      }
    }
  }

  setOffset = (offset) => {
    this.setState({ offset });
  };

  getOffsetHeight = () => {
    return this.refs.snackBar ? this.refs.snackBar.offsetHeight : 0;
  };

  handleCloseClick = () => {
    clearTimeout(this.timeoutId);

    this.props.onClose();
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      // opens, set autoClose
      if (this.props.autoClose) {
        this.timeoutId = setTimeout(() => this.props.onClose(), this.props.autoCloseDuration);
      }

      this.context.snackBarProvider.addSnackBar(this);
    } else if (prevProps.open && !this.props.open) {
      // closes
      clearTimeout(this.timeoutId);

      this.setState({ offset: 0 });
      this.context.snackBarProvider.removeSnackBar(this);
    }
  }

  componentWillUnmount() {
    this.context.snackBarProvider.removeSnackBar(this);

    clearTimeout(this.timeoutId);
  }

  render() {
    const { children, open, wide, 'aria-live': ariaLive } = this.props;

    if (!open) {
      return null;
    }

    const offset = this.state.offset;
    const className = classNames('SnackBar', {
      'SnackBar--wide': wide,
      'SnackBar--rounded': offset > 0,
    });

    return (
      <Portal>
        <div className={className} style={{ bottom: offset }} role="alert" aria-live={ariaLive} ref="snackBar">
          <div className="SnackBar-body">{children}</div>
          <div className="SnackBar-actions">
            <Button className="IconButton SnackBar--close-icon" onClick={this.handleCloseClick}>
              <Close />
            </Button>
          </div>
        </div>
      </Portal>
    );
  }
}
