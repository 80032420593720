import React from 'react';
import ReactDOM from 'react-dom';

import modalManager from '../Modal/ModalManager';

export default class Portal extends React.Component {
  constructor(props) {
    super(props);
    this.container = modalManager.add(this);
  }

  componentWillUnmount() {
    modalManager.remove(this);
    this.container = null;
  }

  // componentDidMount() {
  //   ReactDOM.unstable_renderSubtreeIntoContainer(this, this.props.children, this.container);
  // }
  //
  // componentDidUpdate() {
  //   ReactDOM.unstable_renderSubtreeIntoContainer(this, this.props.children, this.container);
  // }

  render() {
    return ReactDOM.createPortal(this.props.children, this.container);
  }
}
