import React from 'react';
import component from 'omniscient';
import classNames from 'classnames';

/**
 * Renders an icon
 * @param  {Object} props
 * @return {React.Component} The icon component
 */
const Icon = component('Icon', function (props) {
  const { name } = props;
  const width = props.width || 40;
  const height = props.height || 40;
  const className = classNames('Icon', props.className);

  const svgProps = {
    ...props,
    key: name,
    width,
    height,
    className,
    preserveAspectRatio: 'xMidYMid meet',
  };

  const href = `#icon-${name}`;

  /* eslint-disable no-extra-parens */
  return (
    <svg {...svgProps}>
      <use xlinkHref={href} />
    </svg>
  );
  /* eslint-enable no-extra-parens */
});

export default Icon;
