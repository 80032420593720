import immstruct from 'immstruct';

const structure = {
  config: {},
  data: {
    actors: {
      parties: [],
      politicians: [],
    },
    categories: [],
    debates: [],
    documents: [],
    explanations: [],
    faq: [],
    locations: [],
    liveNotifications: [], // notifications from agenda.notifications and websocket
    notifications: {}, // push notifications for mobile devices
    persistent: {},
  },
  auth: {
    user: null,
    initialUrl: null,
  },
  ui: {
    share: null,
    menu: {
      isVisible: false,
      categories: [],
      notifications: [],
    },
    pushNotification: {
      modalVisible: false,
      notification: {},
    },
    notifications: [],
    marker: null,
    error: {},
    infoPanel: {
      isVisible: false,
      component: '',
      props: {},
    },
    search: {
      query: '',
      sidebarOpen: false,
      activeFilters: {
        categoryNames: [],
        fromDate: null,
        toDate: null,
        partyShorthands: [],
        politicianNames: [],
        dossierIds: [],
        status: [],
      },
      sorting: 'relevant',
      searchFrom: 0,
      pageSize: 0,
    },
    settings: {
      hd: false,
      baseUrl: '',
      api: {
        baseUrl: '',
      },
    },
    downloadModal: {
      open: false,
      marker: null,
      fromDate: null,
      toDate: null,
    },
    embedModal: {
      open: false,
    },
    datePicker: {
      open: false,
    },
    prompt: {},
    video: {
      isPlaying: false,
      isSeeking: false,
      isFullScreen: false,
      playRequested: false,
      audioOnly: false,
      controlsVisible: false,
    },
    about: {
      build: '',
    },
    sync: {
      pdt: null,
      delayType: null,
      current: {
        ended: false,
        suspended: false,
        chairman: null,
        speaking: null,
        activeEvent: null,
      },
    },
    downloads: {
      items: [],
    },
    isEmbedded: false,
    seekEvent: null,
    entryParams: null,
    width: document.body.offsetWidth,
  },
};

export default immstruct(structure);
