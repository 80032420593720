import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import debounce from 'lodash.debounce';

import Backdrop from '../Backdrop/Backdrop';
import Portal from '../Modal/Portal';
import modalManager from '../Modal/ModalManager';

const MOBILE_QUERY = window.matchMedia('screen and (max-width: 680px)');

function getOffsetTop(rect, vertical) {
  let offset = 0;

  if (typeof vertical === 'number') {
    offset = vertical;
  } else if (vertical === 'center') {
    offset = rect.height / 2;
  } else if (vertical === 'bottom') {
    offset = rect.height;
  }

  return offset;
}

function getOffsetLeft(rect, horizontal) {
  let offset = 0;

  if (typeof horizontal === 'number') {
    offset = horizontal;
  } else if (horizontal === 'center') {
    offset = rect.width / 2;
  } else if (horizontal === 'right') {
    offset = rect.width;
  }

  return offset;
}

export default class Popover extends React.Component {
  static propTypes = {
    open: PropTypes.bool,
    anchor: PropTypes.object,
    anchorOffset: PropTypes.shape({
      horizontal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      vertical: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    transformOrigin: PropTypes.shape({
      horizontal: PropTypes.oneOf(['auto', 'left', 'center', 'right']),
      vertical: PropTypes.oneOf(['auto', 'top', 'center', 'bottom']),
    }),
    onClose: PropTypes.func,
    initiator: PropTypes.object,
    autoFocus: PropTypes.bool,
  };

  static defaultProps = {
    anchorOffset: {
      horizontal: 'center',
      vertical: 'center',
    },
    transformOrigin: {
      horizontal: 'left',
      vertical: 'bottom',
    },
    autoFocus: true,
  };

  state = {
    fullWidth: MOBILE_QUERY.matches,
  };

  getAnchorOffset() {
    const { anchor, anchorOffset } = this.props;

    if (!anchor) {
      return;
    }

    const anchorRect = anchor.getBoundingClientRect();
    const { horizontal, vertical } = anchorOffset;

    return {
      top: anchorRect.top + getOffsetTop(anchorRect, vertical || 'center'),
      left: anchorRect.left + getOffsetLeft(anchorRect, horizontal || 'center'),
    };
  }

  resetStyle() {
    const { popover } = this.refs;

    if (!popover) {
      return;
    }

    if (popover.getAttribute('style')) {
      popover.removeAttribute('style');
    }
  }

  resizeHandler = () => {
    if (MOBILE_QUERY.matches !== this.state.fullWidth) {
      this.setState({ fullWidth: MOBILE_QUERY.matches });
    }

    if (this.state.fullWidth) {
      this.resetStyle();

      return;
    }

    const anchorOffset = this.getAnchorOffset();

    if (!anchorOffset) {
      return;
    }

    const { top, left } = anchorOffset;

    const transformOrigin = this.props.transformOrigin;

    let horizontalOffset = '0';
    let verticalOffset = '0';

    if (transformOrigin) {
      const windowWidth = Math.min(window.outerWidth, window.innerWidth);
      const windowHeight = Math.min(window.outerHeight, window.innerHeight);
      let { horizontal, vertical } = transformOrigin;

      if (horizontal === 'auto') {
        horizontal = left <= windowWidth / 2 ? 'right' : 'left';
      }

      if (vertical === 'auto') {
        vertical = top <= windowHeight / 2 ? 'bottom' : 'top';
      }

      if (horizontal === 'left') {
        horizontalOffset = '-100%';
      } else if (horizontal === 'center') {
        horizontalOffset = '-50%';
      }

      if (vertical === 'top') {
        verticalOffset = '-100%';
      } else if (vertical === 'center') {
        verticalOffset = '-50%';
      }
    }

    if (this.refs.popover) {
      this.refs.popover.style.top = `${top}px`;
      this.refs.popover.style.left = `${left}px`;

      if (verticalOffset || horizontalOffset) {
        this.refs.popover.style.transform = `translate(${horizontalOffset}, ${verticalOffset})`;
      }
    }
  };

  resizeHandlerDebounced = debounce(this.resizeHandler, 33);

  onEnter = () => {
    this.resizeHandler();

    const ariaControls = document.activeElement?.getAttribute('aria-controls');
    const controlsElement = ariaControls && document.querySelector(`#${ariaControls}`);
    const lastFocus = controlsElement || document.activeElement;

    if (lastFocus && !this.refs.popover.contains(lastFocus)) {
      this._lastFocus = lastFocus;
    }
  };

  onEntered = () => {
    if (!this.props.autoFocus) return;

    const candidates = this.refs.popover.querySelectorAll('div[role="button"], input, a, button');
    const focusable = Array.from(candidates).filter((element) => element.getAttribute('tabindex') !== '-1');
    const focusItem = focusable[0];

    focusItem?.focus({ preventScroll: true });
  };

  onExit = () => {};

  onExited = () => {
    this.resizeHandler();

    // prioritize the initiator and fallback to the last focused element before opening this popover
    if (this.props.initiator) {
      this.props.initiator.focus();
    } else {
      this._lastFocus?.focus();
    }
  };

  componentWillMount() {
    window.addEventListener('resize', this.resizeHandlerDebounced);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandlerDebounced);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      modalManager.addVisibleModal(this);
    } else if (!nextProps.open && this.props.open) {
      modalManager.removeVisibleModal(this);
    }
  }

  render() {
    const popoverClassName = classNames('Popover', this.props.className, {
      'Popover--fullWidth': this.state.fullWidth,
    });

    return (
      <Portal>
        <Backdrop open={this.props.open} onClick={this.props.onClose}>
          <CSSTransition
            onEnter={this.onEnter}
            onEntered={this.onEntered}
            onExit={this.onExit}
            onExited={this.onExited}
            classNames="Popover--animation"
            timeout={190}
            in={this.props.open}
            appear
            mountOnEnter
            unmountOnExit
          >
            <div ref="popover" className={popoverClassName}>
              {this.props.children}
            </div>
          </CSSTransition>
        </Backdrop>
      </Portal>
    );
  }
}
