import React from 'react';
import component from 'omniscient';

import Button from '../../components/Button/Button';
import Icon from '../../components/Icon/Icon';

const LABEL = (
    <span className="Button-label u-centerSelf">
      Bekijk debat <br />
      vanaf begin
    </span>
  ),
  render = function (props) {
    /* eslint-disable no-extra-parens */
    return (
      <Button aria-label="Bekijk debat vanaf begin" {...props}>
        <Icon className="Button-icon" name="toStart" />
        {LABEL}
      </Button>
    );
    /* eslint-enable no-extra-parens */
  };

export default component('DebatePlayBeginButton', render);
