import React from 'react';

import { structure } from '../../../core';
import { appendChildren, createElement } from '../../../utils/lib/DOM';

/**
 * Renderer for a plain event.
 */
class PlainEvent {
  /**
   * Constructor.
   * @param {Object} props
   * @param {String} post - message.
   */
  constructor(props, post) {
    this._props = props;
    this._post = post;
    this._parentId = props.parentId;

    this._eventType = props.event ? props.event.eventType : '';
    this._container = null;
  }

  /**
   * Get container.
   * @returns {HTMLElement|null}
   */
  get container() {
    return this._container;
  }

  /**
   * Render UI.
   * @param {Boolean} isActive
   * @returns {HTMLElement}
   */
  render() {
    this._container = createElement('div', '', { id: `label-${this._parentId}` }, [document.createTextNode(this._post || '')]);

    return this._container;
  }

  /**
   * Clear UI and make rendered nodes garbage collectable.
   */
  clear() {
    this._container = null;
  }
}

/**
 * Renderer for a politician event.
 */
class PoliticianEvent extends PlainEvent {
  /**
   * Constructor.
   * @param {Object} props
   * @param {String} post - message.
   */
  constructor(props, post) {
    super(props, post);

    this._politician = this._resolvePolitician();
    this._parentId = props.parentId;
  }

  /**
   * Render UI.
   * @param {Boolean} isActive
   * @returns {HTMLElement}
   */
  render(isActive) {
    return isActive ? this._renderActive() : this._renderStandard();
  }

  /**
   * Resolve politician data.
   * @returns {Object}
   * @private
   */
  _resolvePolitician() {
    const { event, lookup } = this._props,
      objectId = event.objectId;

    if (!objectId) {
      return {
        politician: null,
        name: 'Onbekende spreker',
        id: '',
        title: '',
        partyShorthand: '',
      };
    }

    const politician = lookup.actors[objectId];

    if (!politician) {
      return {
        politician: null,
        name: 'Onbekende spreker',
        id: '',
        title: '',
        partyShorthand: '',
      };
    }

    return {
      politician: politician,
      name: politician.firstName + ' ' + politician.lastName,
      id: politician.id,
      title: politician.title || '',
      partyShorthand: this._resolvePartyShorthand(politician),
    };
  }

  /**
   * Resolve party shorthand.
   * @param {Object} politician
   * @returns {String}
   * @private
   */
  _resolvePartyShorthand(politician) {
    const { lookup } = this._props;

    if (!politician?.partyId || politician.title !== 'Tweede Kamerlid') {
      return '';
    }

    const party = lookup.parties[politician.partyId];

    if (!party) {
      return '';
    }

    return party.shorthand || '';
  }

  /**
   * Check if event is a chairman event (which is handled a little differently).
   * @returns {Boolean}
   * @private
   */
  _isChairmanEvent() {
    return this._eventType === 'chairman';
  }

  /**
   * Render standard UI.
   * @returns {HTMLElement}
   * @private
   */
  _renderStandard() {
    const text = this._isChairmanEvent() ? 'De voorzitter spreekt' : this._politician.name + ' ' + this._post;

    this._container = createElement('div', '', { id: `label-${this._parentId}` }, [document.createTextNode(text)]);

    return this._container;
  }

  /**
   * Render active UI.
   * @returns {HTMLElement}
   * @private
   */
  _renderActive() {
    this._container = createElement('div', 'u-flex u-row');

    const politicianImage = this._createPoliticianImage();

    if (politicianImage) {
      this._container.appendChild(politicianImage);
    }

    // Create politician link and post.
    const politicianContainer = createElement('div', 'DebateEvents-politician', { id: `label-${this._parentId}` }),
      politicianLink = this._createPoliticianLink(),
      post = createElement('span', '', null, [document.createTextNode(this._post)]);

    appendChildren(politicianContainer, [politicianLink, post]);

    // Create title.
    const politicianTitle = this._createPoliticianTitle();

    // Create container for politician link and title.
    const politicianStatsContainer = createElement('div', 'DebateEvents-politicianStats u-centerSelf');

    appendChildren(politicianStatsContainer, [politicianContainer, politicianTitle]);

    // Create buttons.
    const settingsButton = this._createSettingsButton();

    // Append children to container.
    appendChildren(this._container, [politicianStatsContainer, settingsButton]);

    return this._container;
  }

  /**
   * Create politician image.
   * @returns {HTMLImageElement}
   * @private
   */
  _createPoliticianImage() {
    const politicianId = this._politician.id || '';

    if (!politicianId) {
      return null;
    }

    const image = createElement('img', 'PoliticianImage Speaker-photo u-flex u-opaque', { alt: '' }),
      baseUrl = structure.cursor(['ui', 'settings', 'api', 'baseUrl']).deref();

    image.onload = () => {
      image.onload = null;
      image.onerror = null;

      image.classList.remove('u-opaque');
    };

    image.onerror = () => {
      image.onload = null;
      image.onerror = null;
    };

    image.src = `${baseUrl}/media/actors/politicians/100/100/${politicianId}.jpg`;

    return image;
  }

  /**
   * Create title.
   * @returns {HTMLElement}
   * @private
   */
  _createPoliticianTitle() {
    const div = createElement('div', 'DebateEvents-title'),
      fullTitle = this._createPoliticianTitleText();

    div.appendChild(document.createTextNode(fullTitle));

    return div;
  }

  /**
   * Create politician title text.
   * @returns {String}
   * @private
   */
  _createPoliticianTitleText() {
    const politician = this._politician;

    if (this._isChairmanEvent()) {
      return 'De voorzitter';
    }

    return [politician.title, politician.partyShorthand ? `(${politician.partyShorthand})` : ''].join(' ');
  }

  /**
   * Create link for opening info panel.
   * @returns {HTMLElement}
   * @private
   */
  _createPoliticianLink() {
    const politicianName = this._politician.name,
      politicianId = this._politician.id || '',
      nodeName = politicianId ? 'a' : 'span',
      link = createElement(nodeName, 'Link Link--politician Link--infoPanel ignore-react-onclickoutside', {
        'data-id': politicianId,
        'data-action': 'open-panel',
        href: politicianId ? '#' : null,
      }),
      nameSpan = `<span>${politicianName}</span>`,
      svg = `<svg name="arrow" class="Icon u-inline" width="8.57" height="12" preserveAspectRatio="xMidYMid meet" aria-hidden="true">
                        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-arrow"></use>
                    </svg>`;

    link.innerHTML = `${nameSpan}${svg}`;

    return link;
  }

  /**
   * Create share button.
   * @returns {HTMLElement}
   * @private
   */
  _createSettingsButton() {
    const fragmentTitle = this._isChairmanEvent() ? 'De voorzitter spreekt' : this._politician.name + ' ' + this._post;

    const settingsButton = createElement('button', 'IconButton DebateEvents-button u-centerSelf', {
      'data-id': this._props.parentId,
      'data-fragment-title': fragmentTitle,
      'data-action': 'settings',
      'aria-label': 'Opties',
    });

    settingsButton.innerHTML = `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="SvgIcon" viewBox="0 0 24 24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/>
</svg>
    `;

    return settingsButton;
  }
}

/**
 * Supported UI events.
 */
const events = {
  chairman_change: (props) => new PoliticianEvent(props, ' is tot voorzitter benoemd'),
  chairman: (props) => new PoliticianEvent(props, 'spreekt'),
  chairman_selection: (props) => new PoliticianEvent(props, ' is tot voorzitter benoemd'),
  debate_end: (props) => new PlainEvent(props, 'Het debat is afgelopen'),
  debate_start: (props) => new PlainEvent(props, 'Het debat wordt geopend'),
  interrupter: (props) => new PoliticianEvent(props, ' interrumpeert'),
  speaker: (props) => new PoliticianEvent(props, ' spreekt'),
  speaker_motion_change: (props) => new PoliticianEvent(props, ' dient een motiewijziging in'),
  speaker_motion_present: (props) => new PoliticianEvent(props, ' dient een motie in'),
  speaker_motion_withdraw: (props) => new PoliticianEvent(props, ' trekt een motie terug'),
  suspended: (props) => new PlainEvent(props, 'Het debat is geschorst'),
};

/**
 * Get event instance.
 * @param props
 * @returns {Object|null}
 */
const getEvent = (props) => {
  const eventType = props?.event?.eventType;

  if (!eventType || !events[eventType]) {
    return null;
  }

  return events[eventType](props);
};

/**
 * Check if event is supported.
 * @param event
 * @returns {Boolean}
 */
const isSupportedEvent = (event) => {
  const eventType = event && event.eventType;

  if (!eventType) {
    return false;
  }

  return !!events[eventType];
};

/**
 * Public exports.
 */
export { getEvent, isSupportedEvent };
