import moment from 'moment';

import { stringify } from '../../utils/queryString';

const ElasticServiceFactory = function (reference, { url }) {
  const getService = this.getService;

  /**
   * Do search request
   *
   * @returns {Promise<any>}
   */
  const search = async () => {
    const appVersion = __APP_VERSION__;
    const platform = window.cordova ? 'app' : 'web';

    const queryString = `appVersion=${encodeURIComponent(appVersion)}&platform=${encodeURIComponent(platform)}&totalFormat=new`;

    return fetch(`${url}?${generateQuery(true)}&${queryString}`, {
      method: 'GET',
    }).then((response) => response.json());
  };

  /**
   * Generate the query string with the query and active filters
   * @return {string|string}
   */
  function generateQuery(includeSearchFrom = false) {
    const cursor = reference.cursor().getIn(['ui', 'search']),
      query = cursor.get('query', ''),
      sorting = cursor.get('sorting', ''),
      searchFrom = cursor.get('searchFrom', 0),
      fromDate = cursor.getIn(['activeFilters', 'fromDate'], ''),
      toDate = cursor.getIn(['activeFilters', 'toDate'], ''),
      categoryNames = cursor.getIn(['activeFilters', 'categoryNames']),
      status = cursor.getIn(['activeFilters', 'status']),
      partyShorthands = cursor.getIn(['activeFilters', 'partyShorthands']),
      politicianNames = cursor.getIn(['activeFilters', 'politicianNames']),
      dossierIds = cursor.getIn(['activeFilters', 'dossierIds'], '');

    const search = {
      q: query,
      van: fromDate,
      tot: toDate,
      categorie: categoryNames?.toJS(),
      partij: partyShorthands?.toJS(),
      politici: politicianNames?.toJS(),
      dossier: dossierIds?.toJS(),
      status: status?.toJS(),
      sortering: sorting,
    };

    if (includeSearchFrom) {
      search.vanaf = searchFrom;
    }

    Object.keys(search).forEach((key) => {
      if (search[key] === undefined || search[key] === null || search[key] === '') {
        delete search[key];
      }
    });

    return stringify(search, { arrayFormat: 'index', sort: false });
  }

  function updateQueryParams() {
    const locationQuery = generateQuery();

    getService('router').replace({
      search: locationQuery ? `?${locationQuery}` : '',
      pathname: '/search',
    });
  }

  function getFacet(key, value) {
    const activeFacets = reference.cursor().getIn(['ui', 'search', 'activeFilters']);

    return activeFacets.has(key) && activeFacets.get(key).includes(value);
  }

  function setFacet(key, value, enable) {
    const activeFacets = reference.cursor().getIn(['ui', 'search', 'activeFilters']);

    // key doesn't exist, it's the first active option
    if (!activeFacets.has(key)) {
      if (enable) {
        activeFacets.set(key, [value]);
      }

      return;
    }

    // key exists and it already contains the value
    if (activeFacets.get(key).includes(value)) {
      if (!enable) {
        activeFacets.update(key, (current) => current.filter((val) => val !== value));
      }

      return;
    }

    // key exists but doesn't contain our value, we want to add it
    if (enable) {
      return activeFacets.update(key, (current) => current.concat([value]));
    }
  }

  function toggleFacet(key, value) {
    const current = getFacet(key, value);

    setFacet(key, value, !current);

    // reset search from
    reference.cursor().setIn(['ui', 'search', 'searchFrom'], 0);
  }

  return {
    search: (value) => {
      updateQueryParams();

      return search().then((data) => {
        reference
          .cursor()
          .setIn(['data', 'search', 'results'], data.hits)
          .setIn(['data', 'search', 'hasMore'], data.hits.hits.length < data.hits.total.value)
          .setIn(['data', 'search', 'pageSize'], data.hits.hits.length)
          .setIn(['data', 'search', 'aggregations'], data.aggregations);

        if (value !== '' && window._paq) {
          window._paq.push(['trackSiteSearch', value, false, data.hits.total.value]);
        }
      });
    },
    setSorting: (sorting) => {
      reference.cursor().setIn(['ui', 'search', 'sorting'], sorting);
    },
    getFacet,
    setFacet,
    toggleFacet,
    resetFacets: () => {
      return reference
        .cursor()
        .getIn(['ui', 'search', 'activeFilters'])
        .update('categoryNames', (current) => current.filter(() => false))
        .update('partyShorthands', (current) => current.filter(() => false))
        .update('politicianNames', (current) => current.filter(() => false))
        .update('dossierIds', (current) => current.filter(() => false))
        .update('status', (current) => current.filter(() => false))
        .set('fromDate', null)
        .set('toDate', null);
    },
    resetSuggestions: () => {
      reference.cursor().setIn(['data', 'search', 'suggestions'], []);
    },
    setFromDate: (date) => {
      const cursor = reference.cursor();
      const toDate = cursor.getIn(['ui', 'search', 'activeFilters', 'toDate']);
      const toValue = date ? moment(date).format('YYYY-MM-DD') : null;

      if (toDate && date && moment(toDate).isBefore(date)) {
        cursor.setIn(['ui', 'search', 'activeFilters', 'fromDate'], toValue).setIn(['ui', 'search', 'activeFilters', 'toDate'], toValue);
      } else {
        cursor.setIn(['ui', 'search', 'activeFilters', 'fromDate'], toValue);
      }
    },
    setToDate: (date) => {
      const cursor = reference.cursor();
      const fromDate = cursor.getIn(['ui', 'search', 'activeFilters', 'fromDate']);
      const toValue = date ? moment(date).format('YYYY-MM-DD') : null;

      if (fromDate && date && moment(fromDate).isAfter(date)) {
        cursor.setIn(['ui', 'search', 'activeFilters', 'fromDate'], toValue).setIn(['ui', 'search', 'activeFilters', 'toDate'], toValue);
      } else {
        cursor.setIn(['ui', 'search', 'activeFilters', 'toDate'], toValue);
      }
    },
    loadMore: () => {
      const pageSize = reference.cursor().getIn(['data', 'search', 'pageSize']);

      reference.cursor().updateIn(['ui', 'search', 'searchFrom'], (current) => current + pageSize);
      reference.cursor().getIn(['data', 'search', 'results', 'hits']);

      return search().then((data) => {
        reference
          .cursor()
          .updateIn(['data', 'search', 'results'], (current) => {
            current.hits = current.hits.concat(data.hits.hits);

            return current;
          })
          .setIn(['data', 'search', 'aggregations'], data.aggregations)
          .updateIn(['data', 'search', 'hasMore'], () => {
            return reference.cursor().getIn(['data', 'search', 'results']).hits.length < reference.cursor().getIn(['data', 'search', 'results']).total.value;
          });
      });
    },
    resetSearchFrom: () => {
      reference.cursor().setIn(['ui', 'search', 'searchFrom'], 0);
    },
    getSuggestions: (query) => {
      return fetch(`${url}?q=${encodeURIComponent(query)}&suggesties=1`, { method: 'GET' })
        .then((response) => response.json())
        .then((data) => {
          reference.cursor().setIn(
            ['data', 'search', 'suggestions'],
            data.map((item) => item.text),
          );
        });
    },
  };
};

export default ElasticServiceFactory;
