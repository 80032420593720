import moment from 'moment';
import * as Immutable from 'immutable';
import { v5 as uuid } from 'uuid';

import { clearTmpDirectory, downloadUrl, openFile, fileExists } from '../../utils/fs';
import CreateFragmentConnection, { generateFragmentName } from '../../services/InteractionAdditionsService/util/CreateFragmentConnection';

const DOWNLOADS_NAMESPACE = '8cabbd15-aa4a-40df-be24-0b07ac0db0fe';
const LOCAL_STORAGE_KEY = 'debatdirect-downloads';

export const DOWNLOAD_STATUS = {
  GENERATING: 'generating',
  GENERATED: 'generated',
  DOWNLOADING: 'downloading',
  DOWNLOADED: 'downloaded',
  ERROR: 'error',
  ABORTED: 'aborted',
};

const DownloadServiceFactory = function (reference, { url }) {
  const /**
     * Reference to getService function
     */
    getService = this.getService,
    /**
     * List of connections
     *
     * @type {Object<String, Object>}
     */
    connections = {},
    /**
     * Generate an uuid for an item
     * @param startsAt
     * @param endsAt
     * @param location
     * @param name
     * @param quality
     * @param type
     * @returns {String}
     */
    generateUuid = ({ startsAt, endsAt, location, name, quality, type }) => {
      const seed = [startsAt, endsAt, location, name, quality, type].map((part) => part.toString()).join('');

      return uuid(seed, DOWNLOADS_NAMESPACE);
    },
    /**
     * The service definition
     * @type {Object}
     */
    DownloadService = {
      /**
       * Persist all downloads in the localStorage
       *
       * @private
       */
      _persist: () => {
        const items = reference.cursor().get('items').toJS();

        try {
          window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(items));
        } catch (error) {
          if (import.meta.env.DEV) {
            console.log(error.message);
          }
        }
      },
      /**
       * Generate a download based on a file URL
       */
      generateFileDownload: (name, fileUrl, location, startsAt, endsAt) =>
        new Promise((resolve) => {
          const type = fileUrl.substring(fileUrl.lastIndexOf('.') + 1); // file extension
          const quality = 'unknown';
          const params = {
            location,
            startsAt,
            endsAt,
            quality,
            name,
            type,
          };

          const id = generateUuid(params);
          const item = reference
            .cursor()
            .get('items')
            .find((current) => current.get('id') === id);

          if (item) {
            if (item.get('status') === DOWNLOAD_STATUS.ERROR || item.get('status') === DOWNLOAD_STATUS.ABORTED) {
              DownloadService.remove(item.get('id'));
            } else {
              return resolve(item);
            }
          }

          const startDate = moment(startsAt).format('DD-MM-YYYY');
          const fromTime = moment(startsAt).format('HH:mm:ss');
          const toTime = moment(endsAt).format('HH:mm:ss');
          const label = `${location} | ${startDate} | ${fromTime} - ${toTime} | ${type}`;

          // add the download to the application state
          const addedItem = DownloadService.add({
            url: fileUrl,
            title: name,
            seen: false,
            expires: moment().add(7, 'days').toJSON(),
            id,
            label,
            type,
          });

          if (window.cordova) {
            // Download into filesystem and open file when done
            DownloadService.download(id, true);
          } else {
            // Browser download
            DownloadService.update(id, { status: DOWNLOAD_STATUS.GENERATED });
            DownloadService.open(id);
          }

          resolve(addedItem);
        }),
      /**
       * Generate a fragment
       */
      generateFragment: (name, videoUrl, location, startsAt, endsAt, quality = {}) =>
        new Promise((resolve, reject) => {
          const resolution = quality.resolution || '1280x720';
          const type = quality.type || 'mp4';

          const params = {
            location: videoUrl,
            name: generateFragmentName(name, startsAt, endsAt, resolution, type),
            quality: resolution,
            type,
            startsAt,
            endsAt,
          };

          const id = generateUuid(params);
          const item = reference
            .cursor()
            .get('items')
            .find((current) => current.get('id') === id);

          if (item) {
            if (item.get('status') === DOWNLOAD_STATUS.ERROR || item.get('status') === DOWNLOAD_STATUS.ABORTED) {
              DownloadService.remove(item.get('id'));
            } else {
              return resolve(item);
            }
          }

          // add externalId for later reference
          params.externalId = id;

          const startDate = moment(startsAt).format('DD-MM-YYYY');
          const fromTime = moment(startsAt).format('HH:mm:ss');
          const toTime = moment(endsAt).format('HH:mm:ss');
          const label = `${location} | ${startDate} | ${fromTime} - ${toTime} | ${type} | ${resolution}`;

          // add the download to the application state
          const addedItem = DownloadService.add({
            title: name,
            id,
            label,
            type,
          });

          const connection = new CreateFragmentConnection(params, { url });

          if (!connection) {
            DownloadService.update(params.externalId, {
              status: DOWNLOAD_STATUS.ERROR,
              seen: false,
            });

            return reject('Could not create a connection');
          }

          // store connection for later references
          connections[id] = connection;

          connection.addEventListener('success', (event) => {
            if (event.url) {
              if (window.cordova) {
                // store url in downloads list
                DownloadService.update(params.externalId, {
                  url: event.url,
                  expires: event.expiresAt,
                  seen: false,
                });

                // in the app we want to download the file first
                DownloadService.download(params.externalId, true);
              } else {
                // mark item as generated (user can click the link)
                DownloadService.update(params.externalId, {
                  status: DOWNLOAD_STATUS.GENERATED,
                  expires: event.expiresAt,
                  url: event.url,
                  seen: false,
                });

                // open file automatically
                DownloadService.open(params.externalId);
              }
            } else {
              // mark item as error, we don't have an url
              DownloadService.update(params.externalId, {
                status: DOWNLOAD_STATUS.ERROR,
                expires: moment().add(1, 'days').toJSON(),
                seen: false,
              });
            }

            // close connection
            connection.dispose();

            // remove connection
            delete connections[params.externalId];
          });

          connection.addEventListener('error', () => {
            // update item
            DownloadService.update(params.externalId, {
              status: DOWNLOAD_STATUS.ERROR,
              expires: moment().add(1, 'days').toJSON(),
              seen: false,
            });

            // close connection
            connection.dispose();

            // remove connection
            delete connections[params.externalId];
          });

          // init connection
          connection.init();

          // resolve with added item
          resolve(addedItem);
        }),

      /**
       * Download a download by id.
       *
       * @param {String} id
       * @param {Boolean} [open=false]
       * @returns {Promise}
       */
      download: (id, open = false) =>
        new Promise((resolve, reject) => {
          const item = reference
            .cursor()
            .get('items')
            .find((current) => current.get('id') === id);

          if (!item) {
            return reject(`Item with id ${id} couldn't be found.`);
          }

          if (!window.cordova) {
            return reject('Item can not be downloaded on web');
          }

          const fileName = item.get('url').split('/').pop();

          DownloadService.update(id, {
            status: DOWNLOAD_STATUS.DOWNLOADING,
          });

          return downloadUrl(item.get('url'), fileName)
            .then((filename) => {
              // store downloaded file in downloads list
              DownloadService.update(id, {
                status: DOWNLOAD_STATUS.DOWNLOADED,
                filename,
              });

              // open file automatically if open is set to true
              if (open === true) {
                DownloadService.open(id);
              }
            })
            .then(resolve)
            .catch((error) => {
              DownloadService.update(id, {
                status: DOWNLOAD_STATUS.ERROR,
              });

              reject(error);
            });
        }),

      /**
       * Open a file
       *
       * @param id
       * @returns {Promise}
       */
      open: (id) =>
        new Promise((resolve, reject) => {
          const item = reference
            .cursor()
            .get('items')
            .find((current) => current.get('id') === id);

          if (!item) {
            return reject(`Item with id ${id} couldn't be found.`);
          }

          // open a file using the share dialog in apps
          if (window.cordova) {
            const filename = item.get('filename');

            if (!filename) {
              return reject('Item has not been downloaded');
            }

            return openFile(filename, 'video/mp4').catch((error) => {
              console.log(error);
            });
          }

          return Promise.resolve()
            .then(() => {
              if (item.get('type').includes('mxf')) {
                if (!getService('auth').isLoggedIn()) {
                  getService('prompt').prompt({
                    identifier: 'LoginRequired',
                    dismissable: true,
                    onAccept: () => {
                      getService('router').navigate('/kamerbeelden');
                    },
                    priority: 30,
                  });

                  throw new Error('login required');
                }

                return getService('auth').signVideoUrl(item.get('url'));
              }

              return item.get('url');
            })
            .then((url) => {
              // browser download using a download link
              const a = document.createElement('a');

              a.href = url;
              a.download = url.split('/').pop().split('?')[0];
              a.className = 'no-tracking';
              a.style.display = 'none';
              document.body.appendChild(a);
              a.click();

              document.body.removeChild(a);
              resolve();
            })
            .catch((e) => console.log(e));
        }),

      /**
       * Add a downloaded item
       *
       * @param {Object} item
       * @param {String} item.id
       * @param {String} item.title
       * @param {String} item.label
       * @param {String} item.type
       * @param {String} [item.url=null]
       * @param {String} [item.expires]
       * @param {String} [item.status=generating]
       * @param {Boolean} [item.seen=false]
       */
      add: (item) => {
        const itemToAdd = Immutable.fromJS({
          url: null,
          expires: moment().add(1, 'days').toJSON(),
          status: DOWNLOAD_STATUS.GENERATING,
          seen: false,
          ...item,
        });

        reference.cursor().update('items', (update) => {
          return update.unshift(itemToAdd);
        });

        DownloadService._persist();

        return itemToAdd;
      },

      /**
       * Update item by id
       *
       * @param {String} id
       * @param {Object} changes
       */
      update: (id, changes = {}) => {
        const item = reference
          .cursor()
          .get('items')
          .find((curr) => curr.get('id') === id);

        if (item) {
          item.merge(changes);

          DownloadService._persist();
        }
      },

      /**
       * Remove a download with the given id
       *
       * @param {String} id
       */
      remove: (id) => {
        reference.cursor().update('items', (update) => update.filter((curr) => curr.get('id') !== id));

        DownloadService._persist();

        // close and remove connection if present
        if (connections[id]) {
          connections[id].dispose();
          delete connections[id];
        }
      },

      /**
       * Remove all downloads
       */
      removeAll: () => {
        // close and remove existing connections
        reference
          .cursor()
          .get('items')
          .forEach((item) => {
            const id = item.get('id');

            if (connections[id]) {
              connections[id].dispose();
              delete connections[id];
            }
          });

        // empty directory
        if (window.cordova) {
          clearTmpDirectory().catch((error) => {
            if (import.meta.env.DEV) {
              console.log(error);
            }
          });
        }

        // empty list
        reference.cursor().set('items', Immutable.fromJS([]));

        // persist data
        DownloadService._persist();
      },

      /**
       * Mark all downloads as seen
       */
      markAllAsSeen: () => {
        reference.cursor().update('items', (update) => update.map((item) => item.set('seen', true)));

        DownloadService._persist();
      },

      /**
       * Verify downloaded files on device
       */
      verifyFiles: () => {
        if (!window.cordova) {
          return;
        }

        const items = reference.cursor().get('items').toJS();

        const downloadedItems = items.filter((item) => item.status === DOWNLOAD_STATUS.DOWNLOADED);

        // create promises for all downloaded items
        const promises = downloadedItems.map((item) => {
          // mark as doesn't exist
          if (!item.filename) {
            return Promise.resolve(false);
          }

          // test if file exists
          return fileExists(item.filename).catch(() => false);
        });

        Promise.all(promises).then((results) => {
          downloadedItems.forEach((item, index) => {
            // file exists
            if (true === results[index]) {
              return;
            }

            // file doesn't exist but the generated file is still available, update the status to generated
            if (moment(item.expires).diff(moment(), 'days') > 0) {
              return DownloadService.update(item.id, {
                status: DOWNLOAD_STATUS.GENERATED,
              });
            }

            // file doesn't exist and the generated file is not available, delete the item
            DownloadService.remove(item.id);
          });
        });
      },
    };

  //
  // get items from localStorage
  //
  try {
    const items = window.localStorage.getItem(LOCAL_STORAGE_KEY);

    if (items) {
      const validItems = JSON.parse(items)
        .map((item) => {
          if (item.status === DOWNLOAD_STATUS.GENERATING || item.status === DOWNLOAD_STATUS.DOWNLOADING) {
            item.status = DOWNLOAD_STATUS.ABORTED;
          }

          return item;
        })
        .filter(({ status, expires }) => {
          // if the status is generated and not expired the file can still be downloaded
          const isGenerated = moment(expires).diff(moment(), 'days') > 0 && status === DOWNLOAD_STATUS.GENERATED;

          // the file is downloadable or has been downloaded to the device
          return isGenerated || status === DOWNLOAD_STATUS.DOWNLOADED || status === DOWNLOAD_STATUS.ABORTED;
        });

      // store downloads in the structure, but filter the expired downloads
      reference.cursor().set('items', Immutable.fromJS(validItems));

      // persist filtered items
      window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(validItems));
    }
  } catch (error) {
    if (import.meta.env.DEV) {
      console.log(error.message);
    }
  }

  return DownloadService;
};

export default DownloadServiceFactory;
