import React from 'react';
import PropTypes from 'prop-types';
import { DayPicker } from 'react-day-picker';
import nl from 'date-fns/locale/nl';

import Button from '../Button/Button';
import { Close } from '../../icons';

import 'react-day-picker/dist/style.css';

export default class DatePicker extends React.Component {
  static propTypes = {
    selectedDate: PropTypes.object,
    fromDate: PropTypes.object.isRequired,
    toMonth: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    disabledDays: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  };

  handleDayClick = (day, { selected }) => {
    this.props.onChange(selected ? undefined : day);
  };

  render() {
    return (
      <div id="DatePicker" role="dialog" className="DatePicker" aria-labelledby="date-picker-heading" aria-modal="true">
        <div className="DatePickerHeader">
          <h1 id="date-picker-heading">{this.props.title || 'Kies een datum'}</h1>
          <Button className="IconButton DatePickerIconButton" onClick={this.props.onClose} aria-label="Sluiten">
            <Close />
          </Button>
        </div>
        <DayPicker
          id="DatePicker"
          mode="range"
          selected={this.props.selectedDate}
          disabled={this.props.disabledDays}
          fromDate={this.props.fromDate}
          toMonth={this.props.toMonth}
          defaultMonth={this.props.selectedDate}
          onDayClick={this.handleDayClick}
          locale={nl}
          initialFocus={true}
          labels={{
            labelMonthDropdown: () => 'Maand',
            labelYearDropdown: () => 'Jaar',
            labelNext: () => 'Vorige maand',
            labelPrevious: () => 'Volgende maand',
          }}
          captionLayout="dropdown-buttons"
        />
      </div>
    );
  }
}
