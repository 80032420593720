import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import component from 'omniscient';

const definition = {
  preloadPlaceholder: function () {
    const image = document.createElement('img');

    this.refs.placeholder.classList.add('is-loading');

    setTimeout(() => {
      image.onload = () => {
        if (this.refs.placeholder) {
          this.refs.back.style.backgroundImage = `url(${this.props.placeholder})`;
          this.refs.placeholder.classList.remove('is-loading');

          setTimeout(() => {
            if (!this.refs.front) {
              return;
            }

            this.refs.front.style.backgroundImage = `url(${this.props.placeholder})`;
          }, 200);
        }
      };

      image.src = this.props.placeholder;
    }, 200);
  },

  componentDidMount: function () {
    this.preloadPlaceholder();
  },

  componentWillReceiveProps: function (nextProps) {
    if (nextProps.placeholder !== this.props.placeholder) {
      this.preloadPlaceholder();
    }
  },

  propTypes: {
    placeholder: PropTypes.string,
  },
};

export default component(definition, function ({ className }) {
  const props = {
    className: classNames('Video-placeholder is-loading', className),
    ref: 'placeholder',
  };

  return (
    <div {...props}>
      <div className="Video-placeholderBack" ref="back" />
      <div className="Video-placeholderFront" ref="front" />
    </div>
  );
});
