import moment from 'moment';
import { getDataFromManifest } from '@debatdirect/core/utils/hls';

/**
 * NativeMediaPlayer acts as a bridge between the Player service and the Cordova media plugin.
 *
 * The API has been written to match the THEOplayer API so this a class instance can replace a THEOplayer instance.
 */
class NativeMediaPlayer {
  media = null;
  ready = false;
  error = null;

  _mediaStatus = undefined;
  _currentTime = 0;
  _duration = -1;
  _playbackRate = 1;
  _startProgramDateTime = undefined;

  _seekTo = -1;
  _played = false;

  _interval = null;
  _listeners = {};

  constructor() {
    if (window.Media) {
      this._mediaStatus = window.Media.MEDIA_NONE;
    }
  }

  /**
   * Initializes a new Media instance and dispatches the initial events.
   * @param source
   * @private
   */
  _initializeMedia = (source) => {
    this.media = new window.Media(source, null, this._handleMediaError, this._handleMediaStatus);

    setTimeout(() => {
      this._dispatch({ type: 'initialized' });
      this._dispatch({ type: 'canplay' });
      this.ready = true;
    }, 100);

    this._getStartProgramDate(source);
  };

  /**
   * Get the first program date time from the source
   * @param source
   * @private
   */
  _getStartProgramDate = async (source) => {
    const { firstPdt } = await getDataFromManifest(source);
    this._startProgramDateTime = firstPdt;
  };

  /**
   * Handle errors
   * @param error
   * @private
   */
  _handleMediaError = (error) => {
    this.error = error;

    if (import.meta.env.DEV) {
      console.warn('Error occurred in NativeMediaPlayer', error);
    }
  };

  /**
   * Handle media status updates
   * @param mediaStatus
   * @private
   */
  _handleMediaStatus = (mediaStatus) => {
    this._mediaStatus = mediaStatus;

    clearInterval(this._interval);

    if (mediaStatus === window.Media.MEDIA_RUNNING) {
      this._dispatch({ type: 'play', currentTime: this._currentTime });
      this._played = true;
      this._startTimeUpdateInterval();
    }

    if (mediaStatus === window.Media.MEDIA_PAUSED) {
      this._dispatch({ type: 'timeupdate', currentTime: this._currentTime, duration: this._calculateDuration() });
      this._dispatch({ type: 'pause', currentTime: this._currentTime });
    }

    if (mediaStatus === window.Media.MEDIA_STOPPED) {
      this._dispatch({ type: 'ended', currentTime: this._currentTime, duration: this._calculateDuration() });
    }
  };

  /**
   * Start an internal time update interval which updates the currentTime and duration
   * @private
   */
  _startTimeUpdateInterval() {
    const onTimeUpdate = () => {
      // early return if we don't have a media instance
      // (many crashes occurred due to accessing `this.media.getCurrentPosition`)
      if (!this.media) return;

      // get position and dispatch a timeupdate event
      this.media.getCurrentPosition((position) => {
        this._currentTime = Math.floor(position);
        this._dispatch({ type: 'timeupdate', currentTime: this._currentTime, duration: this._calculateDuration() });
      });

      const duration = this.media.getDuration();

      // if the duration is different, dispatch a durationchange event
      if (duration !== this._duration) {
        this._duration = duration;
        this._dispatch({ type: 'durationchange', duration: this._calculateDuration() });
      }

      // seek to memorized position
      if (this._seekTo > -1) {
        this.currentTime = this._seekTo;
        this._seekTo = -1;
      }
    };

    this._interval = setInterval(onTimeUpdate, 1000);
  }

  /**
   * Dispatch an event
   * @param event
   * @private
   */
  _dispatch(event) {
    if (!this._listeners[event.type]) {
      return;
    }

    setTimeout(() => {
      this._listeners[event.type].forEach((callback) => {
        try {
          callback(event);
        } catch (e) {
          console.log('Error in event callback', e);
        }
      });
    }, 1);
  }

  /**
   * Calculate the duration based on the startProgramDateTime for live streams
   * @return {number}
   * @private
   */
  _calculateDuration = () => {
    return this._duration < 0 && this._startProgramDateTime ? Math.max(0, moment().diff(this._startProgramDateTime, 's') - 35) : this._duration;
  };

  /**
   * Return the (mocked) readyState of the media player
   * @return {number}
   */
  get readyState() {
    return this.ready ? 4 : 1;
  }

  /**
   * Set a new src (this initializes a new Media instance)
   * @param src
   */
  set src(src) {
    this.destroy();
    this._initializeMedia(src);
  }

  /**
   * Set a new source object (this initializes a new Media instance)
   * @param source
   */
  set source(source) {
    this.destroy();

    if (source.sources?.[0]) {
      this._initializeMedia(source.sources[0].src);
    }
  }

  /**
   * Update the playback rate
   * @param playbackRate
   */
  set playbackRate(playbackRate) {
    if (this.media) {
      this.media.setRate(playbackRate);
      this._playbackRate = playbackRate;
      this._dispatch({ type: 'ratechange', playbackRate: this._playbackRate });
    }
  }

  /**
   * Get the playback rate
   * @returns {number}
   */
  get playbackRate() {
    return this._playbackRate;
  }

  /**
   * Update the current time
   * @param currentTime
   */
  set currentTime(currentTime) {
    if (this.media && (this._mediaStatus === window.Media.MEDIA_RUNNING || this._mediaStatus === window.Media.MEDIA_PAUSED)) {
      this._currentTime = currentTime;
      this.media.seekTo(currentTime * 1000);

      this._dispatch({ type: 'seeking', currentTime });

      setTimeout(() => {
        this._dispatch({ type: 'seeked', currentTime });
      }, 50);
    } else {
      this._seekTo = currentTime;
    }
  }

  /**
   * Get the current time
   * @returns {number}
   */
  get currentTime() {
    return this._currentTime;
  }

  /**
   * Get the (virtual) current program date time
   * @returns {Date|null}
   */
  get currentProgramDateTime() {
    if (!this._startProgramDateTime) {
      return null;
    }

    return this._startProgramDateTime.clone().add(this._currentTime, 'seconds').toDate();
  }

  /**
   * Set the current program date time
   * @param currentProgramDateTime
   */
  set currentProgramDateTime(currentProgramDateTime) {
    // not implemented
  }

  /**
   * Get the seekable ranges of the media
   * @returns {{start: (function(): number), end: (function(): number)}}
   */
  get seekable() {
    return {
      length: 1,
      start: () => 0,
      end: () => this._calculateDuration(),
    };
  }

  /**
   * Get duration in seconds. We don't use the calculated duration here since this returns `Infinity`
   * @returns {number}
   */
  get duration() {
    return this._duration < 0 ? Infinity : this._duration;
  }

  /**
   * Get the played attribute
   * @returns {{length: number}}
   */
  get played() {
    return { length: this._played ? 1 : 0 };
  }

  /**
   * Get the paused attribute
   * @returns {boolean}
   */
  get paused() {
    return this._mediaStatus === window.Media.MEDIA_PAUSED;
  }

  /**
   * Destroy the Media instance
   */
  destroy() {
    clearInterval(this._interval);

    if (this.media) {
      this.media.release();
      this.media = null;
    }

    this._mediaStatus = window.Media.MEDIA_NONE;
    this._currentTime = 0;
    this._duration = -1;
  }

  /**
   * Start playback of the media
   */
  play() {
    if (this.media) {
      this.media.play({ playAudioWhenScreenIsLocked: true });
    }
  }

  /**
   * Pause the playback of the media
   */
  pause() {
    if (this.media) {
      this.media.pause();
    }
  }

  /**
   * Mock method
   */
  prepareWithUserAction() {
    // stub
  }

  /**
   * Add an event listener
   * @param type
   * @param callback
   */
  addEventListener(type, callback) {
    this._listeners[type] = this._listeners[type] || [];
    this._listeners[type].push(callback);
  }

  /**
   * Remove an event listener
   * @param type
   * @param callback
   */
  removeEventListener(type, callback) {
    if (this._listeners[type]) {
      this._listeners[type] = this._listeners[type].filter((item) => item !== callback);
    }
  }
}

export default NativeMediaPlayer;
