import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

import { trackEvent } from '../../common';
import Popover from '../Popover/Popover';
import MenuListHeader from '../MenuList/MenuListHeader';
import MenuList from '../MenuList/MenuList';
import SlideTransition from '../Animation/SlideTransition';
import MenuItem from '../MenuList/MenuItem';
import EventListener from '../EventListener/EventListener';
import { Code, Done, Facebook, Link, Twitter, Close, HD, Hearing, Share, SlowMotion } from '../../icons/index';
import { strMaxChars } from '../../utils/formatting';
import { copyToClipboard } from '../../utils/copyToClipboard';

export default class VideoSettingsPopover extends React.Component {
  static contextTypes = {
    getService: PropTypes.func.isRequired,
    getCursor: PropTypes.func.isRequired,
  };

  static propTypes = {
    open: PropTypes.bool,
    debate: PropTypes.object,
    anchor: PropTypes.object,
    anchorOffset: PropTypes.shape({
      vertical: PropTypes.number,
      horizontal: PropTypes.number,
    }),
    transformOrigin: PropTypes.shape({
      horizontal: PropTypes.oneOf(['left', 'center', 'right']),
      vertical: PropTypes.oneOf(['top', 'center', 'bottom']),
    }),
    onClose: PropTypes.func,
  };

  state = {
    firstEnter: true,
    activeIndex: 0,

    qualities: [],
    activeQuality: null,
    targetQuality: null,

    playbackRate: 1,

    audioOnly: false,

    copiedUrl: false,
    copiedUrlFalse: false,

    copiedEmbedCode: false,
    copiedEmbedCodeFailure: false,
  };

  componentWillMount() {
    const { getService } = this.context;
    const video = getService('video');

    video.on('videotrack', this.handleVideoTrack);
    video.on('activequalitychanged', this.handleQualityChange);
    video.on('targetqualitychanged', this.handleQualityChange);
    video.on('ratechange', this.handleRateChange);
    video.on('chromecaststatechange', this.handleChromecastStateChange);
    video.on('airplaystatechange', this.handleAirplayStateChange);
  }

  componentWillUnmount() {
    const { getService } = this.context;
    const video = getService('video');

    video.off('videotrack', this.handleVideoTrack);
    video.off('activequalitychanged', this.handleQualityChange);
    video.off('ratechange', this.handleRateChange);
    video.off('chromecaststatechange', this.handleChromecastStateChange);
    video.off('airplaystatechange', this.handleAirplayStateChange);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.setState({ activeIndex: 0 });
    } else if (!nextProps.open && this.props.open) {
      this.setState({ firstEnter: true });
    }
  }

  getDebateTitle() {
    if (!this.props.debate) return;

    const maxChars = 40;
    const name = this.props.debate.get('name');
    const nameFormatted = strMaxChars(name, maxChars);
    const date = moment(this.props.debate.get('startsAt')).format('YYYY-MM-DD');

    return `${date} / ${nameFormatted}`;
  }

  goToIndex(index) {
    this.setState({
      activeIndex: index,
      firstEnter: false,
    });
  }

  close() {
    this.props.onClose();
  }

  setTargetQuality(quality) {
    const { getService } = this.context;
    const video = getService('video');
    const player = video.getPlayer();

    if (player?.videoTracks.length) {
      player.videoTracks.item(0).targetQuality = quality;
    }

    this.setState({
      targetQuality: quality,
    });

    setTimeout(() => this.close(), 300);
  }

  setPlaybackRate(playbackRate) {
    const { getService } = this.context;
    const video = getService('video');
    const player = video.getPlayer();

    if (player) {
      player.playbackRate = playbackRate;
    }

    setTimeout(() => this.close(), 300);
  }

  toggleAudioOnly() {
    const { getCursor, getService } = this.context;
    const videoService = getService('video');
    const cursor = getCursor(['ui', 'video']);

    if (videoService.canSwitchPlayers()) {
      videoService.getPlayer().setActivePlayerType(cursor.get('audioOnly') ? 'video' : 'audio');

      cursor.update('audioOnly', (current) => {
        if (!current) {
          trackEvent('debate', 'audioOnly', this.getDebateTitle(), 1);
        }
        this.setState({ audioOnly: !current });
        this.handleQualityChange();

        return !current;
      });
    }

    setTimeout(() => this.close(), 300);
  }

  shareFacebook() {
    const { getService } = this.context;
    const href = getService('share').createFacebookURL(this.props.debate);

    if (href) {
      trackEvent('debate', 'shareFacebook', this.getDebateTitle(), 1);
      window.open(href, '_blank');
    }
  }

  shareTwitter() {
    const { getService } = this.context;
    const href = getService('share').createTwitterURL(this.props.debate);

    if (href) {
      trackEvent('debate', 'shareTwitter', this.getDebateTitle(), 1);
      window.open(href, '_blank');
    }
  }

  copyUrl() {
    const url = this.context.getService('share').createShareURL(this.props.debate);
    const copied = copyToClipboard(url);

    trackEvent('debate', 'copyUrl', this.getDebateTitle(), 1);

    this.setState({
      copiedUrl: copied,
      copiedUrlFailure: !copied,
      copiedEmbedCode: false,
      copiedEmbedCodeFailure: false,
    });

    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(
      () =>
        this.setState({
          copiedUrl: false,
          copiedUrlFailure: false,
        }),
      2000,
    );
  }

  copyEmbedCode() {
    const url = this.context.getService('share').createEmbedCode(this.props.debate);
    const copied = copyToClipboard(url);

    trackEvent('debate', 'copyEmbed', this.getDebateTitle(), 1);

    this.setState({
      copiedEmbedCode: copied,
      copiedEmbedCodeFailure: !copied,
      copiedUrl: false,
      copiedUrlFailure: false,
    });

    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(
      () =>
        this.setState({
          copiedEmbedCode: false,
          copiedEmbedCodeFailure: false,
        }),
      2000,
    );
  }

  filterQualities(qualities) {
    return qualities.filter((a) => !isNaN(a.height) && a.height > 0).sort((a, b) => b.height - a.height);
  }

  handleVideoTrack = ({ track }) => {
    this.setState({
      qualities: this.filterQualities(track.qualities),
      activeQuality: track.activeQuality,
      targetQuality: track.targetQuality,
    });
  };

  handleQualityChange = () => {
    const { getService } = this.context;
    const player = getService('video').getPlayer();

    const qualities = this.filterQualities(player.qualities);
    const activeQuality = player.activeQuality && player.activeQuality.width > 0 ? player.activeQuality : null;

    this.setState({
      qualities,
      activeQuality,
      targetQuality: player.targetQuality,
    });
  };

  handleRateChange = ({ playbackRate }) => {
    this.setState({
      playbackRate,
    });
  };

  handleChromecastStateChange = ({ state }) => {
    this.setState({ chromecastCasting: state === 'connected' || state === 'connecting' });
  };

  handleAirplayStateChange = ({ state }) => {
    this.setState({ airplayCasting: state === 'connected' });
  };

  handleKeyUpEvent = (event) => {
    if (event.which === 27) {
      this.close();
    }
  };

  renderCopyUrlMenuItem() {
    const { copiedUrl, copiedUrlFailure } = this.state;

    if (copiedUrl) {
      return <MenuItem label="Link is gekopieerd" icon={<Done />} active />;
    }

    if (copiedUrlFailure) {
      return <MenuItem label="Kopiëren mislukt" icon={<Close style={{ color: 'red' }} />} />;
    }

    return <MenuItem label="Link kopiëren" icon={<Link />} onClick={() => this.copyUrl()} />;
  }

  renderCopyEmbedCodeMenuItem() {
    const { copiedEmbedCode, copiedEmbedCodeFailure } = this.state;

    if (copiedEmbedCode) {
      return <MenuItem label="Embed code gekopieerd" icon={<Done />} active />;
    }

    if (copiedEmbedCodeFailure) {
      return <MenuItem label="Kopiëren mislukt" icon={<Close style={{ color: 'red' }} />} />;
    }

    return <MenuItem label="Embed code kopiëren" icon={<Code />} onClick={() => this.copyEmbedCode()} />;
  }

  renderShareMenu() {
    return (
      <SlideTransition in={this.state.activeIndex === 1} key="share" className="SlideRight">
        <MenuListHeader onClick={() => this.goToIndex(0)} label="Debat delen" />
        <hr className="u-m0" />
        <MenuList>
          <MenuItem label="Twitter" icon={<Twitter />} onClick={() => this.shareTwitter()} />
          <MenuItem label="Facebook" icon={<Facebook />} onClick={() => this.shareFacebook()} />
          {this.renderCopyUrlMenuItem()}
          {this.renderCopyEmbedCodeMenuItem()}
        </MenuList>
      </SlideTransition>
    );
  }

  renderQualityMenu() {
    const qualityIcon = (quality) => {
      if ((!quality && !this.state.targetQuality) || this.state.targetQuality === quality) {
        return <Done />;
      }

      return null;
    };

    return (
      <SlideTransition in={this.state.activeIndex === 2} key="quality" className="SlideRight">
        <MenuListHeader onClick={() => this.goToIndex(0)} label="Kwaliteit" />
        <hr className="u-m0" />
        <MenuList>
          {this.state.qualities.map((quality) => (
            <MenuItem
              key={quality.height}
              label={`${quality.height}p`}
              icon={qualityIcon(quality)}
              iconAfter={quality.height >= 720 ? <HD /> : null}
              onClick={() => this.setTargetQuality(quality)}
            />
          ))}
          <MenuItem label="Automatisch" icon={qualityIcon()} onClick={() => this.setTargetQuality(null)} />
        </MenuList>
      </SlideTransition>
    );
  }

  renderPlaybackRateMenuItem(targetPlaybackRate) {
    const { playbackRate } = this.state;
    const label = targetPlaybackRate === 1 ? 'Normaal' : targetPlaybackRate.toString();
    const icon = playbackRate === targetPlaybackRate ? <Done /> : null;

    return <MenuItem label={label} icon={icon} onClick={() => this.setPlaybackRate(targetPlaybackRate)} />;
  }

  renderPlaybackRateMenu() {
    return (
      <SlideTransition in={this.state.activeIndex === 3} key="playbackRate" className="SlideRight">
        <MenuListHeader onClick={() => this.goToIndex(0)} label="Afspeelsnelheid" />
        <hr className="u-m0" />
        <MenuList>
          {this.renderPlaybackRateMenuItem(0.75)}
          {this.renderPlaybackRateMenuItem(1)}
          {this.renderPlaybackRateMenuItem(1.25)}
          {this.renderPlaybackRateMenuItem(1.5)}
          {this.renderPlaybackRateMenuItem(1.75)}
          {this.renderPlaybackRateMenuItem(2)}
        </MenuList>
      </SlideTransition>
    );
  }

  renderQualityBadge() {
    const { activeQuality, targetQuality } = this.state;

    if (!targetQuality) {
      return 'automatisch ' + (activeQuality ? `(${activeQuality.height}p)` : '');
    }

    return `${targetQuality.height}p`;
  }

  renderPlaybackRateBadge() {
    const { playbackRate } = this.state;

    return playbackRate === 1 ? 'normaal' : playbackRate.toString();
  }

  shareDebate() {
    // open native share in Cordova app
    if (window.cordova && typeof navigator.share === 'function') {
      const shareService = this.context.getService('share');

      try {
        navigator.share(shareService.createShareURL(this.props.debate), 'Debat Direct');
        trackEvent('debate', 'shareNative', this.getDebateTitle(), 1);
      } catch (error) {
        if (import.meta.env.MODE !== 'production') {
          console.log(error);
        }
      }

      // close popover
      return this.close();
    }

    this.goToIndex(1);
  }

  renderMainMenu() {
    const casting = this.state.chromecastCasting || this.state.airplayCasting;
    const iOS12 = window.cordova && window.cordova.platformId.toLowerCase() === 'ios' && parseInt(window.device.version) <= 12;
    const audioUrl = this.props.debate?.getIn(['video', 'catchup', 'audioUrl']);
    const enabledAudio = !!audioUrl || !this.props.debate; // When there is no debate, we assume the room is embedded, and live video usually has audio

    return (
      <SlideTransition
        in={this.state.activeIndex === 0}
        appear={this.state.firstEnter}
        timeout={this.state.firstEnter ? 0 : 300}
        key="main"
        className={this.state.firstEnter ? '' : 'SlideLeft'}
      >
        <MenuList>
          <MenuItem icon={<Share />} label="Debat delen" disabled={!this.props.debate} onClick={() => this.shareDebate()} />
          {!iOS12 ? (
            <MenuItem
              icon={<Hearing />}
              label={
                casting ? (
                  <span>
                    Audiomodus
                    <br />
                    <small>Schakel eerst casting uit</small>
                  </span>
                ) : (
                  'Audiomodus'
                )
              }
              badge={<span>{this.state.audioOnly ? 'aan' : 'uit'}</span>}
              onClick={() => this.toggleAudioOnly()}
              disabled={casting || !enabledAudio}
            />
          ) : null}
          {this.state.qualities.length > 0 ? (
            <MenuItem icon={<HD />} label="Kwaliteit" badge={this.renderQualityBadge()} onClick={() => this.goToIndex(2)} />
          ) : null}
          <MenuItem icon={<SlowMotion />} label="Afspeelsnelheid" badge={this.renderPlaybackRateBadge()} onClick={() => this.goToIndex(3)} />
        </MenuList>
      </SlideTransition>
    );
  }

  render() {
    return (
      <Popover
        open={this.props.open}
        anchor={this.props.anchor}
        anchorOffset={this.props.anchorOffset}
        transformOrigin={this.props.transformOrigin}
        onClose={() => this.close()}
      >
        <EventListener event="keyup" callback={this.handleKeyUpEvent} />
        <div className="MenuList--videosettings">
          {this.renderShareMenu()}
          {this.renderQualityMenu()}
          {this.renderPlaybackRateMenu()}
          {this.renderMainMenu()}
        </div>
        <hr className="u-m0" />
        <MenuList>
          <MenuItem className="MenuItem--footer" icon={<Close />} label="Annuleren" onClick={() => this.close()} />
        </MenuList>
      </Popover>
    );
  }
}
