import PropTypes from 'prop-types';
import React from 'react';
import { withDefaults } from 'omniscient';

import OnClickOutside from '../../OnClickOutside/OnClickOutside';
import InternalLink from '../../../components/InternalLink/InternalLink';
import Prompt from '../../Prompt/Prompt';
import Button from '../../../components/Button/Button';

const component = withDefaults({ cursorField: 'cursor' }),
  definition = {
    contextTypes: {
      getService: PropTypes.func.isRequired,
      pathTo: PropTypes.func.isRequired,
    },

    mixins: [OnClickOutside],

    /**
     * Close prompt with given status
     * @param {string} status
     */
    closePrompt: function (status) {
      const { getService } = this.context;

      getService('prompt').dismiss(status, 'GenericError');
    },

    /**
     * Close button handler
     */
    closePromptHandler: function () {
      this.closePrompt('ok');
    },

    /**
     * Click outside handler
     */
    handleClickOutside: function () {
      this.closePrompt('cancel');
    },
  },
  render = function () {
    const { pathTo } = this.context;

    return (
      <Prompt
        alignment="centerCenter"
        visible={true}
        role="alertdialog"
        aria-labelledby="prompt-title"
        aria-describedby="prompt-description"
        aria-live="assertive"
      >
        <div>
          <h2 className="Heading Prompt-heading" id="prompt-title">
            Foutmelding
          </h2>
          <p className="Text Prompt-description" id="prompt-description">
            Er is iets mis gegaan.
          </p>
        </div>
        <div className="Prompt-actions">
          <Button className="Prompt-action" onClick={this.closePromptHandler}>
            <div className="Button-label">Terug</div>
          </Button>
          <InternalLink href={pathTo('support')} className="Prompt-action Prompt-action--primary">
            Naar support
          </InternalLink>
        </div>
      </Prompt>
    );
  };

export default component('GenericError', definition, render);
