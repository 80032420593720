import moment from 'moment';

import persist from '../../utils/persist';

const LOCAL_STORAGE_KEY = 'debatdirect-verderkijken';
const DAYS_STORED = 30;

const /**
   * Constructs ContinueWatchingService
   * @param  {Reference} reference  Reference to the ui.sync cursor
   * @return {Object}               The ContinueWatching service
   */
  ContinueWatchingServiceFactory = function (reference) {
    const keepFromDate = moment().subtract(DAYS_STORED, 'days');
    const recentlyWatchedFilter = (debateWatched) => moment(debateWatched.watchMoment).isAfter(keepFromDate);

    const watchHistoryStored = JSON.parse(persist.getItem(LOCAL_STORAGE_KEY));
    const watchHistory = Array.isArray(watchHistoryStored) ? watchHistoryStored.filter(recentlyWatchedFilter) : [];

    let debateId;
    const setDebateId = (id) => {
      debateId = id;
    };
    const wasStoredBeforeSession = (id) => !!watchHistory.find((debateWatched) => debateWatched.debateId === id);
    const getPdtFromWatchHistory = (id) => {
      const debateWatched = watchHistory.find((debateWatched) => debateWatched.debateId === id);

      return debateWatched ? debateWatched.pdt : null;
    };

    // Gets called when debate unmounts
    const saveDebate = () => {
      const pdt = reference.cursor(['ui', 'sync']).get('pdt');
      const watchMoment = moment().toISOString();

      if (pdt && debateId) {
        const debateWatched = { pdt, debateId, watchMoment };
        const foundIndex = watchHistory.findIndex((debate) => debate.debateId === debateId);

        if (foundIndex >= 0) {
          watchHistory[foundIndex] = debateWatched;
        } else {
          watchHistory.push(debateWatched);
        }
      }
      persist.setItem(LOCAL_STORAGE_KEY, JSON.stringify(watchHistory));
    };

    window.addEventListener('beforeunload', () => saveDebate());
    window.addEventListener('visibilitychange', () => document.visibilityState === 'hidden' && saveDebate());

    return {
      setDebateId,
      wasStoredBeforeSession,
      getPdtFromWatchHistory,
      saveDebate,
    };
  };

export default ContinueWatchingServiceFactory;
