import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import component from 'omniscient';
import classNames from 'classnames';
import observer from 'omnipotent/decorator/observer';
import debounce from 'lodash.debounce';

import Button from '../../components/Button/Button';
import Icon from '../../components/Icon/Icon';
import structure from '../../core/lib/structure/structure';
import throttle from '../../utils/throttle';

import Controlbar from './Controlbar';

/**
 * Assistant for determining if touchstart happens on control element.
 */
const isDescendant = (parent, child) => parent?.contains(child);

const hittingControlElement = (function () {
  const controlNodes = {
    a: true,
    button: true,
  };

  return (evt) => {
    // Check if event occurred on a control element.
    let element = evt.target.nodeType === 3 ? evt.target.parentNode : evt.target;

    while (element && element.nodeType === 1) {
      if (element.getAttribute('data-control')) {
        return true;
      }

      if (Object.prototype.hasOwnProperty.call(controlNodes, element.nodeName.toLowerCase())) {
        return true;
      }

      element = element.parentNode;
    }

    // Default.
    return false;
  };
})();

/**
 * Get coords object from native touch event.
 *
 * @param {Event} event
 * @returns {{x: (number), y: (number)}}
 */
const getCoords = function (event) {
  const originalEvent = event.originalEvent || event;
  const touches = originalEvent.touches?.length ? originalEvent.touches : [originalEvent];
  const e = originalEvent.changedTouches?.[0] || touches[0];

  // mouse event
  if (event.type.indexOf('touch') === -1) {
    return {
      x: event.clientX,
      y: event.clientY,
    };
  }

  // touch event
  return {
    x: e.clientX,
    y: e.clientY,
  };
};

const mobilePortraitMediaQuery = window.matchMedia('screen and (max-width: 600px) and (orientation: portrait)');
const mobileLandscapeMediaQuery = window.matchMedia('screen and (max-height: 600px) and (orientation: landscape)');

const componentDefinition = {
  touchStartTime: 0,
  touchStartCoords: null,

  getInitialState: () => ({
    visible: true,
    isPhone: false,
    autoHide: true,
  }),

  componentWillMount: function () {
    this.handleTouchMoveThrottled = throttle(this.handleTouchMove, 300);
    this.handleMouseEventDebounced = debounce(this.handleMouseEvent, 300, { leading: true });
  },

  componentDidMount: function () {
    const { getService } = this.context;
    const appView = document.querySelector('.AppView');
    const videoService = getService('video');

    const isTouchDevice = window.matchMedia('(pointer: coarse)').matches;

    if (isTouchDevice) {
      appView.addEventListener('touchstart', this.handleTouchStart);
      appView.addEventListener('touchmove', this.handleTouchMoveThrottled);
      appView.addEventListener('touchend', this.handleTouchEnd);
    } else {
      appView.addEventListener('mousemove', this.handleMouseEventDebounced);
      appView.addEventListener('mouseleave', this.handleMouseEventDebounced);
      document.addEventListener('keyup', this.handleMouseEvent);
    }

    this.state.isPhone = getService('platform').isPhone();

    videoService.on('chromecaststatechange', this.handleChromecastStateChange);
    videoService.on('airplaystatechange', this.handleAirplayStateChange);
    videoService.on('texttrackchange', this.handleTextTrackChange);

    const player = videoService.getPlayer();

    if (player) {
      this.setState({
        chromecastCasting: player.cast.chromecast.casting,
        airplayCasting: player.cast.airplay.casting,
      });
    }
  },

  componentWillUnmount: function () {
    const appView = document.querySelector('.AppView');
    const video = this.context.getService('video');

    appView.removeEventListener('mousemove', this.handleMouseEventDebounced);
    appView.removeEventListener('mouseleave', this.handleMouseEventDebounced);

    appView.removeEventListener('touchstart', this.handleTouchStart);
    appView.removeEventListener('touchmove', this.handleTouchMoveThrottled);
    appView.removeEventListener('touchend', this.handleTouchEnd);

    document.removeEventListener('keyup', this.handleMouseEvent);

    // restore
    clearTimeout(this.hideTimeout);

    this.handleMouseEventDebounced.cancel();
    this.handleTouchMoveThrottled.cancel();

    this.setOverlayingContent(true);
    this.setVisibility(false);

    video.off('chromecaststatechange', this.handleChromecastStateChange);
    video.off('airplaystatechange', this.handleAirplayClick);
    video.off('texttrackchange', this.handleTextTrackChange);
  },

  componentDidUpdate: function (prevProps) {
    const isFullScreen = this.props.video.get('isFullScreen'),
      isPlaying = this.props.video.get('isPlaying'),
      audioOnly = this.props.video.get('audioOnly'),
      prevIsPlaying = prevProps.video.get('isPlaying'),
      prevAudioOnly = prevProps.video.get('audioOnly');

    if (isPlaying !== prevIsPlaying || audioOnly !== prevAudioOnly) {
      this.setVisibility(true);
      this.setOverlayingContent(true);

      clearTimeout(this.hideTimeout);

      if (isPlaying && !audioOnly) {
        this.setHideTimeout();
      }
    }

    if (!isFullScreen) {
      this.setOverlayingContent(true);
    }
  },

  handleChromecastStateChange: function (event) {
    this.setState({
      chromecastCasting: event.state === 'connected',
    });
  },

  handleAirplayStateChange: function (event) {
    this.setState({
      airplayCasting: event.state === 'connected',
    });
  },

  handleTextTrackChange: function () {
    this.setVisibility(true);
    this.setHideTimeout();
  },

  handleTouchStart: function (evt) {
    const video = this.props.video;

    // Check current video state.
    if (!video.get('isPlaying') || video.get('audioOnly')) {
      return;
    }

    this.touchStartTime = Date.now();
    this.touchStartCoords = getCoords(evt);
  },

  handleTouchMove: function (evt) {
    const video = this.props.video;

    // Check current video state.
    if (video.get('controlsVisible') && (!video.get('isPlaying') || video.get('audioOnly'))) {
      return;
    }

    if (mobilePortraitMediaQuery.matches) {
      if (!isDescendant(this.refs.videoControls, evt.target)) {
        return;
      }
    }

    if (video.get('controlsVisible')) {
      clearTimeout(this.hideTimeout);
    }
  },

  handleTouchEnd: function (evt) {
    const video = this.props.video;

    // Check current video state.
    if (!video.get('isPlaying') || video.get('audioOnly')) {
      return;
    }

    // Check if touch event happens on a control.
    if (hittingControlElement(evt)) {
      this.setHideTimeout();

      return;
    }

    const $ = document.querySelector.bind(document);

    if (mobilePortraitMediaQuery.matches) {
      if (!isDescendant(this.refs.videoControls, evt.target)) {
        return;
      }

      if (evt.target.classList.contains('VideoControls-clickArea')) {
        evt.preventDefault();
      }
    } else if (mobileLandscapeMediaQuery.matches) {
      if (evt.target.classList.contains('VideoControls-clickArea')) {
        evt.preventDefault();
      }
    } else if (isDescendant($('.Main-wrapper'), evt.target) || isDescendant($('.Layer--menu'), evt.target) || isDescendant($('.App-navBar'), evt.target)) {
      return;
    }

    const coords = getCoords(evt);

    // most likely a swipe or scroll gesture
    if (Math.abs(coords.x - this.touchStartCoords.x) > 10 || Math.abs(coords.y - this.touchStartCoords.y) > 10) {
      return;
    }

    // user tapped, toggle display
    if (Date.now() - this.touchStartTime < 150) {
      // Toggle visibility of nav and control bar.
      if (video.get('controlsVisible')) {
        this.setVisibility(false);
        this.setOverlayingContent(false);
        clearTimeout(this.hideTimeout);
      } else {
        this.setVisibility(true);
        this.setOverlayingContent(true);
        this.setHideTimeout();
      }

      return;
    }

    if (video.get('controlsVisible')) {
      this.setHideTimeout();
    }
  },

  handleMouseEvent: function (evt) {
    const video = this.props.video;

    // Check current video state.
    if (!video.get('isPlaying') && video.get('controlsVisible')) {
      return;
    }

    // Show nav and control bar.
    this.setVisibility(true);
    this.setOverlayingContent(true);

    // don't hide if we hover the controlbar
    if (isDescendant(ReactDOM.findDOMNode(this.refs.controlbar), evt.target)) {
      clearTimeout(this.hideTimeout);

      return;
    }

    // dont hide if we hover politician marker
    if (isDescendant(document.querySelector('.Marker.Politician'), evt.target)) {
      clearTimeout(this.hideTimeout);

      return;
    }

    // We hide after 5 seconds of inactivity.
    this.setHideTimeout();
  },

  setVisibility: function (visible) {
    this.context.getCursor(['ui', 'video']).update('controlsVisible', () => visible);

    const { videoControls } = this.refs,
      method = visible ? 'remove' : 'add',
      activationAttribute = 'data-user-activated',
      marker = document.querySelector('.Marker.Politician');

    // Use visibility:hidden so position bar measurements can still take place.
    if (videoControls) {
      videoControls.classList[method]('hidden');
    }

    // this should be moved!
    if (marker) {
      marker.classList[method]('u-hidden'); // Use display:none.

      if (visible) {
        marker.setAttribute(activationAttribute, 'true');
      } else {
        marker.removeAttribute(activationAttribute);
      }
    }
  },

  // this should be moved!
  setOverlayingContent: function (overlaysVisible) {
    // On phones this is managed by CSS.
    if (this.state.isPhone) {
      return;
    }

    const method = overlaysVisible || !this.props.video.get('isFullScreen') ? 'remove' : 'add',
      navBar = document.querySelector('.Layer--info .NavBar'),
      infoPanel = document.querySelector('.Layer--info .Layer-infoPanel'),
      route = document.querySelector('.Layer--info .DebateRoute');

    if (navBar) navBar.classList[method]('u-invisible');
    if (infoPanel) infoPanel.classList[method]('u-invisible');
    if (route) route.classList[method]('u-invisible');
  },

  setHideTimeout: function () {
    clearTimeout(this.hideTimeout);

    // don't set hide timeout when the audio only stream is enabled
    if (this.props.video.get('audioOnly')) {
      return;
    }

    // don't hide the controls when the user is navigating with the tab key
    const isUserTabbing = document.body.classList.contains('user-is-tabbing');

    if (isUserTabbing) {
      return;
    }

    this.hideTimeout = setTimeout(() => {
      const video = this.props.video;

      // Check current video state.
      if (!video.get('isPlaying') && !video.get('playRequested')) {
        return;
      }

      // Always show controls when we are casting
      if (this.state.chromecastCasting || this.state.airplayCasting) {
        return;
      }

      // Always show controls when infopanel is open
      if (this.context.getCursor().getIn(['ui', 'infoPanel', 'isVisible'])) {
        return;
      }

      this.setVisibility(false);
      this.setOverlayingContent(false);
    }, 3000);
  },

  handlePlayPauseClick: function () {
    const videoService = this.context.getService('video');

    if (this.props.video.get('isPlaying')) {
      videoService.pause();
    } else {
      videoService.play();
    }
  },

  contextTypes: {
    getCursor: PropTypes.func.isRequired,
    getService: PropTypes.func.isRequired,
  },
};

const render = function ({ video, debate }) {
  const videoIsPlaying = video.get('isPlaying');
  const isEmbedded = this.context.getCursor(['ui', 'isEmbedded']).deref();
  const icon = videoIsPlaying ? 'pause' : 'playArrow';
  const audioOnly = video.get('audioOnly');
  const loading = video.get('isSeeking', false);
  const chromecastCasting = this.state.chromecastCasting;

  return (
    <div
      className={classNames('VideoControls', { 'VideoControls-infoOverlay': chromecastCasting || audioOnly })}
      ref="videoControls"
      id="video-controls"
      tabIndex={-1}
    >
      {videoIsPlaying && !isEmbedded && (
        <Button className="VideoControls-closeButton" aria-label="Sluit videospeler" onClick={() => this.handlePlayPauseClick()}>
          <Icon name="close" aria-hidden="true" className="Button-icon" width="40" height="40" />
        </Button>
      )}
      {loading ? (
        <div className="VideoControls-loading">
          <svg viewBox="0 0 50 50" className="Icon Loading u-inline">
            <circle cx="25" cy="25" r="20" fill="none" strokeWidth="3.6" />
          </svg>
        </div>
      ) : null}
      <div className="VideoControls-box">
        {chromecastCasting ? (
          <div className="VideoControls-info">
            <div className="VideoControls-info-title">{audioOnly ? 'Chromecast en Audiomodus actief' : 'Chromecast actief'}</div>
            <div className="VideoControls-info-subtitle">Het debat speelt af op uw Chromecast-apparaat.</div>
          </div>
        ) : audioOnly ? (
          <div className="VideoControls-info">
            <div className="VideoControls-info-title">Audiomodus actief</div>
            <div className="VideoControls-info-subtitle">U kunt de app nu op de achtergrond laten afspelen.</div>
          </div>
        ) : null}
        <div>
          <Button
            className="VideoControls-playButton"
            onClick={this.handlePlayPauseClick}
            id="largePlayButton"
            aria-label={videoIsPlaying ? 'Video pauzeren' : 'Video starten'}
          >
            {loading ? (
              <svg viewBox="0 0 50 50" className="Icon Loading u-inline">
                <circle cx="25" cy="25" r="20" fill="none" strokeWidth="3.6" />
              </svg>
            ) : (
              <Icon name={icon} className="u-inline" />
            )}
          </Button>
        </div>
      </div>
      <Controlbar debate={debate} audioOnly={audioOnly} ref="controlbar" />
      <div className="VideoControls-clickArea" />
    </div>
  );
};

export default observer(
  structure,
  {
    video: ['ui', 'video'],
    marker: ['ui', 'marker'],
  },
  component('VideoControls', componentDefinition, render),
);
