import PropTypes from 'prop-types';
import React from 'react';
import component from 'omniscient';
import Immutable from 'immutable';
import { createSelectorCreator, defaultMemoize } from 'reselect';

import ScrollView from '../../components/ScrollView/SmartScrollView';
import { byId } from '../../predicates';
import InfiniteScroll from '../../components/InfiniteScroll/InfiniteScroll';

import DebateDocumentsList from './DebateDocumentsList';

const createSelector = createSelectorCreator(defaultMemoize, Immutable.is),
  documentsSelector = createSelector(
    (state) => state.documents,
    (state) => state.documentIds,
    (documents, documentIds) => {
      return documentIds.map((documentId) => documents.find(byId(documentId))).filter((item) => !!item);
    },
  ),
  visibleDocumentsSelector = createSelector(
    (state) => state.documents,
    (state) => state.limit,
    (documents, limit) => documents.slice(0, Math.min(documents.count(), limit)),
  ),
  hasMoreSelector = createSelector(
    (state) => state.documents,
    (state) => state.limit,
    (documents, limit) => documents.count() > limit,
  ),
  /**
   * The component definition
   * @type {Object}
   */
  definition = {
    contextTypes: {
      pathWith: PropTypes.func.isRequired,
      getCursor: PropTypes.func.isRequired,
    },

    getInitialState: () => ({
      limit: 20,
    }),

    handleInfiniteScroll: function () {
      const { limit } = this.state;

      this.setState({ limit: limit + 20 });
    },
  },
  /**
   * Renders the DebateDocumentComponent
   * @param  {Cursor}          cursor The root cursor
   * @return {React.Component}        An instance of DebateDocumentComponent
   */
  render = function ({ debate }) {
    const { getCursor } = this.context,
      { limit } = this.state,
      data = getCursor(['data']),
      documentIds = debate.get('documentIds'),
      documents = documentsSelector({ documents: data.get('documents'), documentIds }),
      visibleDocuments = visibleDocumentsSelector({ documents, limit });

    return (
      <ScrollView>
        <div className="Main-content Content">
          <InfiniteScroll
            loader={<div className="u-centerText">meer aan het laden...</div>}
            loadMore={this.handleInfiniteScroll}
            hasMore={() => hasMoreSelector({ documents, limit })}
          >
            <DebateDocumentsList documents={visibleDocuments} />
          </InfiniteScroll>
        </div>
      </ScrollView>
    );
  };

export default component('DebateDocumentsComponent', definition, render);
