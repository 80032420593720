import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import component from 'omniscient';

const /**
   * Component definition
   * @type {Object}
   */
  definition = {
    contextTypes: {
      getCursor: PropTypes.func.isRequired,
    },

    getInitialState: () => ({
      loading: true,
    }),

    imageLoadHandler: function () {
      this.refs.img.onload = undefined;
      this.setState({
        loading: false,
      });
    },

    componentDidMount: function () {
      this.refs.img.onload = this.imageLoadHandler;
      this.loadImage();
    },

    componentWillUnmount: function () {
      this.refs.img.onload = undefined;
    },

    componentDidUpdate: function (prevProps) {
      if (prevProps.politicianId !== this.props.politicianId) {
        this.loadImage();
      }
    },

    generateImageUrl: function () {
      const size = this.props.size,
        politicianId = this.props.politicianId,
        actualSize = Math.round(size * (window.devicePixelRatio || 1)),
        baseUrl = this.context.getCursor(['ui', 'settings', 'api']).get('baseUrl');

      return `${baseUrl}/media/actors/politicians/${actualSize}/${actualSize}/${politicianId}.jpg`;
    },

    loadImage: function () {
      this.refs.img.onload = this.imageLoadHandler;
      this.refs.img.src = this.generateImageUrl();
    },
  },
  /**
   * The PoliticianImage render function
   * @param  {Object} options               Options
   * @param  {Object} options.alt           Image alt attribute
   * @param  {String} [options.className]   DOM object's className
   * @return {React.DOM}                    The PoliticianImage dom
   */
  render = function ({ className, alt }) {
    const imgClassName = classNames('PoliticianImage', className, {
      'u-opaque': this.state.loading,
    });
    const src = this.state.loading ? '' : this.generateImageUrl();

    if (!alt) {
      // When image has an adjacent text alternative, the alt attribute should be empty.
      // This prevents screen readers from reading the same text twice while also preventing
      // the screen reader from reading the image's filename.
      return <img className={imgClassName} src={src} alt="" ref="img" />;
    } else {
      return <img className={imgClassName} src={src} alt={alt} ref="img" />;
    }
  };

export default component('PoliticianImage', definition, render);
