import PropTypes from 'prop-types';
import React from 'react';
import component from 'omniscient';
import Immutable from 'immutable';
import moment from 'moment';
import observer from 'omnipotent/decorator/observer';
import { createSelectorCreator, defaultMemoize } from 'reselect';

import { structure } from '../../../core';
import VotingExplanationButton from '../../../containers/VotingExplanationButton/VotingExplanationButton';
import ScrollView from '../../../components/ScrollView/SmartScrollView';
import LiveDataDisclaimer from '../../../components/LiveDataDisclaimer/LiveDataDisclaimer';
import VotingRoundPopover from '../../../components/Popovers/VotingRoundPopover';

import DebateVotingRound from './DebateVotingRound';

const /**
   * Create a voting collection
   *
   * @param {Cursor} voting
   *
   * @returns {function} Create voting collection
   */
  createVotingCollection = (partiesDict, politicianDict, handleSettingsClick) => (voting) => {
    const subject = voting.get('subject'),
      rounds = voting.get('votingRounds'),
      key = voting.get('id');

    return (
      <section className="Votings-collection" key={key}>
        <p className="Votings-description u-width600 u-hmc">{subject}</p>
        <ul className="List Votings-list">
          {rounds.map((votingRound) => (
            <DebateVotingRound
              cursor={votingRound}
              parties={partiesDict}
              politicians={politicianDict}
              key={votingRound.get('id')}
              onSettings={handleSettingsClick}
            />
          ))}
        </ul>
      </section>
    );
  },
  toDict = (collection, key = 'id') => {
    return collection.reduce((acc, cursor) => {
      acc[cursor.get(key)] = cursor;

      return acc;
    }, {});
  },
  /**
   * The component definition
   *
   * @type {Object}
   */
  definition = {
    getInitialState: () => ({
      popoverOpen: false,
      popoverAnchor: null,
      popoverVotingRound: null,
    }),

    contextTypes: {
      getCursor: PropTypes.func.isRequired,
      pathTo: PropTypes.func.isRequired,
    },

    handleSettingsClick: function (event, votingRound) {
      this.setState({
        popoverOpen: true,
        popoverAnchor: event.currentTarget,
        popoverVotingRound: votingRound?.toJS(),
      });
    },
  },
  createSelector = createSelectorCreator(defaultMemoize, Immutable.is),
  politiciansDictSelector = createSelector(
    (state) => state.politicians,
    (politicians) => toDict(politicians),
  ),
  partiesDictSelector = createSelector(
    (state) => state.parties,
    (parties) => toDict(parties),
  ),
  votingsSelector = createSelector(
    (state) => state.votings,
    (state) => state.pdt,
    (state) => state.dataQuality,
    (votings, pdt, dataQuality) => {
      const momentNow = moment(pdt);

      return votings
        .deref()
        .map((voting) => {
          return voting
            .update('votingRounds', (votingRounds) => {
              return votingRounds
                .filter((votingRound) => votingRound.has('startedAt') && momentNow.diff(votingRound.get('startedAt')) >= 0)
                .map((votingRound) => {
                  // this clears the votingResult when the votingRound is not ended in the video
                  if (votingRound.has('endedAt') && momentNow.diff(votingRound.get('endedAt')) < 0) {
                    return votingRound.set('dataQuality', dataQuality).set('result', 'Onbekend').set('votes', []);
                  }

                  return votingRound.set('dataQuality', dataQuality);
                })
                .reverse();
            })
            .set('dataQuality', dataQuality);
        })
        .filter((voting) => {
          return voting.get('votingRounds').count() > 0;
        })
        .reverse();
    },
  ),
  /**
   * Renders the DebateVotingComponent
   *
   * @param  {Cursor}          cursor The root cursor
   *
   * @return {React.Component}        An instance of DebateVotingComponent
   */
  render = function ({ debate, sync }) {
    const { getCursor } = this.context,
      data = getCursor(['data']),
      politicians = data.getIn(['actors', 'politicians']),
      parties = data.getIn(['actors', 'parties']),
      votings = debate.get('votings'),
      dataQuality = debate.get('dataQuality', 'live'),
      pdt = sync.get('pdt') || undefined;

    const politiciansDict = politiciansDictSelector({ politicians }),
      partiesDict = partiesDictSelector({ parties }),
      visibleVotings = votingsSelector({ votings, dataQuality, pdt });

    /* eslint-disable no-extra-parens */
    return (
      <ScrollView>
        <div className={`Main-content Main-content--wide Content DataQuality-${dataQuality}`}>
          <div className="LiveDataDisclaimer-container">
            <div className="u-width600 u-horizontalCenter">{dataQuality === 'live' ? <LiveDataDisclaimer icon={<VotingExplanationButton />} /> : null}</div>
          </div>
          {visibleVotings.map(createVotingCollection(partiesDict, politiciansDict, this.handleSettingsClick))}
        </div>
        <VotingRoundPopover
          open={this.state.popoverOpen}
          anchor={this.state.popoverAnchor}
          initiator={this.state.popoverAnchor}
          debate={this.props.debate}
          downloads={this.props.downloads}
          votingRound={this.state.popoverVotingRound}
          transformOrigin={{ horizontal: 'auto', vertical: 'auto' }}
          onClose={() => this.setState({ popoverOpen: false })}
        />
      </ScrollView>
    );
    /* eslint-enable no-extra-parens */
  },
  DebateVotingsComponent = component('DebateVotingsComponent', definition, render);

export default observer(
  structure,
  {
    sync: ['ui', 'sync'],
    downloads: ['ui', 'downloads', 'items'],
  },
  DebateVotingsComponent,
);
