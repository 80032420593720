import PropTypes from 'prop-types';
import React from 'react';
import component from 'omniscient';
import observer from 'omnipotent/decorator/observer';
import moment from 'moment';

import { structure } from '../../core';
import addSourceParameters from '../../utils/addSourceParameters';

import TheoMedia from './TheoMedia';

/**
 * Create object containing video and audio source.
 * @param {Object} debate
 * @returns {Object|null}
 */
const createMediaSources = (debate) => {
    if (!debate?.has('video')) {
      return null;
    }

    let videoSource = debate.getIn(['video', 'catchup', 'url']);
    let audioSource = debate.getIn(['video', 'catchup', 'audioUrl']);
    let start = debate.get('startedAt');
    let end = debate.get('endedAt');

    if (!videoSource || !start) {
      return null;
    }

    // use vodUrl if debate has ended
    if (end) {
      videoSource = debate.getIn(['video', 'catchup', 'vodUrl']);
    } else if (!window.cordova && videoSource && videoSource.indexOf('subtitles=live') === -1) {
      videoSource += (videoSource.indexOf('?') === -1 ? '?' : '&') + 'subtitles=live';
    }

    // make sure there is always 30 seconds of playable stream.
    // Case: user navigates to the debate 5s after start
    // current time: 12:00:05
    // debate start: 12:00:00
    // start will be (current time - 30 = 11:59:35) since 11:59:35 is before 12:00:00
    //
    // Case: user navigates to the debate 5m after start
    // current time: 12:05:00
    // debate start: 12:00:00
    // start will be 12:00:00 since (current time - 30 = 12:04:30) is after 12:00:00
    if (start) {
      start = moment.min(moment(start), moment().subtract(60, 'seconds')).format('YYYY-MM-DDTHH:mm:ssZZ');
    }

    videoSource = addSourceParameters(videoSource, start, end);
    audioSource = addSourceParameters(audioSource, start, end);

    // uncomment this to enable subtitles for VOD on web
    // if (!window.cordova && end) {
    //   videoSource += '&subtitles=1';
    // }

    return {
      video: videoSource,
      audio: audioSource,
    };
  },
  deleteHdParameter = (url) => {
    const parsedUrl = new URL(url);
    const params = new URLSearchParams(parsedUrl.search);

    params.delete('hd');

    const search = params.toString();

    return `${parsedUrl.origin}${parsedUrl.pathname}${search ? `?${search}` : ''}`;
  },
  definition = {
    getInitialState: () => ({
      playerInitialised: false,
    }),

    contextTypes: {
      getService: PropTypes.func.isRequired,
      getCursor: PropTypes.func.isRequired,
      embedded: PropTypes.bool.isRequired,
      params: PropTypes.object.isRequired,
      route: PropTypes.object.isRequired,
    },

    propTypes: {
      debate: PropTypes.object,
    },

    componentDidUpdate: function () {
      const { debate } = this.props;
      const route = this.context.route;
      const isEmbedded = this.context.getService('router').isEmbedded(route);

      if (!debate && !isEmbedded) {
        // User has navigated to a non video page.
        this.context.getService('video').updatePlayRequested(false);
      }
    },

    generateLocationMediaSources: function () {
      const locations = this.context.getCursor(['data', 'locations']);
      const locationSlug = this.context.params.location;
      const location = locations.find((current) => current.get('slug') === locationSlug);

      if (!location) {
        return null;
      }

      let videoUrl = location.get('streamUrl').replace('{date}', 'live').replace('{slug}', locationSlug.replace(/-/g, '_'));
      const audioUrl = location.get('audioUrl').replace('{date}', 'live').replace('{slug}', locationSlug.replace(/-/g, '_'));

      // make sure that the location embedded streams always contain the subtitle param
      if (videoUrl && videoUrl.indexOf('subtitles=live') === -1) {
        videoUrl += (videoUrl.indexOf('?') === -1 ? '?' : '&') + 'subtitles=live';
      }

      return {
        audio: audioUrl,
        video: videoUrl,
      };
    },
  },
  VideoSwitcher = component('VideoSwitcher', definition, function ({ debate, poster, audioOnly, controlsVisible, playRequested, hd }) {
    const { getService } = this.context;
    const player = getService('video').getPlayer();
    let sources = createMediaSources(debate);
    let location;

    // always use sliding DVR stream for location embedded
    if (this.context.route && (this.context.route.name === 'location-embedded' || this.context.route.name === 'location-embedded-audio')) {
      sources = this.generateLocationMediaSources();
      location = this.context.params.location;
    }

    if (!sources) {
      return <div />;
    }

    // remove hd param by default
    if (this.context.embedded && !hd.deref()) {
      sources.video = deleteHdParameter(sources.video);
    }

    if (player) {
      player.textTrackMarginBottom = controlsVisible.deref() ? 60 : 20;
    }

    this.state.playerInitialised = true;

    const debateName = debate ? debate.get('name') || '' : '';
    const isLive = debate ? !debate.has('endedAt') : false;

    return (
      <div className="Video-container">
        <TheoMedia
          sources={sources}
          poster={poster}
          isLive={isLive}
          audioOnly={audioOnly.deref()}
          debate={debate}
          debateName={debateName}
          location={location}
          playRequested={playRequested.deref()}
        />
      </div>
    );
  });

export default observer(
  structure,
  {
    audioOnly: ['ui', 'video', 'audioOnly'],
    playRequested: ['ui', 'video', 'playRequested'],
    controlsVisible: ['ui', 'video', 'controlsVisible'],
    hd: ['ui', 'settings', 'hd'],
  },
  VideoSwitcher,
);
