import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import component from 'omniscient';
import debounce from 'lodash.debounce';

const getParentScrollView = function (parent) {
    let element = parent;

    while ((element = element.parentNode)) {
      if (/(Native)?ScrollView/.test(element.className)) {
        return element;
      }
    }

    return window;
  },
  definition = {
    contextTypes: {
      getService: PropTypes.func.isRequired,
    },

    hasMore: function () {
      const { hasMore } = this.props;

      return typeof hasMore === 'function' ? hasMore() : hasMore;
    },

    getDefaultProps: function () {
      return {
        pageStart: 0,
        hasMore: false,
        loadMore: function () {},
        threshold: 250,
      };
    },

    shouldComponentUpdate: () => true,

    componentDidMount: function () {
      this.scrollView = getParentScrollView(ReactDOM.findDOMNode(this));
      this.pageLoaded = this.props.pageStart;
      this.attachScrollListener();
    },

    componentDidUpdate: function () {
      this.scrollView = getParentScrollView(ReactDOM.findDOMNode(this));
      this.attachScrollListener();
    },

    handleScroll: debounce(function () {
      const hasMore = this.hasMore();

      if (!hasMore) {
        this.detachScrollListener();
      }

      if (this.scrollView.scrollHeight - (this.scrollView.offsetHeight + this.scrollView.scrollTop) < Number(this.props.threshold)) {
        this.pageLoaded += 1;
        this.props.loadMore(this.pageLoaded);
      }
    }, 100),

    attachScrollListener: function () {
      this.scrollView.addEventListener('scroll', this.handleScroll);
      this.scrollView.addEventListener('resize', this.handleScroll);
    },

    detachScrollListener: function () {
      this.scrollView.removeEventListener('scroll', this.handleScroll);
      this.scrollView.removeEventListener('resize', this.handleScroll);
    },

    componentWillUnmount: function () {
      this.detachScrollListener();
    },
  },
  render = function ({ children }) {
    return <div>{children}</div>;
  };

export default component('InfiniteScroll', definition, render);
