import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import component from 'omniscient';

const componentDefinition = {
  getDefaultProps: function () {
    return {
      alignment: 'centerCenter',
      visible: false,
    };
  },

  propTypes: {
    alignment: PropTypes.oneOf(['topLeft', 'topCenter', 'topRight', 'centerRight', 'bottomRight', 'bottomCenter', 'bottomLeft', 'centerLeft', 'centerCenter']),
    visible: PropTypes.bool,
  },

  shouldComponentUpdate: () => true,
};

export default component('Prompt', componentDefinition, function ({ className, children, visible, alignment, ...rest }) {
  const props = {
    className: classNames(`Prompt Prompt--${alignment}`, className, {
      'is-hidden': !visible,
    }),
    'aria-modal': true,
    ...rest,
  };

  return <div {...props}>{children}</div>;
});
