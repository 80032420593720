import PropTypes from 'prop-types';
import React from 'react';

import { DOWNLOAD_STATUS } from '../../services/DownloadService/DownloadService';
import Popover from '../Popover/Popover';
import SlideTransition from '../Animation/SlideTransition';
import MenuList from '../MenuList/MenuList';
import MenuItem from '../MenuList/MenuItem';
import { Close, OpenInNew, Download, Delete } from '../../icons/index';

export default class DownloadItemPopover extends React.Component {
  static contextTypes = {
    getService: PropTypes.func.isRequired,
    getCursor: PropTypes.func.isRequired,
  };

  state = {
    firstEnter: true,
    activeIndex: 0,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.setState({ activeIndex: 0 });
    } else if (!nextProps.open && this.props.open) {
      // reset state
      this.setState({
        firstEnter: true,
      });
    }
  }

  close() {
    this.props.onClose();
  }

  openVideo = () => {
    const { getService } = this.context;
    const { item } = this.props;

    getService('download')
      .open(item.get('id'))
      .catch((e) => console.log(e));

    this.close();
  };

  downloadVideo = () => {
    const { getService } = this.context;
    const { item } = this.props;

    if (window.cordova) {
      getService('download').download(item.get('id'));
    } else {
      // open will download the file on web
      getService('download').open(item.get('id'));
    }

    this.close();
  };

  deleteVideo = () => {
    const { getService } = this.context;
    const { item } = this.props;

    getService('download').remove(item.get('id'));

    this.close();
  };

  renderMainMenu() {
    const { item } = this.props;

    const canDownload = item && item.get('status') === DOWNLOAD_STATUS.GENERATED;
    const canOpen = item && item.get('status') === DOWNLOAD_STATUS.DOWNLOADED;

    return (
      <SlideTransition
        in={this.state.activeIndex === 0}
        appear={this.state.firstEnter}
        timeout={this.state.firstEnter ? 0 : 300}
        key="main"
        className={this.state.firstEnter ? '' : 'SlideLeft'}
      >
        <MenuList>
          {canOpen && <MenuItem label="Video openen" icon={<OpenInNew />} onClick={this.openVideo} />}
          {canDownload && <MenuItem label="Video downloaden" icon={<Download />} onClick={this.downloadVideo} />}
          <MenuItem label="Video verwijderen" icon={<Delete />} onClick={this.deleteVideo} />
        </MenuList>
      </SlideTransition>
    );
  }

  render() {
    return (
      <Popover
        open={this.props.open}
        anchor={this.props.anchor}
        anchorOffset={this.props.anchorOffset}
        transformOrigin={this.props.transformOrigin}
        onClose={() => this.close()}
      >
        <div style={{ position: 'relative' }}>{this.renderMainMenu()}</div>
        <hr className="u-m0" />
        <MenuList>
          <MenuItem className="MenuItem--footer" label="Annuleren" icon={<Close />} onClick={() => this.close()} />
        </MenuList>
      </Popover>
    );
  }
}
